import { useState, useEffect, Suspense } from "react"
import { Spin } from "antd"

function wrapPromise(promise) {
  let status = "pending"
  let result
  let suspender = promise.then(
    r => {
      status = "success"
      result = r
    },
    e => {
      status = "error"
      result = e
    }
  )
  return () => {
    if (status === "pending") {
      throw suspender
    } else if (status === "error") {
      throw result
    } else if (status === "success") {
      return result
    }
  }
}

const generatePromise = (delayTime) => new Promise(res => setTimeout(res, delayTime))

/**
*
* @author : 田源
* @date : 2022-11-15 10:54
* @description : 用来强制延迟组件渲染
* @param depend 控制触发延迟更新的依赖项
* @param delayTime 延迟时间
*/
function DelayComponent({ children, depend, delayTime = 200 }) {

  const [waitPromise, setWaitPromise] = useState(() => wrapPromise(generatePromise(delayTime)))

  useEffect(() => {
    setWaitPromise(() => wrapPromise(generatePromise(delayTime)))
  }, [depend, delayTime])

  return (
    <Suspense fallback={<Spin size="large" style={{ width: '100%' }} />} >
      <Delay waitPromise={waitPromise} Component={children} />
    </Suspense>
  )
}

function Delay({ Component, waitPromise }) {
  waitPromise()
  return Component
}

export default DelayComponent