import { useState, useEffect } from 'react'
import { Form, Modal, Button, Upload, Space } from 'antd'
import { ContainerOutlined } from '@ant-design/icons'
import styles from '../sass/import.module.scss'
import EolAxios, { API } from '../../axios'
import { useImport } from '../../hooks'
import { FILE_TYPES } from '../../tools'
import ImportProgress from './ImportProgress'

const { Item } = Form

const FILE_TYPE = '7'

/**
 * 
 * @description: 未报到数据导入弹窗 
 */
function ImportBaoDaoModal({ open, onOk, onCancel, year }) {
  const [form] = Form.useForm()
  const [config, setConfig] = useState({})

  const multiple = config.file_num === '多文件'

  const {
    getUploadProps,
    getImportBtnProps,
    importState,
    handleImport,
    handleCancel,
    // handleFinish,
    progressProps,
  } = useImport({
    title: config?.title ? `导入${config.title}` : '',
    open,
    year,
    accept: config.file_type,
    maxSize: +config.file_size,
    multiple,
    onCancel,
    onOk,
    onError: (_error) => {
      if (open) {
        Modal.warn({
          title: '提示',
          content: '导入失败。原因：网络连接错误。'
        })
        onCancel()
      }
    },
  })

  const handleModalOk = () => {
    handleImport(API.importReportData, { file_type: FILE_TYPES.BAODAO, year, })
  }

  useEffect(() => {
    if (!open) return

    EolAxios.dynamicRequest({
      path: API.uploadConfig,
      formData: {
        year,
        type_id: 4//* 1 计划编制 2 计划调整 3 录取数据 4 报到数据
      },
    }).then(res => {
      if (!EolAxios.checkResponse({ res })) return
      setConfig(res)
    })
  }, [open, year])

  const handleDownloadTemplate = () => {
    EolAxios.dynamicRequest({
      path: API.downloadTemplate,
      formData: { file_type: FILE_TYPE, }
    }).then((res) => {
      if (!EolAxios.checkResponse({ res })) return
      const { url } = res
      url !== '' && window.open(url)
    })
  }

  return <Modal
    width={560}
    className={styles.importModal}
    title={config?.title ? `导入${config.title}` : ''}
    destroyOnClose
    maskClosable={false}
    open={open}
    onCancel={handleCancel}
    footer={importState === 'PENDING' ?
      <Button onClick={handleModalOk} type='primary'>导入</Button>
      : importState === 'PROGRESSING'
        ? <Button onClick={handleCancel}>取消</Button>
        : <Button onClick={onOk} type='primary'>完成</Button>}
  >
    {importState !== 'PENDING' ?
      <ImportProgress {...progressProps} /> :
      <>
        <p style={{ color: 'rgba(0,0,0,0.65)' }}>· 说明： 批量导入未报到的学生，以统计报到情况</p>
        <Form
          form={form}
          preserve={false}
        >
          <Item className={styles.formItemLast}>
            <Upload {...getUploadProps({ className: multiple ? styles.multipleUploadList : '' })}>
              <Button icon={<ContainerOutlined />} {...getImportBtnProps()}>
                选择文件
              </Button>
            </Upload>
          </Item>
          <Space direction='vertical'>
            <span style={{ color: 'rgba(0,0,0,0.65)' }}>
              {`请选择 excel文件，文件不能超过${config.file_size}M`}
            </span>
            <Button
              type='link'
              size='small'
              style={{ padding: 0 }}
              onClick={handleDownloadTemplate}
            >
              下载未报到模板
            </Button>
          </Space>
        </Form>
      </>
    }
  </Modal>
}

export default ImportBaoDaoModal