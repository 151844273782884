/**
*
* @author : 田源
* @date : 2022-10-17 11:57
* @description : mock数据测试接口
*
*/
const { api, mock } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}

export const mockJiami = 'mockJiami'
export const mockDynamicTable = 'mockDynamicTable'
export const mockDynamicTableConfig = 'mockDynamicTableConfig'
export const mockMenu = 'mockMenu'
export const mockAddQ = 'mockAddQ'
export const mockQuestionDetail = 'mockQuestionDetail'

// setRealUrl(`静态接口 example, params:[省份]`,
//   staticTest, (params) => staticZj + `/json/operate/home/3/${params[0]}/1.json`
// )
setRealUrl(`测试加密 example`, mockJiami, () => api + `/apitoken/test/test/add`)
setRealUrl(`动态表格测试数据`, mockDynamicTable, () => mock + `/mockTable`)
setRealUrl(`动态表格测试数据`, mockDynamicTableConfig, () => mock + `/config`)
setRealUrl(`测试菜单权限`, mockMenu, () => mock + `/menu`)
setRealUrl(`新增问卷`, mockAddQ, () => mock + `/addQ`)
setRealUrl(`新增问卷`, mockQuestionDetail, () => mock + `/questionDetails`)

export default realUrl