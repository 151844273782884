import React from 'react'
import * as icons from '@ant-design/icons'
/**
*
* @author : 王松
* @date : 2022-10-11 11:30
* @description : 动态加载icon
* <EIcon icon={'MessageOutlined'} />
*/
const EIcon = ({ icon = 'BellOutlined' }) => {
  return React.createElement(icons[icon] ?? 'span')
}

export default EIcon