import { SAVE_CONFIG } from '../actions/action-types'


const initConfig = { yearArray: [] }
export const globalConfig = (state = initConfig, action) => {
  switch (action.type) {
    case SAVE_CONFIG:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
