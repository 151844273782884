import { useState, useEffect } from 'react'
import { Form, Modal, Alert, Button, Upload, Space, Select, Tabs } from 'antd'
import { ContainerOutlined } from '@ant-design/icons'
import styles from '../sass/import.module.scss'
import EolAxios, { API } from '../../axios'
import { useImport } from '../../hooks'
import { FILE_TYPES } from '../../tools'
import ImportProgress from './ImportProgress'
import { useSelector } from 'react-redux'

const { Item } = Form
const { Option } = Select

const FILE_TYPE = '9'

/**
 * 
 * @description: 导入计划弹窗 
 */
function ImportJiHuaModal({ open, onOk, onCancel, year: propsYear, }) {
  const { yearArray: yearList } = useSelector(store => store.globalConfig)
  const [form] = Form.useForm()
  const [config, setConfig] = useState({})
  const [type, setType] = useState(FILE_TYPES.JIHUA)
  const [year, setYear] = useState(propsYear)

  const multiple = config.file_num === '多文件'

  const {
    getUploadProps,
    getImportBtnProps,
    importState,
    handleImport,
    handleCancel,
    handleFinish,
    finishLoading,
    progressProps,
  } = useImport({
    title: config?.title ? `导入${config.title}` : '',
    open,
    year,
    accept: config.file_type,
    maxSize: +config.file_size,
    multiple,
    onCancel,
    onOk,
    onError: (_error) => {
      if (open) {
        Modal.warn({
          title: '提示',
          content: '导入失败。原因：网络连接错误。'
        })
        onCancel()
      }
    },
  })

  const handleModalOk = () => {
    handleImport(API.fileUpload, { file_type: type, year, })
  }

  const handleDownloadTemplate = () => {
    EolAxios.dynamicRequest({
      path: API.downloadTemplate,
      formData: { file_type: FILE_TYPE, }
    }).then((res) => {
      if (!EolAxios.checkResponse({ res })) return
      const { url } = res
      url !== '' && window.open(url)
    })
  }

  useEffect(() => {
    if (!open) {
      setConfig({})
      setType(FILE_TYPES.JIHUA)
      return
    }
    if (type === FILE_TYPES.CUSTOM_JIHUA) {
      setConfig(c => ({
        ...c,
        title: '自定义计划',
      }))
      return
    }

    form.setFieldValue('year', propsYear)
    setYear(propsYear)

    EolAxios.dynamicRequest({
      path: API.uploadConfig,
      formData: {
        year: propsYear,
        type_id: type === FILE_TYPES.JIHUA ? 1 : 2 //* 1 计划编制 2 计划调整 3 录取数据 4 报到数据
      },
    }).then(res => {
      if (!EolAxios.checkResponse({ res })) return
      setConfig(res)
    })
  }, [open, propsYear, type, form])

  return <>
    <Modal
      width={560}
      className={styles.importModal}
      title={config?.title ? `导入${config.title}` : ''}
      destroyOnClose
      open={open}
      onCancel={handleCancel}
      maskClosable={false}
      footer={importState === 'PENDING' ?
        <Button onClick={handleModalOk} type='primary'>导入</Button>
        : importState === 'PROGRESSING'
          ? <Button onClick={handleCancel}>取消</Button>
          : <Button onClick={handleFinish} loading={finishLoading} type='primary'>完成</Button>}
    >
      {importState !== 'PENDING' ?
        <ImportProgress
          {...{ ...progressProps, year, isConvert: type !== FILE_TYPES.CUSTOM_JIHUA, }}
        /> :
        <>
          <Tabs
            activeKey={type}
            onChange={(key) => { setType(key) }}
            style={{ marginTop: '-24px' }}
            items={[
              { key: FILE_TYPES.JIHUA, label: '计划编制' },
              { key: FILE_TYPES.JIHUA_ADJUST, label: '计划调整' },
              { key: FILE_TYPES.CUSTOM_JIHUA, label: '自定义计划' },
            ]}
          />
          <Form
            form={form}
            preserve={false}
            labelCol={{ span: 3 }}
          >
            <Item className={styles.formItem} style={{ marginTop: '1em' }} name="year" label="年份" rules={[{ required: true, message: '请选择年份' }]}>
              {yearList.length > 0 ?
                <Select style={{ width: 120 }} onChange={(year) => setYear(year)}>
                  {yearList.map(item =>
                    <Option key={item.value} value={item.value}>{item.label}</Option>
                  )}
                </Select>
                : <p style={{ margin: 0, paddingTop: '4px' }}>
                  暂无可选择的年份<br />
                  <span style={{ fontSize: 12 }}>只能选择有计划编制年份</span>
                </p>}
            </Item>
            <Item className={styles.formItem} style={{ paddingLeft: '64px' }}>
              <Upload {...getUploadProps({ className: multiple ? styles.multipleUploadList : '' })}>
                <Button style={{ width: 120 }} icon={<ContainerOutlined />} {...getImportBtnProps()}>
                  选择文件
                </Button>
              </Upload>
            </Item>
            <Space direction='vertical'>
              <span style={{ color: 'rgba(0,0,0,0.65)' }}>
                {`请选择 excel 文件，文件不能超过${+config.file_size}M`}
              </span>
              {type === FILE_TYPES.CUSTOM_JIHUA && <Button
                type='link'
                size='small'
                style={{ padding: 0 }}
                onClick={handleDownloadTemplate}
              >
                自定义模板下载
              </Button>}
              <Alert
                message={
                  type === FILE_TYPES.JIHUA ?
                    <>
                      系统支持导入教育部计划来源网导出完整“计划编制”<br />
                      下载路径：【来源计划网】计划编制管理→计划数编制→导出Excel
                    </> :
                    type === FILE_TYPES.JIHUA_ADJUST ?
                      <>
                        系统支持导入教育部计划来源网导出完整“计划调整”<br />
                        下载路径：【来源计划网】计划调整→调整明细→查询→导出Excel
                      </> :
                      <>
                        系统支持导入自定义计划数据（自主招生、高校专项计划、保送生）
                      </>
                } type="info" />
              {/* <a href='/'>计划数据导入说明</a> */}
            </Space>
          </Form>
        </>
      }
    </Modal >
  </>
}

export default ImportJiHuaModal