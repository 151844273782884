/*
 * @Author: 王松
 * @Descripttion: 
 * @Date: 2023-08-16 09:19:15
 */
import { Dropdown, Button, Tooltip } from "antd"
import { DownOutlined } from '@ant-design/icons'
import { useRouterInfo } from "../../hooks"

/**
 *
 * @author : 田源
 * @date : 2022-10-12 17:51
 * @description : 功能按钮权限判断
 * @param {{btnsInfo:array,tab:number,foldLimit?:number}} 
 * 
 * - btnsInfo: 按钮信息
 * - tab: 页面有多个tab的情况下，需传入当前是第几个tab
 * - foldLimit 超过几个折叠，默认不折叠
 */
const AuthButton = ({ btnsInfo = [], tab = 1, foldLimit }) => {
  const { tags = [] } = useRouterInfo()
  const tagsArray = tags[tab - 1]?.filter(tag => tag.show) || []
  const more = foldLimit > 0 && tagsArray.length > foldLimit ? tagsArray.slice((foldLimit - 1)) : false
  const btns = foldLimit > 0 && tagsArray.length > foldLimit ? tagsArray.slice(0, (foldLimit - 1)) : tagsArray
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
      {
        btns.map(item => {
          const info = btnsInfo.find(btn => btn.key === item.tag) || {}
          return (
            info.tooltip &&
            <Tooltip placement="bottom" title='暂无需要解密的数据' trigger="click" key={item.tag}>
              <Button danger={info.danger ? true : false} disabled={info.disabled ? true : false} loading={info.loading} key={item.tag} type={info.type || ''} onClick={info.onClick}>
                {info.loading ? info.labelText : info.label}
              </Button>
            </Tooltip>
            || <Button danger={info.danger ? true : false} disabled={info.disabled ? true : false} loading={info.loading} key={item.tag} type={info.type || ''} onClick={info.onClick}>
              {info.loading ? info.labelText : info.label}
            </Button>
          )
        })
      }
      {
        more &&
        <Dropdown
          menu={{ items: more.map(item => btnsInfo.find(btn => btn.key === item.tag)) }}
        >
          <Button style={{ marginLeft: '8px' }}>更多操作<DownOutlined /></Button>
        </Dropdown>
      }
    </div >
  )
}

export default AuthButton