import { useEffect, useState, useRef } from 'react'
import { wait } from '../tools'

/**
 *
 *
 * @param {{options?:PositionOptions,enable?:boolean}} {options = { timeout: 30 * 1000 }, enable = true}
 * @return {{
 * loading: boolean, 
 * data: null | {province: string},
 * error: null | GeolocationPositionError, 
 * update: () => void,
 * }} 
 */
const useAddress = ({ options = { timeout: 30 * 1000 }, enable = true } = {}) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [reLocation, setReLocation] = useState(false)

  const optionsRef = useRef(options)
  optionsRef.current = options

  useEffect(() => {
    if (!enable) return

    const getCurrentPosition = async () => {
      setLoading(true)
      await wait(300)
      if (window.BMapGL && typeof window.BMapGL === 'object') {
        let BMap = window.BMapGL
        let resAddress = null
        let geolocation = new BMap.Geolocation()
        geolocation.getCurrentPosition(function (res) {
          setLoading(false)
          resAddress = res
          if (this.getStatus() === window.BMAP_STATUS_SUCCESS) {
            setData(res.address)
          } else {
            setData(null)
            setError(res)
          }
        }, { enableHighAccuracy: true, timeout: 6000 })
        setTimeout(() => {
          if (!resAddress) {
            setLoading(false)
            setError('定位失败')
            setData(null)
          }
        }, 6000)
      } else {
        setLoading(false)
        setError('定位失败')
        setData(null)
      }
      // jsonp(`https://api.map.baidu.com/location/ip`, {
      //   ak: AK,
      //   output: 'json',
      //   coor: 'bd09ll',
      // }).then(res => {
      //   setLoading(false)
      //   if (res?.status === 0) {
      //     setData(res.content.address_detail)
      //   } else {
      //     setData(null)
      //     setError(res)
      //   }
      // })
      //   .catch(err => {
      //     setLoading(false)
      //     setError(err)
      //     setData(null)
      //   })
    }

    getCurrentPosition()
  }, [enable, reLocation])

  if (!enable) {
    return {
      loading: false,
      data: null,
      error: null,
      update: () => { },
    }
  }

  return {
    loading,
    data,
    error,
    update: () => setReLocation(l => !l),
  }
}

// const jsonp = (url, query) => new Promise((resolve, reject) => {
//   const script = document.createElement('script')
//   const callback = `callback_${Math.floor(Math.random() * 1000000)}`
//   script.src = `${url}?${qs.stringify({ ...query, callback })}`

//   window[callback] = (data) => {
//     delete window[callback]
//     document.body.removeChild(script)
//     resolve(data)
//   }

//   script.onerror = (error) => {
//     delete window[callback]
//     document.body.removeChild(script)
//     reject(error)
//   }

//   document.body.appendChild(script)
// })

export default useAddress
