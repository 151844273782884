// * 登录日志/操作日志

const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}

export const loginLog = 'loginLog'
setRealUrl(`登录日志`, loginLog, () => api + `/school/appschool/log/login`)
export const actionLog = 'actionLog'
setRealUrl(`操作日志`, actionLog, () => api + `/school/appschool/log/action`)
export const actionLogType = 'actionLogType'
setRealUrl(`操作日志-类型配置`, actionLogType, () => api + `/school/appschool/log/actionType`)

export default realUrl