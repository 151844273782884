import { useState, useEffect } from 'react'
import { Button, Modal, Select, } from 'antd'
import { DeleteOutlined, PlusOutlined, DragOutlined } from '@ant-design/icons'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import styles from '../sass/sort-modal.module.scss'
import ESpace from './ESpace'

const { Option } = Select

const DragHandle = sortableHandle(() => <DragOutlined className={styles.handleIcon} />)

const SortableContainer = sortableContainer(({ children }) => {
  return <div className={styles.sortBox}>{children}</div>
})

const initSortData = {
  key: '',
  value: 'asc'
}

/**
*
* @author : 田源
* @date : 2022-10-19 15:54
* @description : 排序模态框
*
*/
function SortModal({ openModal, closeModal, optionData = [], onOk, data }) {

  const [sortData, setSortData] = useState([{ ...initSortData }])
  const disableBtn = sortData.length === optionData.length
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      setSortData(data => {
        const temp = data[oldIndex]
        data.splice(oldIndex, 1)
        data.splice(newIndex, 0, temp)
        return [...data]
      })
    }
  }

  const SortableItem = sortableElement(({ _index }) => (
    <div>
      <div className={styles.sort} >
        <span style={{ whiteSpace: 'nowrap' }}>排序{_index + 1}：</span>
        <Select
          value={sortData[_index].key}
          onChange={(value) => {
            setSortData(data => {
              data[_index]['key'] = value
              return [...data]
            })
          }}
          style={{ width: 300, marginRight: 8 }}
        >
          <Option value=''>请选择</Option>
          {
            optionData?.map(option => {
              const disable = sortData.some(item => item.key === option.value)
              return <Option
                key={option.value}
                value={option.value}
                disabled={disable}
              >{option.text}</Option>
            })
          }
        </Select>
        <Select
          value={sortData[_index].value}
          onChange={(value) => {
            setSortData(data => {
              data[_index]['value'] = value
              return [...data]
            })
          }}
          style={{ width: 88 }}
        >
          <Option value={'asc'} >正序</Option>
          <Option value={'desc'} >倒序</Option>
        </Select>
        <ESpace width={10} />
        <DeleteOutlined onClick={() => {
          setSortData(data => data.filter((_, i) => i !== _index))
        }} />
        <ESpace width={10} />
        <DragHandle />
      </div>
    </div>
  ))

  useEffect(() => {
    if (Array.isArray(data)) {
      setSortData(data)
    }
  }, [data])

  return <Modal
    title={<span className='font-bold'>排序规则</span>}
    open={openModal}
    onCancel={closeModal}
    onOk={() => {
      onOk?.(sortData.filter(item => item.key))
      closeModal()
    }}
    width={560}
    bodyStyle={{ maxHeight: 370, overflow: 'auto' }}
  >
    <span>注：按住选框内移动图标 <DragOutlined /> 可拖动排序</span>
    <SortableContainer onSortEnd={onSortEnd} useDragHandle
      helperClass={styles.rowDragging}
    >
      {sortData.map((_, index) => (
        <SortableItem key={`item-${index}`} _index={index}
          index={index}
        />
      ))}
    </SortableContainer>
    <Button
      type='dashed'
      disabled={disableBtn}
      className={`${styles.btn}`}
      onClick={() => {
        setSortData(data => [...data, { ...initSortData }])
      }}>
      <PlusOutlined />添加一个新的数据
    </Button>
  </Modal>
}

export default SortModal