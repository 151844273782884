
import NoDataImg from '../../assets/images/noData.png'
import styles from '../sass/noData.module.scss'

/**
*
* @author : 田源
* @date : 2022-09-08 09:39
* @description : 无数据组件
*
*/
function NoData({ content = '暂无数据' }) {
  return (
    <div className={styles.noDataBox}>
      <img alt='暂无数据' src={NoDataImg} />
      <p>{content}</p>
    </div>
  )
}

export default NoData