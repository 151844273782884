/*
 * @Author: 王松
 * @Descripttion:辅助管理
 * @Date: 2023-03-09 13:38:27
 */
const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}

export const jfglGetZslx = 'jfglGetZslx'
export const jfglGetLists = 'jfglGetLists'
export const jfglUpdateNum = 'jfglUpdateNum'
export const jfglUpdateRemark = 'jfglUpdateRemark'
export const jfglCopyNum = 'jfglCopyNum'
export const jfglClearNum = 'jfglClearNum'
export const jfglPreview = 'jfglPreview'
setRealUrl(`缴费管理-招生类型`, jfglGetZslx, () => api + `/admissionFee/appadmissionfee/admissionFee/getZslx`)
setRealUrl(`缴费管理-录取费用管理`, jfglGetLists, () => api + `/admissionFee/appadmissionfee/admissionFee/lists`)
setRealUrl(`缴费管理-编辑缴费人数`, jfglUpdateNum, () => api + `/admissionFee/appadmissionfee/admissionFee/updateNum`)
setRealUrl(`缴费管理-编辑备注`, jfglUpdateRemark, () => api + `/admissionFee/appadmissionfee/admissionFee/updateRemark`)
setRealUrl(`缴费管理-复制缴费人数`, jfglCopyNum, () => api + `/admissionFee/appadmissionfee/admissionFee/copyNum`)
setRealUrl(`缴费管理-清空缴费人数`, jfglClearNum, () => api + `/admissionFee/appadmissionfee/admissionFee/clearNum`)
setRealUrl(`缴费管理-查看图片`, jfglPreview, () => api + `/admissionFee/appadmissionfee/admissionFee/preview`)
export default realUrl