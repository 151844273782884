/**
 * @description: 数据导入接口
 */
const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}

export const fileUpload = 'fileUpload'
export const enrollFilesUpload = 'enrollFilesUpload'
export const enrollFilesPreUpload = 'enrollFilesPreUpload'
export const importReportData = 'importReportData'
export const fileTags = 'fileTags'
export const fileAbnormal = 'fileAbnormal'
export const fileExport = 'fileExport'
export const overviewInfo = 'overviewInfo'
export const overviewProvince = 'overviewProvince'
export const overviewList = 'overviewList'
export const overviewLostList = 'overviewLostList'
export const overviewClear = 'overviewClear'
export const uploadConfig = 'uploadConfig'
export const cusFieldTemplate = 'cusFieldTemplate'
export const getBucket = 'getBucket'
export const getSTSToken = 'getSTSToken'
export const setUploadLog = 'setUploadLog'
export const getAdmissionList = 'getAdmissionList'
export const toolDownload = 'toolDownload'
export const overviewDecryptList = 'overviewDecryptList'
export const overviewDecrypt = 'overviewDecrypt'

setRealUrl(`文件上传（录取数据、计划数据）`, fileUpload, () => api + `/school/file/upload`)
setRealUrl(`文件上传（附件）`, enrollFilesUpload, () => api + `/school/file/enroll`)
setRealUrl(`文件预上传`, enrollFilesPreUpload, () => api + `/school/file/enroll_log`)
setRealUrl(`导入未报到数据`, importReportData, () => api + `/school/file/importReportData`)
setRealUrl(`上传文件附属信息（用于断点上传）`, fileTags, () => api + `/school/appschool/upload/fileTags`)
setRealUrl(`处理导入结果`, fileAbnormal, () => api + `/school/appschool/upload/abnormal`)
setRealUrl(`导出失败信息 excel`, fileExport, () => api + `/school/appschool/upload/export`)
setRealUrl(`获取文件上传配置`, uploadConfig, () => api + `/school/appschool/upload/config`)
setRealUrl(`获取导入录取数据里的招生类型列表`, getAdmissionList, () => api + `/school/appschool/mapConfig/admissionList`)
setRealUrl(`获取导入自定义模板`, cusFieldTemplate, () => api + `/school/appschool/studentInfoManage/cusFieldTemplate`)

setRealUrl(`导入数据总览`, overviewInfo, () => api + `/school/appschool/overview/info`)
setRealUrl(`导入数据总览-省份`, overviewProvince, () => api + `/school/appschool/overview/proLists`)
setRealUrl(`导入数据总览-列表`, overviewList, () => api + `/school/appschool/overview/lists`)
setRealUrl(`导入数据总览-缺失照片列表`, overviewLostList, () => api + `/school/appschool/overview/lostLists`)
setRealUrl(`导入数据总览-清除数据`, overviewClear, () => api + `/school/appschool/overview/clear`)
setRealUrl(`导入数据总览-获取待解密的数据`, overviewDecrypt, () => api + `/school/appschool/overview/ToBeDecrypted`)
setRealUrl(`导入数据总览-获取待解密的数据`, overviewDecryptList, () => api + `/school/appschool/overview/toBeDecryptedLists`)

setRealUrl(`获取oss上传bucket配置`, getBucket, () => api + `/school/appschool/oss/getBucket`)
setRealUrl(`获取oss上传 STS token信息`, getSTSToken, () => api + `/school/appschool/oss/getToken`)
setRealUrl(`回传给后端的本次上传信息`, setUploadLog, () => api + `/school/appschool/oss/uploadLog`)

// setRealUrl(`小工具下载`, toolDownload, () => api + `/school/appschool/tools/down`)
setRealUrl(`小工具下载`, toolDownload, () => api + `/tools/school/tool/down`)

export default realUrl  