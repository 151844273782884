import { lazy } from 'react'
import { DeepRouter } from '../components/common'
/**
 * 权限路由
 */
const menuRouter = [
  { name: '数据总览', path: '/', exact: true, Comp: lazy(() => import('../pages/data-overview/Index')) },
  { name: '数据导入', path: '/import', exact: true, Comp: lazy(() => import('../pages/data-import/Index')) },
  {
    name: '数据管理',
    path: '/manage',
    exact: false,
    Comp: DeepRouter,
    children: [
      { name: '招生计划管理', path: '/manage/jihua', exact: true, Comp: lazy(() => import('../pages/data-manage/JiHua')) },
      { name: '学生信息管理', path: '/manage/xinxi', exact: true, Comp: lazy(() => import('../pages/data-manage/XinXi')) },
      { name: '学生三表管理', path: '/manage/sanbiao', exact: true, Comp: lazy(() => import('../pages/data-manage/SanBiao')) },
      { name: '报到数据管理', path: '/manage/baodao', exact: true, Comp: lazy(() => import('../pages/data-manage/BaoDao')) },
      { name: '预科生转入', path: '/manage/preppy', exact: true, Comp: lazy(() => import('../pages/data-manage/Preppy')) }
    ]
  },
  {
    name: '数据处理',
    path: '/handle',
    exact: false,
    Comp: DeepRouter,
    children: [
      { name: '招生类型维护', path: '/handle/zhaosheng', exact: true, Comp: lazy(() => import('../pages/data-handle/ZhaoSheng')) },
      { name: '学院专业维护', path: '/handle/xueyuanzhuanye', exact: true, Comp: lazy(() => import('../pages/data-handle/XueYuanZhuanYe')) },
      {
        name: '数据修正',
        path: '/handle/xiuzheng',
        exact: false,
        Comp: DeepRouter,
        children: [
          { name: '科类修正', path: '/handle/xiuzheng/kelei', exact: true, Comp: lazy(() => import('../pages/data-handle/KeLeiXiuZheng')) },
          { name: '批次修正', path: '/handle/xiuzheng/pici', exact: true, Comp: lazy(() => import('../pages/data-handle/PiCiXiuZheng')) },
          { name: '考生类别修正', path: '/handle/xiuzheng/kaoshengleibie', exact: true, Comp: lazy(() => import('../pages/data-handle/KaoShengLeiBie')) },
          { name: '毕业类别修正', path: '/handle/xiuzheng/biyeleibie', exact: true, Comp: lazy(() => import('../pages/data-handle/BiYeLeiBie')) },
          { name: '高中信息修正', path: '/handle/xiuzheng/gaozhongxinxi', exact: true, Comp: lazy(() => import('../pages/data-handle/GaoZhongXinXi')) }
        ]
      },
      { name: '位次对应', path: '/handle/weici', exact: true, Comp: lazy(() => import('../pages/data-handle/WeiCi')) },
      { name: '省控线对应', path: '/handle/shengkongxian', exact: true, Comp: lazy(() => import('../pages/data-handle/ShengKongXian')) },
      { name: '成绩项', path: '/handle/chengjixiang', exact: true, Comp: lazy(() => import('../pages/data-handle/ChengJiXiang')) }
    ]
  },
  {
    name: '数据分析',
    path: '/analyse',
    exact: false,
    Comp: DeepRouter,
    children: [
      { name: '数据总览', path: '/analyse/overview', exact: true, Comp: lazy(() => import('../pages/data-overview/Index')) },
      {
        name: '分数',
        path: '/analyse/score',
        exact: false,
        Comp: DeepRouter,
        children: [
          { name: '专业分分析', path: '/analyse/score/major', exact: true, Comp: lazy(() => import('../pages/data-analyse/ScoreMajor')) },
          { name: '专业分历年趋势', path: '/analyse/score/major-trend', exact: true, Comp: lazy(() => import('../pages/data-analyse/ScoreMajorTrend')) },
          { name: '单科成绩分析', path: '/analyse/score/single', exact: true, Comp: lazy(() => import('../pages/data-analyse/ScoreSingle')) },
          { name: '分段分数分析', path: '/analyse/score/part', exact: true, Comp: lazy(() => import('../pages/data-analyse/ScorePart')) },
          { name: '线差分析', path: '/analyse/score/diff', exact: true, Comp: lazy(() => import('../pages/data-analyse/ScoreDiff')) },
          { name: '线差历年趋势', path: '/analyse/score/diff-trend', exact: true, Comp: lazy(() => import('../pages/data-analyse/ScoreDiffTrend')) }
        ]
      },
      {
        name: '志愿分析',
        path: '/analyse/choice',
        exact: false,
        Comp: DeepRouter,
        children: [
          { name: '第一志愿分析', path: '/analyse/choice/first', exact: true, Comp: lazy(() => import('../pages/data-analyse/FirstChoice')) },
          { name: '调剂率分析', path: '/analyse/choice/adjust', exact: true, Comp: lazy(() => import('../pages/data-analyse/Adjust')) },
          { name: '志愿组成分析', path: '/analyse/choice/volunteer', exact: true, Comp: lazy(() => import('../pages/data-analyse/Volunteer')) }
        ]
      },
      { name: '省份', path: '/analyse/province', exact: true, Comp: lazy(() => import('../pages/data-analyse/ProvinceInfo')) },
      {
        name: '省份',
        path: '/analyse/province',
        exact: false,
        Comp: DeepRouter,
        children: [
          { name: '各省情况分析', path: '/analyse/province/info', exact: true, Comp: lazy(() => import('../pages/data-analyse/ProvinceInfo')) },
          { name: '各省历年趋势', path: '/analyse/province/trend', exact: true, Comp: lazy(() => import('../pages/data-analyse/ProvinceTrend')) }
        ]
      },
      {
        name: '专业',
        path: '/analyse/spec',
        exact: false,
        Comp: DeepRouter,
        children: [
          { name: '专业情况分析', path: '/analyse/spec/info', exact: true, Comp: lazy(() => import('../pages/data-analyse/SpecInfo')) },
          { name: '各专业历年趋势', path: '/analyse/spec/trend', exact: true, Comp: lazy(() => import('../pages/data-analyse/SpecTrend')) }
        ]
      },
      { name: '民族', path: '/analyse/nation', exact: true, Comp: lazy(() => import('../pages/data-analyse/Nation')) },
      {
        name: '计划统计',
        path: '/analyse/plan',
        exact: false,
        Comp: DeepRouter,
        children: [
          { name: '计划录取匹配', path: '/analyse/plan/match', exact: true, Comp: lazy(() => import('../pages/data-analyse/PlanMatch')) },
          { name: '计划数据统计', path: '/analyse/plan/info', exact: true, Comp: lazy(() => import('../pages/data-analyse/PlanInfo')) }
        ]
      },
      {
        name: '生源高中',
        path: '/analyse/highschool',
        exact: false,
        Comp: DeepRouter,
        children: [
          { name: '情况分析', path: '/analyse/highschool/info', exact: true, Comp: lazy(() => import('../pages/data-analyse/HighschoolInfo')) },
          { name: '历年趋势', path: '/analyse/highschool/trend', exact: true, Comp: lazy(() => import('../pages/data-analyse/HighschoolTrend')) }
        ]
      },
      { name: '考生类别', path: '/analyse/category', exact: true, Comp: lazy(() => import('../pages/data-analyse/Category')) },
      {
        name: '新高考选科',
        path: '/analyse/gaokao',
        exact: false,
        Comp: DeepRouter,
        children: [
          { name: '新高考选科', path: '/analyse/gaokao/select', exact: true, Comp: lazy(() => import('../pages/data-analyse/GaokaoSelect')) },
          { name: '新高考选科趋势', path: '/analyse/gaokao/selectTrend', exact: true, Comp: lazy(() => import('../pages/data-analyse/GaokaoSelectTrend')) }
        ]
      },
      { name: '更多数据统计', path: '/analyse/record', exact: true, Comp: lazy(() => import('../pages/data-analyse/MoreData')) },
      { name: '自定义数据统计', path: '/analyse/custom', exact: true, Comp: lazy(() => import('../pages/data-analyse/CustomData')) },
      {
        name: '院校数据对比',
        path: '/analyse/contrast',
        exact: false,
        Comp: DeepRouter,
        children: [
          { name: '投档分对比', path: '/analyse/contrast/score', exact: true, Comp: lazy(() => import('../pages/helper/ContrastScore')) },
          { name: '专业分（专业维度）', path: '/analyse/contrast/major', exact: true, Comp: lazy(() => import('../pages/helper/Major')) },
          { name: '专业分（学校维度）', path: '/analyse/contrast/majorshool', exact: true, Comp: lazy(() => import('../pages/helper/Majorshool')) },
          { name: '招生计划', path: '/analyse/contrast/enplan', exact: true, Comp: lazy(() => import('../pages/helper/Enplan')) }
        ]
      },
      {
        name: '公共信息查询',
        path: '/analyse/public_message_msg',
        exact: false,
        Comp: DeepRouter,
        children: [
          { name: '一分一段表', path: '/analyse/public_message_msg/ProvincialControlLine', exact: true, Comp: lazy(() => import('../pages/helper/ProvincialControlLine')) },
          { name: '省控线', path: '/analyse/public_message_msg/Segmentation', exact: true, Comp: lazy(() => import('../pages/helper/Segmentation')) },
          { name: '生源高中', path: '/analyse/public_message_msg/SourceOfStudents', exact: true, Comp: lazy(() => import('../pages/helper/SourceOfStudents')) }
        ]
      },
    ]
  },
  // {
  //   name: '生源质量报告', path: '/report', exact: true, Comp: lazy(() => import('../pages/data-analyse/Report'))
  // },  
  {
    name: '生源质量报告', path: '/report', exact: false, Comp: DeepRouter,
    children: [
      { name: '简洁版', path: '/report/brevity', exact: true, Comp: lazy(() => import('../pages/data-analyse/Report')) },
      { name: '详情版', path: '/report/detailed', exact: true, Comp: lazy(() => import('../pages/quality-report/Index')) },
    ]
  },
  {
    name: '通知书管理',
    path: '/notice',
    exact: false,
    Comp: DeepRouter,
    children: [
      { name: '通知书编号生成', path: '/notice/number', exact: true, Comp: lazy(() => import('../pages/data-notice/Number')) },
      { name: '通知书模板设置', path: '/notice/template', exact: true, Comp: lazy(() => import('../pages/data-notice/Template')) },
      { name: '通知书打印管理', path: '/notice/print', exact: true, Comp: lazy(() => import('../pages/data-notice/Print')) },
      { name: '通知书数据流转', path: '/notice/dataDransfer', exact: true, Comp: lazy(() => import('../pages/data-notice/DataDransfer')) }
    ]
  },
  {
    name: '数据发布',
    path: '/release',
    exact: false,
    Comp: DeepRouter,
    children: [
      { name: '录取结果发布', path: '/release/enroll', exact: true, Comp: lazy(() => import('../pages/data-release/Enroll')) },
      { name: '历年数据发布', path: '/release/overyears', exact: true, Comp: lazy(() => import('../pages/data-release/Overyears')) },
      { name: '志愿报考分析', path: '/release/AnalysisVA', exact: true, Comp: lazy(() => import('../pages/data-release/AnalysisVA')) },
      { name: '招生计划发布', path: '/release/plan', exact: true, Comp: lazy(() => import('../pages/data-release/Plan')) },
      { name: '自定义数据发布', path: '/release/custom', exact: true, Comp: lazy(() => import('../pages/data-release/Custom')) },
      { name: '录取进程', path: '/release/enrollProcess', exact: false, Comp: lazy(() => import('../pages/data-notice/EnrollProcess')) }
    ]
  },
  {
    name: '统计准备',
    path: '/census',
    exact: false,
    Comp: DeepRouter,
    children: [{ name: '计划录取匹配', path: '/census/plan', exact: true, Comp: lazy(() => import('../pages/data-census/LuQuPiPei')) }]
  },
  {
    name: '权限管理',
    path: '/authority',
    exact: false,
    Comp: DeepRouter,
    children: [
      { name: '角色管理', path: '/authority/role', exact: true, Comp: lazy(() => import('../pages/authority/Role')) },
      { name: '用户管理', path: '/authority/account', exact: true, Comp: lazy(() => import('../pages/authority/Account')) },
      { name: '登录日志', path: '/authority/loginLog', exact: true, Comp: lazy(() => import('../pages/authority/LoginLog')) },
      { name: '操作日志', path: '/authority/operationLog', exact: true, Comp: lazy(() => import('../pages/authority/OperationLog')) }
    ]
  },
  {
    name: '辅助功能',
    path: '/helper',
    exact: false,
    Comp: DeepRouter,
    children: [
      {
        name: '萌新H5',
        path: '/helper/hreport',
        exact: false,
        Comp: lazy(() => import('../pages/data-hreport/Hreport'))
      },
      {
        name: '',
        path: '/helper/template',
        tips: '萌新h5预览',
        exact: false,
        Comp: lazy(() => import('../pages/data-hreport/Template'))
      },
      {
        name: '喜报管理', path: '/helper/goodNews', exact: false, Comp: DeepRouter,
        children: [
          { name: '', tips: '喜报模板编辑', path: '/helper/goodNews/edit', exact: false, Comp: lazy(() => import('../pages/good-news/NewsHandle')) },
          { name: '喜报模板设置', path: '/helper/goodNews/template', exact: true, Comp: lazy(() => import('../pages/good-news/Template')) },
          { name: '喜报生成管理', path: '/helper/goodNews/generate', exact: true, Comp: lazy(() => import('../pages/good-news/Generate')) },
        ]
      },
      {
        name: '新生问卷调查',
        path: '/helper/questionnaire',
        exact: false,
        Comp: lazy(() => import('../pages/questionnaire/QuestionList'))
      },
      { name: '', tips: '问卷详情', path: '/helper/paperedit', exact: false, Comp: lazy(() => import('../pages/questionnaire/QuestionHandle')) },
      { name: '', tips: '问卷分析', path: '/helper/datasolution', exact: false, Comp: lazy(() => import('../pages/questionnaire/Datasolution')) },
      { name: '', tips: '问卷数据查看', path: '/helper/questioncheck', exact: false, Comp: lazy(() => import('../pages/questionnaire/Questioncheck')) },
      {
        name: '分班分学号',
        path: '/helper/division',
        exact: false,
        Comp: DeepRouter,
        children: [
          { name: '分班专业设置', path: '/helper/division/config', exact: true, Comp: lazy(() => import('../pages/helper/DivisionConfig')) },
          { name: '分班', path: '/helper/division/class', exact: true, Comp: lazy(() => import('../pages/helper/DivisionClass')) },
          { name: '分学号', path: '/helper/division/number', exact: true, Comp: lazy(() => import('../pages/helper/DivisionNumber')) }
        ]
      },
      {
        name: '迎新报到',
        path: '/helper/welcome',
        exact: false,
        Comp: DeepRouter,
        children: [
          { name: '二维码生成', path: '/helper/welcome/qrcode', exact: true, Comp: lazy(() => import('../pages/helper/welcome/QrCode')) },
          { name: '报到查询', path: '/helper/welcome/baodao', exact: true, Comp: lazy(() => import('../pages/helper/welcome/BaoDao')) },
        ]
      }
    ]
  },
  {
    name: '教育调查表',
    path: '/jydcb',
    exact: false,
    Comp: DeepRouter,
    children: [
      {
        name: '教育事业统计表',
        path: '/jydcb/jysytjb',
        exact: false,
        Comp: DeepRouter,
        children: [
          { name: '教基3324', path: '/jydcb/jysytjb/jiaoji3324', exact: true, Comp: lazy(() => import('../pages/data-jydcb/Jiaoji3324')) },
          { name: '教基3326', path: '/jydcb/jysytjb/jiaoji3326', exact: true, Comp: lazy(() => import('../pages/data-jydcb/Jiaoji3326')) },
          { name: '教基3335', path: '/jydcb/jysytjb/jiaoji3335', exact: true, Comp: lazy(() => import('../pages/data-jydcb/Jiaoji3335')) },
          { name: '教基3336', path: '/jydcb/jysytjb/jiaoji3336', exact: true, Comp: lazy(() => import('../pages/data-jydcb/Jiaoji3336')) },
          { name: '教基3337', path: '/jydcb/jysytjb/jiaoji3337', exact: true, Comp: lazy(() => import('../pages/data-jydcb/Jiaoji3337')) },
          { name: '教基3338', path: '/jydcb/jysytjb/jiaoji3338', exact: true, Comp: lazy(() => import('../pages/data-jydcb/Jiaoji3338')) },
          { name: '教基3339', path: '/jydcb/jysytjb/jiaoji3339', exact: true, Comp: lazy(() => import('../pages/data-jydcb/Jiaoji3339')) },
        ]
      },
      {
        name: '基本教育状态表',
        path: '/jydcb/jbjyztb',
        exact: false,
        Comp: DeepRouter,
        children: [
          { name: '表6-3-1', path: '/jydcb/jbjyztb/biao631', exact: true, Comp: lazy(() => import('../pages/data-jydcb/Biao631')) },
          { name: '表6-3-2', path: '/jydcb/jbjyztb/biao632', exact: true, Comp: lazy(() => import('../pages/data-jydcb/Biao632')) },
          { name: '表6-3-3', path: '/jydcb/jbjyztb/biao633', exact: true, Comp: lazy(() => import('../pages/data-jydcb/Biao633')) },
        ]
      }
    ]
  },
  {
    name: '辅助管理',
    path: '/aided',
    exact: false,
    Comp: DeepRouter,
    children: [
      { name: '录取费用管理', path: '/aided/payment', exact: true, Comp: lazy(() => import('../pages/data-aided/Payment')) },      
    ]
  },
  {
    name: '人像核验',
    path: '/verify',
    exact: false,
    Comp: DeepRouter,
    children: [
      { name: '二维码生成', path: '/verify/qrcode', exact: true, Comp: lazy(() => import('../pages/portrait-verification/CreatQR')) },
      { name: '核验查询', path: '/verify/info', exact: true, Comp: lazy(() => import('../pages/portrait-verification/VerifyInfo')) },
      { name: '核验信息下载', path: '/verify/download', exact: true, Comp: lazy(() => import('../pages/portrait-verification/VerifyDownload')) },
      { name: '核验剩余次数查询', path: '/verify/available-times', exact: true, Comp: lazy(() => import('../pages/portrait-verification/AvailableTimes')) },
    ]
  },
  {
    name: '辅助管理',
    path: '/aided',
    exact: false,
    Comp: DeepRouter,
    children: [
      { name: '录取费用管理', path: '/aided/payment', exact: true, Comp: lazy(() => import('../pages/data-aided/Payment')) },      
    ]
  },
  { path: '*', exact: true, Comp: lazy(() => import('../pages/_404')) }
]

export default menuRouter
// 写完路由后要按照上述方式排列，否则会导致行数溢出导致无法提交到git
