// * 迎新报到
const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}

export const reportSetGetSet = 'reportSetGetSet'
setRealUrl(`获取报到设置信息`, reportSetGetSet, () => api + `/welcome/appwelcome/reportSet/getSet`)
export const reportSetSet = 'reportSetSet'
setRealUrl(`报到设置`, reportSetSet, () => api + `/welcome/appwelcome/reportSet/set`)
export const reportLinkList = 'reportLinkList'
setRealUrl(`二维码生成列表`, reportLinkList, () => api + `/welcome/appwelcome/reportLink/lists`)
export const reportLinkPreview = 'reportLinkPreview'
setRealUrl(`二维码预览`, reportLinkPreview, () => api + `/welcome/appwelcome/reportLink/preview`)
export const reportLinkResetLinkQrCodeAll = 'reportLinkResetLinkQrCodeAll'
setRealUrl(`重置全部二维码`, reportLinkResetLinkQrCodeAll, () => api + `/welcome/appwelcome/reportLink/resetLinkQrCodeAll`)
export const reportLinkResetLinkAll = 'reportLinkResetLinkAll'
setRealUrl(`重置全部链接及密钥`, reportLinkResetLinkAll, () => api + `/welcome/appwelcome/reportLink/resetLinkAll`)
export const reportLinkResetQrCode = 'reportLinkResetQrCode'
setRealUrl(`重置二维码`, reportLinkResetQrCode, () => api + `/welcome/appwelcome/reportLink/resetQrCode`)
export const reportLinkResetLink = 'reportLinkResetLink'
setRealUrl(`重置链接及密钥`, reportLinkResetLink, () => api + `/welcome/appwelcome/reportLink/resetLink`)
export const reportLinkBatchDownload = 'reportLinkBatchDownload'
setRealUrl(`导出全部二维码`, reportLinkBatchDownload, () => api + `/welcome/appwelcome/reportLink/batchDownload`)
export const reportLinkGetCampus = 'reportLinkGetCampus'
setRealUrl(`获取学院列表`, reportLinkGetCampus, () => api + `/welcome/appwelcome/reportLink/getCampus`)

export const reportCampusConfig = 'reportCampusConfig'
setRealUrl(`获取学院配置信息`, reportCampusConfig, () => api + `/welcome/appwelcome/reportCampus/config`)
export const reportCampusInfo = 'reportCampusInfo'
setRealUrl(`学院维度-数据总览`, reportCampusInfo, () => api + `/welcome/appwelcome/reportCampus/info`)
export const reportCampusCampusLists = 'reportCampusCampusLists'
setRealUrl(`学院维度-学院列表`, reportCampusCampusLists, () => api + `/welcome/appwelcome/reportCampus/campusLists`)

export const reportStudentsConfig = 'reportStudentsConfig'
setRealUrl(`获取学生配置信息`, reportStudentsConfig, () => api + `/welcome/appwelcome/reportStudents/config`)
export const reportStudentsInfo = 'reportStudentsInfo'
setRealUrl(`学生维度-数据总览`, reportStudentsInfo, () => api + `/welcome/appwelcome/reportStudents/info`)
export const reportStudentsBatchUpdate = 'reportStudentsBatchUpdate'
setRealUrl(`学生维度-批量修改`, reportStudentsBatchUpdate, () => api + `/welcome/appwelcome/reportStudents/batchUpdate`)
export const reportStudentsLists = 'reportStudentsLists'
setRealUrl(`学生维度-学生列表`, reportStudentsLists, () => api + `/welcome/appwelcome/reportStudents/lists`)

export default realUrl