/**
*
* @author : 田源
* @date : 2022-10-17 12:02
* @description : 权限管理接口
*
*/
const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}

export const accountList = 'accountList'
export const roleNameList = 'roleNameList'
export const deleteAccount = 'deleteAccount'
export const addAccount = 'addAccount'
export const modifyAccount = 'modifyAccount'
export const roleList = 'roleList'
export const addRole = 'addRole'
export const modifyRole = 'modifyRole'
export const deleteRole = 'deleteRole'
export const hasRole = 'hasRole'
export const getAllRoleMenu = 'getAllRoleMenu'
export const getRoleMenu = 'getRoleMenu'
export const getRoleCampusList = 'getRoleCampusList'

setRealUrl(`用户-列表`, accountList, () => api + `/school/appschool/user/lists`)
setRealUrl(`用户-角色筛选列表`, roleNameList, () => api + `/school/appschool/role/name`)
setRealUrl(`用户-新增`, addAccount, () => api + `/school/appschool/user/add`)
setRealUrl(`用户-编辑`, modifyAccount, () => api + `/school/appschool/user/modify`)
setRealUrl(`用户-删除`, deleteAccount, () => api + `/school/appschool/user/del`)
setRealUrl(`角色-列表`, roleList, () => api + `/school/appschool/role/lists`)
setRealUrl(`角色-新增`, addRole, () => api + `/school/appschool/role/add`)
setRealUrl(`角色-编辑`, modifyRole, () => api + `/school/appschool/role/modify`)
setRealUrl(`角色-删除`, deleteRole, () => api + `/school/appschool/role/del`)
setRealUrl(`角色-是否存在`, hasRole, () => api + `/school/appschool/role/has`)
setRealUrl(`角色-全部菜单`, getAllRoleMenu, () => api + `/school/appschool/role/info`)
setRealUrl(`角色-当前角色菜单`, getRoleMenu, () => api + `/school/appschool/role/roleInfo`)
setRealUrl(`角色-院系下拉`, getRoleCampusList, () => api + `/school/appschool/role/campus`)

export default realUrl