
// 特殊处理的路由地址

const getInfo = {
  XITONG: [
    '/handle/xueyuanzhuanye',
    '/handle/zhaosheng',
    '/manage/xinxi'
  ],
  RENXIANG: [
    '/verify/qrcode',
    '/verify/info',
    '/verify/available-times'
  ],
}
export default getInfo