/*
 * @Author: 郝家奎
 * @Date: 2023-04-21 14:17:21
 * @LastEditTime: 2023-04-23 09:47:38
 * @LastEditors: 郝家奎
 * @FilePath: \ddp_pc\src\components\common\AllAuthButton.jsx
 * @Description: 基于源哥的页面级权限校验按钮做的升级版，支持列表按钮权限校验
 * btnsInfo: 按钮信息
    tab: 页面有多个tab的情况下，需传入当前是第几个tab
    pageBtn:按钮级别：默认为1，页面级别，别的为组件级    页面级别是tag，列表级是oprations
 */
import { Dropdown, Button } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useRouterInfo } from '../../hooks'
/**
 *    <AuthButton
 *           pageBtn={1}
            btnsInfo={[
              { key: 'add', label: '新增', onClick: () => openModal({ modal: MODAL_TYPE.add.modal }), type: 'primary' }
            ]}
          />
DataPublishAuthButton
AllAuthButton
*/

const AllAuthButton = ({ pageBtn = 1, btnsInfo = [], isNeedMore = false, tab = 1 }) => {
  const { tags = [], oprations = [] } = useRouterInfo()
  const TAG = pageBtn === 1 ? tags : oprations
  //  以下console仅用于开发时校验，上线时须注释掉
  // console.log(`当前'pageBtn'参数如果是1则是页面级，否则是组件级，当前状态是:${pageBtn}`, `当前使用的权限数组是:${pageBtn === 1 ? 'tags' : 'oprations'}`)
  // console.log('TAG :>> ', TAG, oprations)
  const tagsArray = TAG[tab - 1]?.filter(tag => tag.show) || []
  // console.log('tagsArray :>> ', tagsArray)
  const more = isNeedMore && tagsArray.length > 3 ? tagsArray.slice(0, -2) : false
  const btns = isNeedMore && tagsArray.length > 3 ? tagsArray.slice(-2) : tagsArray
  // console.log('btns :>> ', btns)
  return (
    <>
      {isNeedMore && more && (
        <Dropdown menu={{ items: more.map(item => btnsInfo.find(btn => btn.key === item.tag)) }}>
          <Button style={{ marginLeft: '8px' }}>
            更多操作
            <DownOutlined />
          </Button>
        </Dropdown>
      )}
      {btns.map(item => {
        const info = btnsInfo.find(btn => btn.key === item.tag)
        if (!info) return null
        return (
          <Button
            disabled={info.disabled || false}
            loading={info.loading}
            ghost={info.ghost || false}
            key={item.tag}
            style={info.style ? { ...info.style } : { marginLeft: '8px' }}
            type={info.type || ''}
            onClick={info.onClick}
            danger={info.danger || false}>
            {info.loading && info.labelText ? info.labelText : info.label}
          </Button>
        )
      })}
    </>
  )
}

export default AllAuthButton
