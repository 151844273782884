 
/**
 * @Author: 郝家奎
 * @Date: 2023-02-01 16:40:44
 * @LastEditTime: 2023-02-01 16:40:47
 * @LastEditors: 郝家奎
 * @FilePath: \ddp_pc\src\axios\api\gaokao.js
 * @Description: 高考选科&趋势
 * 
 */
const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}
export const getProvince = 'getProvince'
export const getCategory = 'getCategory'
export const getBatch = 'getBatch'
export const getSpecial = 'getSpecial'
export const getCampus = 'getCampus'
export const getChartsResource = `getChartsResource`
export const getGaoKaoSelectTableList = `getGaoKaoSelectTableList`

setRealUrl(`获取省`, getProvince, () => api + `/school/appschool/elective/getProvince`)
setRealUrl(`获取科类`, getCategory, () => api + `/school/appschool/elective/getCategory`)
setRealUrl(`获取批次`, getBatch, () => api + `/school/appschool/elective/getBatch`)
setRealUrl(`获取专业`, getSpecial, () => api + `/school/appschool/elective/getSpec`)
setRealUrl(`获取学院`, getCampus, () => api + `/school/appschool/elective/getCampus`)
setRealUrl(`获取图表数据`, getChartsResource, () => api + `/school/appschool/elective/info`)
setRealUrl(`获取表格数据`, getGaoKaoSelectTableList, () => api + `/school/appschool/elective/lists`)
// ------------------高考选课over
// start 高考选课趋势
export const getGaokaoTrendProvince = `getGaokaoTrendProvince`
export const getGaokaoTrendSpec = `getGaokaoTrendSpec`
export const getGaokaoTrendSingle = `getGaokaoTrendSingle`
export const getGaokaoTrendAccount = `getGaokaoTrendAccount`
export const getGaokaoTrendExtTrend = `getGaokaoTrendExtTrend`
setRealUrl(`历年趋势省份`, getGaokaoTrendProvince, () => api + `/school/appschool/elective/province`)
setRealUrl(`历年趋势专业`, getGaokaoTrendSpec, () => api + `/school/appschool/elective/spec`)
setRealUrl(`历年趋势单科分析`, getGaokaoTrendSingle, () => api + `/school/appschool/elective/singleTrend`)
setRealUrl(`历年趋势组合科目筛选`, getGaokaoTrendAccount, () => api + `/school/appschool/elective/account`)
setRealUrl(`历年趋势组合科目分析`, getGaokaoTrendExtTrend, () => api + `/school/appschool/elective/extTrend`)
export default realUrl