import { useEffect } from "react"
import { useHistory } from "react-router-dom"

/**
*
* @author : 田源
* @date : 2022-08-04 10:58
* @description : 路由监听组件
*
*/
function RouterListen({ children }) {
  const history = useHistory()
  useEffect(() => {
    // 路由切换监听
    const { pathname, search } = history.location
    recordPathChange({ pathname, search })
    const unListen = history.listen(recordPathChange)
    return unListen
  }, [history])

  return children
}

export default RouterListen

// 记录上一个路由和当前路由
const recordPathChange = ({ pathname, search }) => {
  const path = pathname + search
  if (!sessionStorage['currentPath']) {
    sessionStorage['currentPath'] = path
  }
  // 切换路由记录相关pathname
  if (window.sessionStorage['currentPath'] !== path) {
    window.sessionStorage['prePath'] = window.sessionStorage['currentPath']
    window.sessionStorage['currentPath'] = path
  }
}