
import { SignApi } from "./axios"

/**
*
* @author : 田源
* @date : 2022-10-13 11:25
* @description : 用户登录、登出操作统一处理
*
*/
class User {
  constructor() {
    this.emiter = []
  }

  setuserToken = token => localStorage.setItem('userToken', token)
  setuserName = name => localStorage.setItem('userName', name)
  // 保存用户信息，加密
  setuserInfo = userInfo => {
    let signApi = new SignApi()
    localStorage.setItem('userInfo', signApi.encrypt(userInfo))
    localStorage.setItem('userTimestamp', signApi.timestamp)
  }


  // 获取用户信息，解密
  getuserInfo = () => {
    if (!localStorage['userTimestamp'] || !localStorage['userInfo']) return {}
    let signApi = new SignApi({ timestamp: localStorage['userTimestamp'] })
    return signApi.decode(localStorage['userInfo'])
  }
  getuserToken = () => localStorage.getItem('userToken')
  getuserName = () => localStorage.getItem('userName')

  clearUserStorage() {
    localStorage.removeItem('userToken')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('userTimestamp')
    localStorage.removeItem('userName')
  }

  // 向监听组件发送最新的用户数据
  emit = () => {
    this.emiter.forEach(fn => fn(this.getuserInfo()))
  }

  // 开启监听用户信息修改
  listener = (callback) => {
    let index = this.emiter.push(callback)
    return () => {
      this.emiter.splice(index - 1, 1)
    }
  }
}

const user = new User()

export default user