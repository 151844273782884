import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Table, Tooltip } from 'antd'
import { mapValues } from 'lodash'
import EolAxios from '../../axios'
import styles from '../sass/dynamic-table.module.scss'
import { useRouterInfo } from '../../hooks'
import CheckFilterDropdown from './CheckFilterDropdown'

// ! 关于表格列宽，假设滚动条宽度设置为1500，如果当前屏幕宽度大于1500，就以屏幕宽度为准。
// ! 示例提到的滚动条宽度为1500或者大于1500的屏幕宽度，示例如下：
// ! 全部列不设置宽度，则按照滚动条宽度均适配
// ! 全部列设置了宽度，并且总宽度小于滚动条宽度，则均分适配，适配时的宽度会设置的宽度比例进行适配
// ! 全部列设置了宽度，并且总宽度大于滚动条宽度，则按照设置的宽度显示并且出现滚动条
// ! 只设置某几列宽度，剩下列的自动均分适配，使用滚动条宽度减设置的列的总宽度
// ! 表格列宽问题，交给产品处理

// ! 关于操作列：
// ! 操作列由前端写死，后端配置显示哪些数据
// ! 关于筛选条件
// ! 后端设置filters字段，切换时触发onChange函数，此函数需要发送的请求可以在前端具体页面进行提前配置
// ! 初始化筛选条件，由前端添加filteredValue字段进行控制
// ! 关于需要前端重置的字段，由后端统一使用 [_params name]作为字段，由前端拼接内容即可
// ! 关于筛选初始值，由相关功能自主传入screen参数即可
// ! 限制：后台接口筛选条件字段必须与列字段一致。使用的参数必须是filters中的value

const pageSize = 10

/**
*
* @author : 田源
* @date : 2022-09-21 10:29
* @description : 动态生成表格的逻辑
* @param getTableListPath 获取表格的接口路径
* @param openDelete 删除操作函数，接收行信息参数，外部相关功能自己编写弹窗。
* @param openEdit  编辑操作函数，接收行信息参数，外部相关功能自己编写弹窗。
* @param openView  查看操作函数，接收行信息参数,外部相关功能自己编写弹窗。
* @param tab 当前是第几个tab，用于权限操作按钮取值，默认为1
* @param setTips 拥有tips的功能，需要从table接口中获取，setTips设置tips
* @param hasData 是否有数据，用来提供给调用者当前是否拥有数据
* @param rowSelectionKey 批量选中的 key
* @param setRowSelectionKey 批量操作触发的回调函数 `const [rowSelectionKey, setRowSelectionKey] = useState([])`
* @param setSelectedRows 选中的每一行的数据 `const [setSelectedRows, setSelectedRows] = useState([])`
* @param sticky 控制表格是否进行吸顶, 可传boolean或者 配置项，查阅antd文档
* @param tableSize 表格大小, default | middle | small
* @param screen 表头筛选项初始值的设置，表头筛选项初始值value必须是数组 `<DynamicTable screen={name:['xa']} />`
* @param setScreen 设置表头筛选项的函数，用于给外部同步当前表头筛选项的值
* @param setTableListReqParams 设置表格请求的参数，暂用于外部的点击导出
* @param autoColumns 前端控制显示的操作
* @param requiredParamNames 必传的参数名，用于判断是否需要请求表格数据 `['year']`
* @param userFormData 剩余参数，用户触发的一些表格筛选条件 `<DynamicTable year={2021} />`
*
*/
function DynamicTable({
  getTableListPath = '',
  openDelete,
  openEdit,
  openView,
  tab = 1,
  setTips,
  hasData,
  setRowSelectionKey,
  setSelectedRows,
  rowSelectionKey,
  sticky,
  tableSize = 'small',
  screen,
  setScreen,
  setTableListReqParams,
  autoColumns,
  requiredParamNames,
  ...userFormData
}) {
  const { oprations = [] } = useRouterInfo()
  const btnsRef = useRef(oprations[tab - 1]?.filter(b => b.show) || [])
  const deleteRef = useRef(openDelete)
  const editRef = useRef(openEdit)
  const viewRef = useRef(openView)
  const initPageSizeRef = useRef(pageSize) // 不触发依赖，并且保持更新
  const initCurrentPageRef = useRef(1)
  const screenRef = useRef(screen)
  const yearRef = useRef()
  const requiredParamNamesRef = useRef(requiredParamNames)

  deleteRef.current = openDelete
  editRef.current = openEdit
  viewRef.current = openView
  screenRef.current = screen
  requiredParamNamesRef.current = requiredParamNames

  // 当有搜索条件时，重置分页
  // 防止先分页，后搜索搜索不到
  if (userFormData.search) {
    initCurrentPageRef.current = 1
  }
  // ! 切换年份时 重置分页为默认
  if (userFormData?.year) {
    if (userFormData?.year !== yearRef.current) {
      initCurrentPageRef.current = 1
      initPageSizeRef.current = pageSize
    }
    yearRef.current = userFormData.year
  }

  userFormData = JSON.stringify(userFormData) // 对象转成字符串，防止多次触发请求
  // ! 配置分成两部分，1：基本配置项，2：管理员配置项。
  // ! 基本配置项：使用默认值。
  // ! 管理员配置项：没有默认值的选项，同时可覆盖基本配置项的配置
  const baseConfig = {
    loading: false,
    bordered: true,
    columns: [],
    dataSource: [],
    pagination: {
      showQuickJumper: true,
      showSizeChanger: true,
      pageSize: initPageSizeRef.current,
      showTotal: (total) => `共${total}条`,
    },
    size: tableSize,
    sticky: sticky ?? {
      offsetHeader: 46
    },
    scroll: { scrollToFirstRowOnChange: true, x: 1000 },
    rowSelection: setRowSelectionKey || setSelectedRows ? {
      selectedRowKeys: rowSelectionKey,
      // selectedRows,
      onChange: (selectedRowKeys, selectedRows) => {
        setRowSelectionKey?.(selectedRowKeys)
        setSelectedRows?.(selectedRows)
      },
      columnWidth: 30,
    } : undefined,

  }
  const [tableData, setTableData] = useState(baseConfig)

  // 获取数据列表
  const getTableList = useCallback(({
    pagination = { pageSize: initPageSizeRef.current, current: initCurrentPageRef.current },
    filters = {}
  } = {}) => {
    const paseFormData = JSON.parse(userFormData)

    if (Array.isArray(requiredParamNamesRef.current) && requiredParamNamesRef.current?.length > 0) {
      const valid = requiredParamNamesRef.current.some(name => paseFormData[name])
      if (!valid) return
    }

    setTableData(tableData => {
      return {
        ...tableData,
        loading: true
      }
    })

    const params = {
      ...paseFormData,
      screen: filters,
      page: pagination.current,
      limit: pagination.pageSize
    }
    setTableListReqParams?.(params)
    EolAxios.dynamicRequest({
      path: getTableListPath,
      formData: params
    }).then(res => {
      if (!EolAxios.checkResponse({ res, text: "数据获取失败" })) return
      let { columns, dataSource, adminConfig = {}, tips, count } = res
      if (dataSource?.length === 0) {
        dataSource = null
      }
      hasData?.(!dataSource)
      setTips?.(tips)
      // 处理每列数据
      const handledColumns = columns.map(column => {
        // 控制省略部分渲染
        if (column.dataIndex && column.ellipsis) {
          column.render = text => <Tooltip placement="topLeft" title={text}><span>{text}</span></Tooltip>
        }
        // 处理默认筛选条件
        if (column.filters) {
          column.filteredValue = filters?.[column.dataIndex] || []
        }
        if (column.filterSearch) {
          column.filterDropdown = props => (
            <CheckFilterDropdown
              {...props}
            />
          )
        }
        if (column.filters?.length === 0) {
          column.filters = undefined
        }

        // 处理操作列
        if (column.key === 'action') {
          column.render = (_text, record) => {
            return (
              <div className={styles.handleBtnBox}>
                {
                  btnsRef.current.map(btn => {
                    switch (btn.tag) {
                      case '1': // 编辑
                        return <span key={btn.tag} className={styles.handleBtn}
                          onClick={() => editRef.current?.(record)}>编辑</span>
                      case '2': // 删除
                        return <span key={btn.tag} className={`${styles.handleBtn} ${styles.deleteBtn}`}
                          onClick={() => deleteRef.current?.(record)}>删除</span>
                      case '3': // 查看
                        return <span key={btn.tag} className={styles.handleBtn}
                          onClick={() => viewRef.current?.(record)}>查看详情</span>
                      default:
                        return null
                    }
                  })
                }
                {
                  autoColumns && autoColumns.length && autoColumns.map(btnCode => {
                    return <span key={btnCode} className={styles.handleBtn}
                      onClick={() => viewRef?.(record)}>{btnCode}</span>
                  })
                  || null}
              </div>
            )
          }
        }
        return column
      })

      // ! 处理配置项
      if (adminConfig.footerReturn) {
        adminConfig.footer = () => adminConfig._fotter
      }
      setTableData(tableData => {
        return {
          ...tableData,
          ...adminConfig,
          pagination: { ...tableData.pagination, ...pagination, total: count },
          columns: handledColumns,
          dataSource,
          loading: false
        }
      })
    }).finally(_res => {
      setTableData(tableData => {
        return {
          ...tableData,
          loading: false
        }
      })
    })
  }, [userFormData, setTableListReqParams, getTableListPath, setTips, hasData, editRef, deleteRef, viewRef, autoColumns])

  useEffect(() => {
    getTableList({ filters: screenRef.current })
    screenRef.current = {} // 请求成功后需要重置一下初始的筛选条件
  }, [getTableList])

  // * 外部设置批量选中的 key，主要用于操作后清空选中的行 `setRowSelectionKey([])`
  useEffect(() => {
    if (!rowSelectionKey) return
    setTableData(tableData => {
      return {
        ...tableData,
        rowSelection: setRowSelectionKey || setSelectedRows ? {
          selectedRowKeys: rowSelectionKey,
          onChange: (selectedRowKeys, selectedRows) => {
            setRowSelectionKey?.(selectedRowKeys)
            setSelectedRows?.(selectedRows)
          },
          columnWidth: 30,
        } : undefined,
      }
    })
  }, [rowSelectionKey, setSelectedRows, setRowSelectionKey])

  return (
    <Table
      {...tableData}
      onChange={(pagination, filters) => {
        getTableList({ pagination, filters: mapValues(filters, (filter) => filter ?? []) })
        initPageSizeRef.current = pagination.pageSize
        initCurrentPageRef.current = pagination.current
        setScreen?.(filters)
      }}
    />
  )
}

export default memo(DynamicTable)