import { useState, useEffect } from 'react'
import { message, Modal } from 'antd'
import { isFileValid, sendFileTags, handleImportResult, exportFailedInfo } from '../tools'
import useWebSocket from './use-websocket'
import EolAxios from '../axios'

const IMPORT_STATUS = {
  PENDING: 'PENDING', // 未导入
  PROGRESSING: 'PROGRESSING', // 导入中
  SUCCESS: 'SUCCESS', // 导入成功
}

/**
 * 
 * @description: 用于导入的hook，处理了websocket相关逻辑zl
 *
 * @param {string} title 弹窗标题
 * @param {boolean} open Modal是否打开 
 * @param {year} year 导入的年份
 * @param {string} accept 上传文件的类型 例如：'.zip, .rar'
 * @param {string} multiple 是否允许一次上传多个文件，默认为false
 * @param {number} maxSize 上传文件的最大大小 例如：500 代表500MB
 * @param {array} fileList 外部传入的上传文件的列表
 * @param {function} setFileList 外部传入的设置上传文件的列表 需和fileList同时传入
 * @param {function} onCancel Modal关闭回调
 * @param {function} onOk Modal确定回调 
 * @param {function} onError socket错误回调
 * @return {{getUploadProps: (otherProps?:{[k:string]:any})=>({[k:string]:any}),getImportBtnProps: (otherProps?:{[k:string]:any})=>({[k:string]:any}),clearFileList:()=>void,importState: 'PENDING'|'PROGRESSING'|'SUCCESS',handleImport: (path:string,query:{[k:string]:any}) => void,handleCancel: () => void,handleFinish:()=>void,finishLoading:boolean,progressProps: {[k:string]:any},fileList:file[]}}
 * 
 * - getUploadProps: getter函数，获取Upload组件的props，参数用于覆盖或增加
 * - getImportBtnProps: getter函数，获取导入按钮的props，参数用于覆盖或增加
 * - importState: 导入的状态 PENDING: 未导入 PROGRESSING: 导入中 SUCCESS: 导入成功
 * - handleImport: 导入函数，参数为请求路径和请求参数
 * - handleCancel: 取消导入函数
 * - handleFinish: 完成导入函数
 * - finishLoading: 完成按钮的loading状态
 * - progressProps: ImportProgress组件的props
 */
const useImport = ({ title, open, year, accept, multiple = false, maxSize, fileList: fileListProp, setFileList: setFileListProp, onCancel, onOk, onError }) => {
  const [fileList, setFileList] = useState([])
  const [importState, setImportState] = useState(IMPORT_STATUS.PENDING)
  const [currentMessage, setCurrentMessage] = useState(null)

  const { connect, disconnect, } = useWebSocket('ws/connect', {
    manual: true,
    onError,
    onClose: () => {
      if (importState === IMPORT_STATUS.PROGRESSING) {
        onError()
      }
    },
    onMessage: (message) => {
      if (message.cmd === '15') return // * 忽略传输模块的消息
      setCurrentMessage(_ => message)
    }
  })

  const [finishLoading, setFinishLoading] = useState(false) // * 完成按钮的loading状态

  const currentFileList = (fileListProp && setFileListProp) ? fileListProp : fileList
  const setCurrentFileList = (fileListProp && setFileListProp) ? setFileListProp : setFileList

  const getUploadProps = (otherProps) => {
    const uploadProps = {
      onRemove: file => {
        const index = currentFileList.indexOf(file)
        const newFileList = currentFileList.slice()
        newFileList.splice(index, 1)
        setCurrentFileList(newFileList)
      },
      beforeUpload: (file, newFileList) => {
        if (multiple === false) {
          setCurrentFileList(isFileValid(file, accept, maxSize) ? [file] : [])
          return false
        }
        setCurrentFileList([...currentFileList, ...newFileList]
          .reduce((prev, cur) => prev.find(i => i.name === cur.name) ? prev : [...prev, cur], [])
          .filter(file => isFileValid(file, accept, maxSize)))
        return false
      },
      multiple,
      accept,
      fileList: currentFileList,
    }

    return ({
      ...uploadProps,
      ...otherProps,
    })
  }

  const getImportBtnProps = (otherProps) => ({
    disabled: multiple === false && currentFileList.length === 1,
    ...otherProps,
  })

  /**
   * 
   * @param {string} path 导入的接口地址路径
   * @param {object} query 导入需要传的参数
   */
  const handleImport = async (path, query) => {
    if (currentFileList.length === 0) return message.warning('请选择文件')

    setImportState(IMPORT_STATUS.PROGRESSING)

    const formData = new FormData()
    currentFileList.forEach(file => {
      formData.append(multiple === true ? 'file[]' : 'file', file)
    })

    // * 每次导入生成单次的task_id
    const task_id = Date.now()
    sessionStorage.setItem('task_id', task_id)

    EolAxios.dynamicRequest({
      path,
      formData,
      query: {
        ...query,
        task_id,
      },
      timeout: 10 * 60 * 1000,
    }).then((res) => {
      if (!EolAxios.checkResponse({ res })) {
        if (res.errStatus !== 'NETWORK_ERROR') { onCancel() }
        return
      }
      sendFileTags(currentFileList)
    })

  }

  // * 导入取消
  const handleCancel = () => {
    if (importState === IMPORT_STATUS.PROGRESSING) {
      Modal.confirm({
        title: '提示',
        content: title === '解密录取数据' ? '是否要取消当前的数据解密？' : '是否要取消数据的导入？',
        onOk: () => {
          handleImportResult(currentMessage?.data?.data_id, 3, year).then(() => {
            setImportState(IMPORT_STATUS.PENDING)
            disconnect()
            onCancel?.()
          })
        }
      })
    } else {
      disconnect()
      onCancel?.()
    }
  }

  // * 导入完成
  const handleFinish = () => {
    onOk?.()
    setFinishLoading(false)
  }


  useEffect(() => {
    if (open) {
      connect()
    } else {
      setFileList([])
      if (setFileListProp) setFileListProp([])
      setImportState(IMPORT_STATUS.PENDING)
    }
  }, [open, connect, setFileListProp])

  return {
    getUploadProps, // * Upload组件的props getter
    getImportBtnProps, // * 导入按钮的props getter
    // connect,
    // disconnect,
    importState,
    handleImport,
    handleCancel,
    handleFinish,
    finishLoading,
    progressProps: { // * 导入进度组件的props
      data: open ? currentMessage?.data : null,
      isError: currentMessage?.cmd === 'ERROR',
      title,
      importState,
      setImportState,
      handleImportResult,
      exportFailedInfo,
    },
    fileList: currentFileList,
  }
}

export default useImport