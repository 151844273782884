const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}

export const verifytAuditLink = 'verifytAuditLink'
setRealUrl(`链接校验`, verifytAuditLink, () => api + `/verifyt/appverify/audit/link`)
export const verifytAuditLogin = 'verifytAuditLogin'
setRealUrl(`链接登录`, verifytAuditLogin, () => api + `/verifyt/appverify/audit/login`)
export const verifytAuditConfig = 'verifytAuditConfig'
setRealUrl(`链接筛选下拉`, verifytAuditConfig, () => api + `/verifyt/appverify/audit/config`)
export const verifytAuditInfo = 'verifytAuditInfo'
setRealUrl(`审核页面总览`, verifytAuditInfo, () => api + `/verifyt/appverify/audit/info`)
export const verifytAuditList = 'verifytAuditList'
setRealUrl(`审核页面列表`, verifytAuditList, () => api + `/verifyt/appverify/audit/lists`)
export const verifytAuditStudentsInfo = 'verifytAuditStudentsInfo'
setRealUrl(`审核页面弹出学生信息`, verifytAuditStudentsInfo, () => api + `/verifyt/appverify/audit/studentsInfo`)
export const verifytAuditAudit = 'verifytAuditAudit'
setRealUrl(`审核页面审核确认`, verifytAuditAudit, () => api + `/verifyt/appverify/audit/audit`)
export default realUrl