
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import user from '../../user'
import getInfo from '../../assets/static/export-watermark.js'

/**
*
* @author : 田源
* @date : 2022-10-09 14:30
* @description : 组件盒子
*
*/

function CompBox({ children, className, style }) {
  const location = useLocation()
  const [userInfo, setUserInfo] = useState(user.getuserInfo())
  const [styles, setStyles] = useState('')
  useEffect(() => {
    const unListener = user.listener(setUserInfo)
    return unListener
  }, [])

  useEffect(() => {
    if (!userInfo) return
    let isXITONG = getInfo.XITONG.some(item => location.pathname.includes(item))
    let isRENXIANG = getInfo.RENXIANG.some(item => location.pathname.includes(item))
    if (isXITONG && (userInfo.school?.IsWatermark === 1 || userInfo.school?.IsWatermarkVerify === 1)) {
      setStyles('')
    } else if (!isRENXIANG && userInfo.school?.IsWatermark === 1) {
      setStyles('')
    } else if (!isRENXIANG && userInfo.school?.IsWatermark === 2) {
      setStyles('test-user')
    } else if (isRENXIANG && userInfo.school?.IsWatermarkVerify === 1) {
      setStyles('')
    } else if (isRENXIANG && userInfo.school?.IsWatermarkVerify === 2) {
      setStyles('test-user')
    } else if (userInfo.school?.IsWatermark === 2 && userInfo.school?.IsWatermarkVerify === 2) {
      setStyles('test-user')
    }
  }, [userInfo, location.pathname])

  return (
    <div style={{ background: '#fff', padding: '24px', ...style }}
      className={`${styles} ${className ?? ''}`}
    >
      {children}
    </div>
  )
}

export default CompBox