/*
 * @Author: 王松
 * @Descripttion: 
 * @Date: 2023-12-18 16:57:45
 */
// 处理ie浏览器请求多个/问题
function handlerProduction(url) {
  if (process.env.NODE_ENV === 'development') {
    url = '/' + url
  }
  return url
}
const dev = {
  YUN_PATH: 'http://yun.dev.zjzw.cn/login',
  IMAGE_PATH: 'http://zhaolu.dev.zjzw.cn',
  api: handlerProduction('zhaolu.dev.zjzw.cn'),
  mock: handlerProduction('localhost:9996')
}

const release = {
  YUN_PATH: 'https://yun.zjzw.cn/login',
  IMAGE_PATH: '',
  api: process.env.REACT_APP_API_MODEL === 'RELEASE' ? '' : ''
}

module.exports = process.env.REACT_APP_API_MODEL === 'DEV' ? dev : release
