/*
 * @Author: 郝家奎
 * @Date: 2023-01-28 14:17:10
 * @LastEditTime: 2023-03-09 11:48:41
 * @LastEditors: 郝家奎
 * @FilePath: \ddp_pc\src\components\common\ExportBtn.jsx
 * @Description: 通用导出按钮
 */
import { Button } from "antd"
import styles from '../sass/exportBtn.module.scss'
// import {  } from " ";
const ExportComp = (props) => {
  const { handleClick, loading, disabled, leftText } = props
  return (
    <div style={{ justifyContent: leftText ? 'space-between' : 'flex-end' }} className={styles.btn_box}>
      {leftText && <span>{leftText}</span>}
      <Button type="primary" disabled={disabled} loading={loading} onClick={handleClick}>
        {props?.desp || '导出'}
      </Button>
    </div>
  )
}
export default ExportComp