import { Switch } from "react-router-dom"
import { Suspense } from "react"
import { Loading } from "../../components/common"
import { RouteWithSubRoutes } from "../../router"

/**
*
* @author : 田源
* @date : 2022-09-06 15:23
* @description : 多级路由处理
*
*/
function DeepRouter({ routes }) {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {routes.map((route, i) => <RouteWithSubRoutes key={i} {...route} />)}
      </Switch>
    </Suspense>
  )
}

export default DeepRouter