import { SAVE_MENU } from '../actions/action-types'


const initMenu = { menuList: [] }
export const userMenu = (state = initMenu, action) => {
  switch (action.type) {
    case SAVE_MENU:
      return { ...state, menuList: action.payload }
    default:
      return state
  }
}
