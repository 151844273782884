/*
 * @Author: 王松
 * @Descripttion: 
 * @Date: 2023-07-28 17:30:32
 */
import React, { useEffect, useState, useRef } from "react"
import { Checkbox, Input, Empty, Button, } from 'antd'
const CheckFilterDropdown = (props) => {
  const composingRef = useRef(false)
  const { filters, clearFilters, confirm, selectedKeys, setSelectedKeys, visible } = props
  const [selectList, setSelectList] = useState([])
  useEffect(() => {
    setSelectList(filters)
  }, [filters])
  const handleCompositionStart = () => {
    composingRef.current = true
  }
  const handleCompositionEnd = e => {    
    if (composingRef.current) {
      composingRef.current = false
      handleChange(e)
    }
  }
  const handleChange = e => {
    let { value } = e.target    
    if (!composingRef.current) {
      if (value) {
        setSelectList(pre => {          
          if (pre.length) {
            return pre.filter(item => item.text.indexOf(value) > -1)
          } else {
            return filters.filter(item => item.text.indexOf(value) > -1)
          }
        })
      } else {
        setSelectList(filters)
      }
    }

  }
  return (
    <div>
      <div style={{ padding: 8, borderBottom: '1px solid #f0f0f0' }}>
        {visible && <Input
          onCompositionStart={handleCompositionStart}
          onCompositionEnd={handleCompositionEnd}
          onChange={handleChange} placeholder="在筛选项搜索" /> || null}</div>
      <ul style={{ maxHeight: 264, overflowY: 'auto', overflowX: 'hidden', paddingLeft: 8 }}>
        {selectList && selectList.length && selectList.map((item, i) => (<li style={{ padding: '4px 0' }} key={i} >
          <Checkbox onChange={e => {
            const { checked } = e.target
            if (checked) {
              let list = selectedKeys.length ? [...selectedKeys] : []
              list.push(item.value)
              setSelectedKeys(list)
            } else {
              let list = selectedKeys.length ? [...selectedKeys] : []
              list = list.filter(f => f !== item.value)
              setSelectedKeys(list)
            }
          }} checked={selectedKeys?.includes(item.value)}>{item.text}</Checkbox></li>)) || <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </ul>
      <div style={{ display: 'flex', borderTop: '1px solid #f0f0f0', justifyContent: 'space-between', padding: '7px 8px', alignItems: 'center' }}>
        <Button type="link" disabled={!selectedKeys.length} onClick={() => {
          setSelectedKeys([])
          clearFilters()
        }}>重置</Button>
        <Button type="link" disabled={selectedKeys?.length === selectList?.length || !selectList?.length} onClick={() => {
          setSelectedKeys(selectList.map(m => m.value))
        }}>全选</Button>
        <Button type="primary" size='small' onClick={() => {
          confirm()
        }}>确定</Button>
      </div>
    </div>
  )
}

export default CheckFilterDropdown