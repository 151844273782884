import { isEmail, isPassword, isPhone } from "./validator-regexp"

/**
*
* @author : 田源
* @date : 2022-10-17 17:06
* @description : form表单账户相关的验证
*
*/
export const checkAccount = (_, value) => {
  if (!value || isEmail(value) || isPhone(value)) {
    return Promise.resolve()
  } else {
    return Promise.reject('请输入您的邮箱/手机号')
  }
}

export const checkPhone = (_, value) => {
  if (!value || isPhone(value)) {
    return Promise.resolve()
  } else {
    return Promise.reject('请输入正确的手机号码')
  }
}

export const checkPassword = (_, value) => {
  if (!value || isPassword(value)) {
    return Promise.resolve()
  } else {
    return Promise.reject('请输入[8-16]位大小写字母及数字组合密码')
  }
}

// 验证两次密码一致
export const comparisonPassword = ({ getFieldValue }) => ({
  validator(_, value) {
    if (!value || getFieldValue('password') === value) {
      return Promise.resolve()
    }
    return Promise.reject(new Error('两次输入密码不一致'))
  },
})