import { useHistory } from "react-router-dom"
import user from "../user"
import { getQueryVariable, getPlatform } from '../tools'
const { YUN_PATH } = require('../axios/eol-api-domain')
/**
 * @description 设置路由
 * @param _  留给路由写描述用的
 * @param path  路径
 * @param exact 是否严格匹配 
 * @param Comp 组件 
 * @param children :Array<{path,Comp}> 包含路径和组件的数组
 * @param params 路由中的任意参数
 * @returns 路由对象
 */
function setRouter(_, { path, exact = true, Comp, children, ...params }) {
  return setChildrenRoute({ path, exact, Comp, children, ...params })
}

// 递归生成route
const setChildrenRoute = (router) => {
  return {
    path: router.path,
    exact: router.exact,
    component: (props) => RouterGuard(props, router),
    routes: router.children?.map(item => setChildrenRoute(item))
  }
}

// ! 路由守卫
const RouterGuard = (props, router) => {
  const history = useHistory()
  // 必须登录的页面没有userToken，就去登录页面
  getPlatform(res => {
    if (res === '1') {
      if (router.needLogin && !user.getuserToken()) {
        history.replace('/login')
      }
    } else {
      if (router.path !== '/transit') {
        if (getQueryVariable('token')) {
          history.replace(`/transit?token=${getQueryVariable('token')}`)
        } else {
          if (router.needLogin && !user.getuserToken()) {
            window.location.href = `${YUN_PATH}?from=${window.location.href}`
          }
        }
      }
    }
  })
  return <router.Comp {...props} {...router} />
}

export default setRouter