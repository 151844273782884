/*
 * @Author: 王松
 * @Descripttion: 加密
 * @Date: 2024-07-25 18:49:36
 */
import CryptoJS from 'crypto-js'
import md5 from 'js-md5'
import user from '../user'
class SignApi {

  constructor({ timestamp, userName, token } = {}) {
    this.userName = userName ? '' : user.getuserName()
    this.token = token || user.getuserToken()
    this.timestamp = timestamp || Date.now()
    this.iv = md5(`${this.userName}${this.token}`).slice(8, 24)
    this.key = md5(`${this.timestamp}${this.userName}${this.iv}`).slice(8, 24)
  }

  /** 加密函数 */
  encrypt(params = {}) {
    try {
      const aesSign = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(params)),
        CryptoJS.enc.Utf8.parse(this.key),
        {
          iv: CryptoJS.enc.Utf8.parse(this.iv),
          mode: CryptoJS.mode.CBC
        })
      return aesSign.toString()
    } catch {
      user.clearUserStorage()
      window.location.reload()
    }
  }

  /** 解密函数 */
  decode(text = '') {
    try {
      const result = CryptoJS.AES.decrypt(text, CryptoJS.enc.Utf8.parse(this.key), {
        iv: CryptoJS.enc.Utf8.parse(this.iv),
        mode: CryptoJS.mode.CBC,
      })
      return JSON.parse(result.toString(CryptoJS.enc.Utf8) || '{}')
    } catch {
      user.clearUserStorage()
      window.location.reload()
    }
  }
}

export default SignApi