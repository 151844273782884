/*
 * @Author: 王松
 * @Descripttion: 问卷接口
 * @Date: 2023-07-04 09:49:57
 */
const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}
export const paperList = 'paperList'
export const paperAdd = 'paperAdd'
export const paperCopy = 'paperCopy'
export const paperEdit = 'paperEdit'
export const paperDel = 'paperDel'
export const paperYears = 'paperYears'
export const paperPublish = 'paperPublish'
export const getPaper = 'getPaper'
export const getPaperSpec = 'getPaperSpec'
export const addPaperSpec = 'addPaperSpec'
export const specList = 'specList'
export const uploadSpec = 'uploadSpec'
export const addQuestion = 'addQuestion'
export const editQuestion = 'editQuestion'
export const delQuestion = 'delQuestion'
export const rankQuestion = 'rankQuestion'
export const answerAdd = 'answerAdd'
export const answerCheck = 'answerCheck'
export const answerGetPaper = 'answerGetPaper'
export const visitorConfig = 'visitorConfig'
export const visitorData = 'visitorData'
export const visitorExport = 'visitorExport'
export const visitorList = 'visitorList'
export const visitorSee = 'visitorSee'
export const visitorExportVisitor = 'visitorExportVisitor'
setRealUrl(`问卷列表`, paperList, () => api + `/question/survey/paper/list`)
setRealUrl(`问卷新增`, paperAdd, () => api + `/question/survey/paper/add`)
setRealUrl(`问卷复制`, paperCopy, () => api + `/question/survey/paper/copy`)
setRealUrl(`问卷删除`, paperDel, () => api + `/question/survey/paper/del`)
setRealUrl(`问卷编辑`, paperEdit, () => api + `/question/survey/paper/modify`)
setRealUrl(`发布前验证年份`, paperYears, () => api + `/question/survey/paper/years`)
setRealUrl(`发布、撤销`, paperPublish, () => api + `/question/survey/paper/publish`)
setRealUrl(`获取问卷、预览`, getPaper, () => api + `/question/survey/question/getPaper`)
setRealUrl(`获取专业`, getPaperSpec, () => api + `/question1/survey/question/spec`)
setRealUrl(`添加专业`, addPaperSpec, () => api + `/question/survey/question/addSpec`)
setRealUrl(`选择专业下拉`, specList, () => api + `/question/survey/question/specList`)
setRealUrl(`上传专业`, uploadSpec, () => api + `/question/survey/question/uploadSpec`)
setRealUrl(`添加问题`, addQuestion, () => api + `/question/survey/question/add`)
setRealUrl(`问题修改`, editQuestion, () => api + `/question/survey/question/modify`)
setRealUrl(`问题删除`, delQuestion, () => api + `/question/survey/question/del`)
setRealUrl(`问题排序`, rankQuestion, () => api + `/question/survey/question/rank`)
setRealUrl(`答案提交`, answerAdd, () => api + `/question1/survey/answer/add`)
setRealUrl(`学生验证`, answerCheck, () => api + `/question1/survey/answer/check`)
setRealUrl(`获取问卷`, answerGetPaper, () => api + `/question1/survey/question/getPaper`)
setRealUrl(`问卷配置`, visitorConfig, () => api + `/question/survey/visitor/config`)
setRealUrl(`问卷分析`, visitorData, () => api + `/question/survey/visitor/data`)
setRealUrl(`问卷导出`, visitorExport, () => api + `/question/survey/visitor/export`)
setRealUrl(`问卷访客列表`, visitorList, () => api + `/question/survey/visitor/lists`)
setRealUrl(`问卷访客查看`, visitorSee, () => api + `/question/survey/visitor/see`)
setRealUrl(`问卷访客导出`, visitorExportVisitor, () => api + `/question/survey/visitor/exportVisitor`)
export default realUrl