const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}

//  录取结果
export const getProvincesList = 'getProvincesList'
export const getStudentsList = 'getStudentsList'
export const getRelAdmissionResTemplateInfo = 'getRelAdmissionResTemplateInfo'
export const fileUploadResult = 'fileUploadResult'
export const admissionResCheck = 'admissionResCheck'
export const errorListsSearch = 'errorListsSearch'
export const publishLastSend = 'publishLastSend'
export const admissionWithdraw = 'admissionWithdraw'
export const admissionResConfig = 'admissionResConfig'
export const admissionResSearch = 'admissionResSearch'
export const admissiongetYear = 'admissiongetYear'
export const admissiongetGetNotice = 'admissiongetGetNotice'
export const relAdmissionResInfo = 'relAdmissionResInfo'
export const relAdmissionSchoolConfig = 'relAdmissionSchoolConfig'
export const admissionResProcess = 'admissionResProcess'
setRealUrl(`发起异常搜索`, errorListsSearch, () => api + `/school/appschool/relAdmissionRes/admissionResExceptionSearch`)
setRealUrl(`录取结果发布数据检测`, admissionResCheck, () => api + `/school/appschool/relAdmissionRes/admissionResCheck`)
setRealUrl(`录取分数省份维度列表`, getProvincesList, () => api + `/school/appschool/relAdmissionRes/provinceLists`)
setRealUrl(`录取分数学生维度列表`, getStudentsList, () => api + `/school/appschool/relAdmissionRes/studentLists`)
setRealUrl(`录取结果获取的配置信息`, getRelAdmissionResTemplateInfo, () => api + `/school/appschool/relAdmissionRes/templateInfo`)
setRealUrl(`最终发布结果`, publishLastSend, () => api + `/school/appschool/relAdmissionRes/addRel`)
setRealUrl(`一键撤回`, admissionWithdraw, () => api + `/school/appschool/relAdmissionRes/admissionWithdraw`)
setRealUrl(`录取结果展示配置`, admissionResConfig, () => api + `/rel/dataRelease/relAdmissionRes/config`)
setRealUrl(`查询录取进程`, admissionResProcess, () => api + `/rel/dataRelease/relAdmissionRes/getProcess`)
setRealUrl(`录取结果查询`, admissionResSearch, () => api + `/rel/dataRelease/relAdmissionRes/dataSearch`)
setRealUrl(`录取结果查询`, admissiongetYear, () => api + `/school/appschool/relAdmissionRes/getYear`)
setRealUrl(`获取学生的通知书`, admissiongetGetNotice, () => api + `/rel/dataRelease/relAdmissionRes/getNotice`)
setRealUrl(`学生端获取通知书/快递信息`, relAdmissionResInfo, () => api + `/rel/dataRelease/relAdmissionRes/info`)
setRealUrl(`上传附件-获取学院专业`, relAdmissionSchoolConfig, () => api + `/rel/dataRelease/relAdmissionRes/schoolConfig`)
// 招生计划
export const relEnrollPlanAddRel = 'relEnrollPlanAddRel'
export const relEnrollPlanRelDataConfigure = 'relEnrollPlanRelDataConfigure'
export const relEnrollPlanExport = 'relEnrollPlanExport'
export const relEnrollPlanRelDataSearch = 'relEnrollPlanRelDataSearch'
export const relEnrollPlanCheckCustomData = 'relEnrollPlanCheckCustomData'
export const relEnrollPlanClearCustomData = 'relEnrollPlanClearCustomData'
export const relEnrollPlanCustomDataUpload = 'relEnrollPlanCustomDataUpload'
export const relEnrollPlanConfig = 'relEnrollPlanConfig'
export const relEnrollPlanHeaderSearch = 'relEnrollPlanHeaderSearch'
export const relEnrollPlanDataSearch = 'relEnrollPlanDataSearch'
// 历年数据
export const relHistoryHistoryDataConfigure = 'relHistoryHistoryDataConfigure'
export const relHistoryClearCustomData = 'relHistoryClearCustomData'
export const relHistoryCheckCustomData = 'relHistoryCheckCustomData'
export const relHistoryAddRel = 'relHistoryAddRel'
export const relHistoryHistoryDataSearch = 'relHistoryHistoryDataSearch'
export const relHistoryExport = 'relHistoryExport'
export const relHistoryDataCheck = 'relHistoryDataCheck'
export const relHistoryDataConfig = 'relHistoryDataConfig'
export const relHistoryDataHeaderSearch = 'relHistoryDataHeaderSearch'
export const relHistoryDataSearch = 'relHistoryDataSearch'
export const relHistoryDataContrastSearch = 'relHistoryDataContrastSearch'
// 自定义数据发布
export const relCustomDataRelList = 'relCustomDataRelList'
export const relCustomDataRelConfigure = 'relCustomDataRelConfigure'
export const relCustomDataEditRelManage = 'relCustomDataEditRelManage'
export const relCustomDataList = 'relCustomDataList'
export const relCustomData = 'relCustomData'
export const relCustomDataImport = 'relCustomDataImport'
export const relCustomDataTemplate = 'relCustomDataTemplate'
export const relCustomDataEdit = 'relCustomDataEdit'
export const relCustomDataDel = 'relCustomDataDel'
export const relCustomDataClear = 'relCustomDataClear'
export const relCustomDataAddRelConfigure = 'relCustomDataAddRelConfigure'
export const relCustomDataEditRelConfigure = 'relCustomDataEditRelConfigure'
export const relCustomDataConfig = 'relCustomDataConfig'
export const relCustomDataDataSearch = 'relCustomDataDataSearch'

export const relEnrollPlanDataCheck = 'relEnrollPlanDataCheck'
export const addRelAnnex = 'addRelAnnex'
export const resetLinks = 'resetLinks'
export const getHasPublishYearsList = 'getHasPublishYearsList'

// 志愿报考分析
export const volAnalysisDataConfigure = 'volAnalysisDataConfigure'
export const volAnalysisDataSearch = 'volAnalysisDataSearch'
export const relVolAnalysisDataCheck = 'relVolAnalysisDataCheck'
export const relVolAnalysisCheckCustomData = 'relVolAnalysisCheckCustomData'
export const relVolAnalysisClearCustomData = 'relVolAnalysisClearCustomData'
export const relVolAnalysisCheckNewToOld = 'relVolAnalysisCheckNewToOld'
export const relVolAnalysisExport = 'relVolAnalysisExport'
export const relVolAnalysisRelScoreOrSectionCheck = 'relVolAnalysisRelScoreOrSectionCheck'
export const relVolAnalysisAddRel = 'relVolAnalysisAddRel'
export const relVolAnalysisDataConfig = 'relVolAnalysisDataConfig'
export const relVolAnalysisDataGetMinScore = 'relVolAnalysisDataGetMinScore'
export const relVolAnalysisDataHeaderSearch = 'relVolAnalysisDataHeaderSearch'
export const relVolAnalysisDataScoreDataSearch = 'relVolAnalysisDataScoreDataSearch'
export const relVolAnalysisDataSectionDataSearch = 'relVolAnalysisDataSectionDataSearch'
export const relVolAnalysisGetScoreSection = 'relVolAnalysisGetScoreSection'

// 志愿报考分析
setRealUrl(`志愿报考分析配置`, volAnalysisDataConfigure, () => api + `/school/appschool/relVolAnalysis/volAnalysisDataConfigure`)
setRealUrl(`志愿分析列表`, volAnalysisDataSearch, () => api + `/school/appschool/relVolAnalysis/volAnalysisDataSearch`)
setRealUrl(`校验数据是否存在`, relVolAnalysisDataCheck, () => api + `/school/appschool/relVolAnalysis/dataCheck`)
setRealUrl(`自定义上传入库`, relVolAnalysisCheckCustomData, () => api + `/school/appschool/relVolAnalysis/checkCustomData`)
setRealUrl(`清空志愿分析自定义数据`, relVolAnalysisClearCustomData, () => api + `/school/appschool/relVolAnalysis/clearCustomData`)
setRealUrl(`新老高考数据对应校验`, relVolAnalysisCheckNewToOld, () => api + `/school/appschool/relVolAnalysis/checkNewToOld`)
setRealUrl(`导出`, relVolAnalysisExport, () => api + `/school/appschool/relVolAnalysis/export`)
setRealUrl(`校验分数、位次、省控线是否存在`, relVolAnalysisRelScoreOrSectionCheck, () => api + `/school/appschool/relVolAnalysis/relScoreOrSectionCheck`)
setRealUrl(`发布`, relVolAnalysisAddRel, () => api + `/school/appschool/relVolAnalysis/addRel`)
setRealUrl(`志愿分析配置`, relVolAnalysisDataConfig, () => api + `/rel/dataRelease/relVolAnalysisData/config`)
setRealUrl(`登录页获取最低分或最低分位次`, relVolAnalysisDataGetMinScore, () => api + `/rel/dataRelease/relVolAnalysisData/getMinScore`)
setRealUrl(`表头层级筛选`, relVolAnalysisDataHeaderSearch, () => api + `/rel/dataRelease/relVolAnalysisData/headerSearch`)
setRealUrl(`按分数获取结果页`, relVolAnalysisDataScoreDataSearch, () => api + `/rel/dataRelease/relVolAnalysisData/scoreDataSearch`)
setRealUrl(`按位次结果页`, relVolAnalysisDataSectionDataSearch, () => api + `/rel/dataRelease/relVolAnalysisData/sectionDataSearch`)
setRealUrl(`输入分数展示位次`, relVolAnalysisGetScoreSection, () => api + `/rel/dataRelease/relVolAnalysisData/getScoreSection`)


setRealUrl(`获取可展示最新当前年列表`, getHasPublishYearsList, () => api + `/school/appschool/relAdmissionRes/getYear`)
setRealUrl(`自定义数据发布-列表`, relCustomDataRelList, () => api + `/school/appschool/relCustomData/relList`)
setRealUrl(`自定义数据发布-（新增或者编辑）获取配置`, relCustomDataRelConfigure, () => api + `/school/appschool/relCustomData/relConfigure`)
setRealUrl(`自定义数据发布-编辑自定义的发布状态`, relCustomDataEditRelManage, () => api + `/school/appschool/relCustomData/editRelManage`)
setRealUrl(`自定义数据发布-数据管理-数据列表`, relCustomDataList, () => api + `/school/appschool/relCustomData/dataList`)
setRealUrl(`自定义数据发布-数据管理-数据单个新增`, relCustomData, () => api + `/school/appschool/relCustomData/dataAdd`)
setRealUrl(`自定义数据发布-数据导入`, relCustomDataImport, () => api + `/school/appschool/relCustomData/dataImport`)
setRealUrl(`自定义数据发布-数据管理-获取数据导入模板`, relCustomDataTemplate, () => api + `/school/appschool/relCustomData/dataTemplate`)
setRealUrl(`自定义数据发布-数据单个编辑`, relCustomDataEdit, () => api + `/school/appschool/relCustomData/dataEdit`)
setRealUrl(`自定义数据发布-数据单个删除`, relCustomDataDel, () => api + `/school/appschool/relCustomData/dataDelete`)
setRealUrl(`自定义数据发布-清空数据`, relCustomDataClear, () => api + `/school/appschool/relCustomData/dataClear`)
setRealUrl(`自定义数据发布-新增发布配置`, relCustomDataAddRelConfigure, () => api + `/school/appschool/relCustomData/addRelConfigure`)
setRealUrl(`自定义数据发布-编辑发布配置`, relCustomDataEditRelConfigure, () => api + `/school/appschool/relCustomData/editRelConfigure`)
setRealUrl(`自定义数据发布-获取展示配置`, relCustomDataConfig, () => api + `/rel/dataRelease/relCustomData/config`)
setRealUrl(`自定义数据发布-获取数据查询结果`, relCustomDataDataSearch, () => api + `/rel/dataRelease/relCustomData/dataSearch`)
// 发布文件上传
setRealUrl(`发布文件上传`, addRelAnnex, () => api + `/school/file/addRelAnnex`)
setRealUrl(`发布链接重置`, resetLinks, () => api + `/school/appschool/relManage/resetLinks`)
// 历年数据
setRealUrl(`历年数据-发布配置`, relHistoryHistoryDataConfigure, () => api + `/school/appschool/relHistoryData/historyDataConfigure`)
setRealUrl(`历年数据-发布清空自定义数据`, relHistoryClearCustomData, () => api + `/school/appschool/relHistoryData/clearCustomData`)
setRealUrl(`历年数据-发布检测自定义数据`, relHistoryCheckCustomData, () => api + `/school/appschool/relHistoryData/checkCustomData`)
setRealUrl(`历年数据-发布`, relHistoryAddRel, () => api + `/school/appschool/relHistoryData/addRel`)
setRealUrl(`历年数据-数据查询`, relHistoryHistoryDataSearch, () => api + `/school/appschool/relHistoryData/historyDataSearch`)
setRealUrl(`历年数据-数据导出`, relHistoryExport, () => api + `/school/appschool/relHistoryData/export`)
setRealUrl(`历年数据-数据存在校验`, relHistoryDataCheck, () => api + `/school/appschool/relHistoryData/dataCheck`)
setRealUrl(`历年数据-获取展示配置`, relHistoryDataConfig, () => api + `/rel/dataRelease/relHistoryData/config`)
setRealUrl(`历年数据-获取头部筛选`, relHistoryDataHeaderSearch, () => api + `/rel/dataRelease/relHistoryData/headerSearch`)
setRealUrl(`历年数据-获取数据查询结果`, relHistoryDataSearch, () => api + `/rel/dataRelease/relHistoryData/dataSearch`)
setRealUrl(`历年数据-获取数据对比结果`, relHistoryDataContrastSearch, () => api + `/rel/dataRelease/relHistoryData/dataContrastSearch`)

// 招生计划
setRealUrl(`招生计划-发布配置`, relEnrollPlanRelDataConfigure, () => api + `/school/appschool/relEnrollPlan/relDataConfigure`)
setRealUrl(`招生计划-数据导出`, relEnrollPlanExport, () => api + `/school/appschool/relEnrollPlan/export`)
setRealUrl(`招生计划-发布执行`, relEnrollPlanAddRel, () => api + `/school/appschool/relEnrollPlan/addRel`)
setRealUrl(`招生计划-发布数据查询`, relEnrollPlanRelDataSearch, () => api + `/school/appschool/relEnrollPlan/relDataSearch`)
setRealUrl(`招生计划-发布数据查询`, relEnrollPlanRelDataSearch, () => api + `/school/appschool/relEnrollPlan/relDataSearch`)
setRealUrl(`招生计划-发布清空自定义数据`, relEnrollPlanClearCustomData, () => api + `/school/appschool/relEnrollPlan/clearCustomData`)
setRealUrl(`招生计划-检测并入库自定义数据`, relEnrollPlanCheckCustomData, () => api + `/school/appschool/relEnrollPlan/checkCustomData`)
setRealUrl(`招生计划-数据是否存在校验`, relEnrollPlanDataCheck, () => api + `/school/appschool/relEnrollPlan/dataCheck`)
setRealUrl(`自定义数据上传（历年数据和招生计划数据）`, relEnrollPlanCustomDataUpload, () => api + `/school/file/customDataUpload`)
setRealUrl(`招生计划-获取展示配置`, relEnrollPlanConfig, () => api + `/rel/dataRelease/relEnrollPlan/config`)
setRealUrl(`招生计划-获取头部筛选`, relEnrollPlanHeaderSearch, () => api + `/rel/dataRelease/relEnrollPlan/headerSearch`)
setRealUrl(`招生计划-获取数据查询结果`, relEnrollPlanDataSearch, () => api + `/rel/dataRelease/relEnrollPlan/dataSearch`)
export const relFileDownload = 'relFileDownload'
setRealUrl(`数据发布-文件下载`, relFileDownload, () => api + `/rel/file/download`)
export default realUrl
