/*
 * @Author: 王松
 * @Descripttion: 
 * @Date: 2023-05-05 14:56:08
 */
const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}
export const getMxYear = 'getMxYear'
export const getMxConfig = 'getMxConfig'
export const uploadMxLogo = 'uploadMxLogo'
export const getMxData = 'getMxData'
export const delLogo = 'delLogo'
setRealUrl(`年份下拉`, getMxYear, () => api + `/mengxin/meng/xin/year`)
setRealUrl(`数据展示范围`, getMxConfig, () => api + `/mengxin/meng/xin/config`)
setRealUrl(`logo上传`, uploadMxLogo, () => api + `/mengxin/logo`)
setRealUrl(`萌新数据`, getMxData, () => api + `/mengxin/meng/xin/view`)
setRealUrl(`logo删除`, delLogo, () => api + `/mengxin/meng/xin/delLogo`)
export default realUrl