import { combineReducers } from 'redux'
import { userMenu } from './menu'
import { globalConfig } from './config'
import { ossProgress } from './ossProgress'

const reducer = combineReducers({
  userMenu,
  globalConfig,
  ossProgress
})

export default reducer