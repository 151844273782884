/*
 * @Author: 付田莉
 * @Descripttion: 老师端报到链接
 * @Date: 2023-06-30 15:13:30
 */
const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}
// 老师端
export const checkLink = 'checkLink'
export const login = 'login'
export const getStudentInfo = 'getStudentInfo'
export const baodaoLists = 'baodaoLists'
export const audit = 'audit'
export const reportProcessGetConfig = 'reportProcessGetConfig'

setRealUrl(`验证链接是否失效`, checkLink, () => api + `/welcomeT/appwelcome/reportProcess/checkLink`)
setRealUrl(`登录`, login, () => api + `/welcomeT/appwelcome/reportProcess/login`)
setRealUrl(`获取单个学生信息`, getStudentInfo, () => api + `/welcomeT/appwelcome/reportProcess/getStudentInfo`)
setRealUrl(`报到列表`, baodaoLists, () => api + `/welcomeT/appwelcome/reportProcess/lists`)
setRealUrl(`审核`, audit, () => api + `/welcomeT/appwelcome/reportProcess/audit`)
setRealUrl(`获取筛选配置`, reportProcessGetConfig, () => api + `/welcomeT/appwelcome/reportProcess/getConfig`)


// 学生端
export const studentCheckLink = 'studentCheckLink'
export const studentCheckTime = 'studentCheckTime'
export const studentLogin = 'studentLogin'
export const getStudentsInfo = 'getStudentsInfo'
export const reReporting = 'reReporting'

setRealUrl(`验证二维码是否失效`, studentCheckLink, () => api + `/welcomeT/appwelcome/reportScan/checkLink`)
setRealUrl(`判断学校是否开启时间限制`, studentCheckTime, () => api + `/welcomeT/appwelcome/reportScan/checkTime`)
setRealUrl(`学生登录`, studentLogin, () => api + `/welcomeT/appwelcome/reportScan/login`)
setRealUrl(`获取学生信息`, getStudentsInfo, () => api + `/welcomeT/appwelcome/reportScan/getStudentsInfo`)
setRealUrl(`重新报到`, reReporting, () => api + `/welcomeT/appwelcome/reportScan/reReporting`)


export default realUrl