/*
 * @Author: 王松
 * @Descripttion: 
 * @Date: 2022-12-27 09:13:30
 */
const { api, mock } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}
export const reportContent = 'reportContent'
export const provinceConfig = 'provinceConfig'
export const provinceInfo = 'provinceInfo'
export const provinceList = 'provinceList'
export const provinceSpec = 'provinceSpec'
export const provinceColTrend = 'provinceColTrend'
export const provinceListsTrend = 'provinceListsTrend'
export const specialExport = 'specialExport'
export const provinceExport = 'provinceExport'
export const specBatchConfig = 'specBatchConfig'
export const specCampusConfig = 'specCampusConfig'
export const specTopData = 'specTopData'
export const specLists = 'specLists'
export const specProvinceList = 'specProvinceList'
export const reportTitle = 'reportTitle'
export const reportConfig = 'reportConfig'
export const provinceCityLists = 'provinceCityLists'
export const provinceCityInfo = 'provinceCityInfo'
export const provinceCityColTrend = 'provinceCityColTrend'
export const provinceCityListsTrend = 'provinceCityListsTrend'


// 各专业历年趋势
export const specTrenTop = 'specTrenTop'
export const specMajorList = `specMajorList`
export const specModalMajorList = `specModalMajorList`
// 专业详情
export const specModalMajorListDetails = `specModalMajorListDetails`

// 
// /stat/nation/top
// 民族字典下
export const nationTopResource = `nationTopResource`
export const nationTimes = 'nationTimes'
export const nationSubjects = 'nationSubjects'
export const nationYears = 'nationYears'
export const nationProvinces = 'nationProvinces'
export const nationDict = `nationDict`
export const nationTableResource = `nationTableResource`
export const nationMapResource = `nationMapResource`
// 志愿率分析
export const volunteerConfig = `volunteerConfig`
export const volunteerInfo = `volunteerInfo`
export const volunteerLists = `volunteerLists`
export const adjustInfo = `adjustInfo`
export const adjustLists = `adjustLists`
export const volunteerSpecInfo = `volunteerSpecInfo`
export const volunteerSpecList = `volunteerSpecList`
export const volunteerEnrollInfo = `volunteerEnrollInfo`
export const volunteerEnrollLists = `volunteerEnrollLists`

// 考生类别
export const candidateTypeSpcial = `candidateTypeSpcial`
export const candidateTypeTop = `candidateTypeTop`
export const candidateTypeTableResource = `candidateTypeTableResource`
// 生源高中
export const highSchoolAnalyseTop = `highSchoolAnalyseTop`
export const highSchoolAnalyseList = `highSchoolAnalyseList`
export const highSchoolAnalyseViewTop = `highSchoolAnalyseViewTop`
export const highSchoolAnalyseViewLists = `highSchoolAnalyseViewLists`
export const highSchoolAnalyseTrendTop = `highSchoolAnalyseTrendTop`
export const highSchoolAnalyseTrendLists = `highSchoolAnalyseTrendLists`
export const highSchoolAnalyseTrendProvince = `highSchoolAnalyseTrendProvince`

// 分数分析
export const scoreGetProvince = `scoreGetProvince`
export const scoreGetCategory = `scoreGetCategory`
export const scoreGetBatch = `scoreGetBatch`
export const scoreTop = `scoreTop`
export const scoreLists = `scoreLists`
export const scoreGetProvinceTrend = `scoreGetProvinceTrend`
export const scoreGetMajorTrend = `scoreGetMajorTrend`
export const scoreLineTrend = `scoreLineTrend`
export const scoreListsTrend = `scoreListsTrend`
export const scoreSingleTop = 'scoreSingleTop'
export const scoreSingleSingleLists = 'scoreSingleSingleLists'
export const scoreSingleSectionTop = 'scoreSingleSectionTop'
export const scoreSingleSectionLists = 'scoreSingleSectionLists'
export const scoreSectionExport = 'scoreSectionExport'
export const diffTop = `diffTop`
export const diffLists = `diffLists`
export const diffProvince = `diffProvince`
export const diffCategory = `diffCategory`
export const diffBatch = 'diffBatch'
export const diffSpec = 'diffSpec'
export const diffColTrend = 'diffColTrend'
export const diffListsTrend = 'diffListsTrend'
export const diffExportAll = 'diffExportAll'
export const diffExportTrend = 'diffExportTrend'

// 计划分析
export const planInfo = 'planInfo'
export const planConfig = 'planConfig'
export const planAllLists = 'planAllLists'
export const planMatchLists = 'planMatchLists'
export const planMatchConfig = 'planMatchConfig'
export const planMatchMatch = 'planMatchMatch'
export const planAdjust = 'planAdjust'
export const planUnbind = 'planUnbind'
export const planGetRule = 'planGetRule'
export const planSetRule = 'planSetRule'
export const planCensus = 'planCensus'
export const planSetShow = 'planSetShow'
export const planGetShow = 'planGetShow'
export const planTip = 'planTip'
export const planUnTip = 'planUnTip'
export const customLists = 'customLists'
export const planCensusT = 'planCensusT'
export const planSetShowT = 'planSetShowT'
export const planGetShowT = 'planGetShowT'

setRealUrl(`民族-批次`, nationTimes, () => api + `/school/appschool/nation/getBatch`)
setRealUrl(`民族-科目`, nationSubjects, () => api + `/school/appschool/nation/getCategory`)
setRealUrl(`民族-年份`, nationYears, () => mock + `/school/appschool/students/getYears`)
setRealUrl(`民族-省份`, nationProvinces, () => api + `/school/appschool/nation/getProvince`)
setRealUrl(`民族-下拉字典`, nationDict, () => api + `/school/appschool/nation/getNation`)
setRealUrl(`民族-top数据`, nationTopResource, () => api + `/school/appschool/nation/top`)
setRealUrl(`民族-表格`, nationTableResource, () => api + `/school/appschool/nation/lists`)
setRealUrl(`民族-地图`, nationMapResource, () => api + `/school/appschool/nation/topMap`)

setRealUrl(`生源质量报告-报告详情`, reportContent, () => api + `/school/appschool/report/lists`)
setRealUrl(`生源质量报告-标题`, reportTitle, () => api + `/school/appschool/report/title`)
setRealUrl(`生源质量报告-筛选`, reportConfig, () => api + `/school/appschool/report/config`)
setRealUrl(`省份分析-配置`, provinceConfig, () => api + `/school/appschool/province/config`)
setRealUrl(`省份分析-情况分析`, provinceInfo, () => api + `/school/appschool/province/info`)
setRealUrl(`省份分析-详情`, provinceList, () => api + `/school/appschool/province/lists`)
setRealUrl(`省份分析-招生专业`, provinceSpec, () => api + `/school/appschool/province/spec`)
setRealUrl(`省份分析-历年趋势图`, provinceColTrend, () => api + `/school/appschool/province/colTrend`)
setRealUrl(`省份分析-历年趋势列表`, provinceListsTrend, () => api + `/school/appschool/province/listsTrend`)
setRealUrl(`省份分析-情况分析导出全部数据`, provinceExport, () => api + `/school/appschool/province/export`)
setRealUrl(`省份分析-下钻各市情况分析详情 `, provinceCityLists, () => api + `/school/appschool/province/cityLists`)
setRealUrl(`省份分析- 下钻各市情况分析`, provinceCityInfo, () => api + `/school/appschool/province/cityInfo`)
setRealUrl(`省份分析-下钻历年趋势柱状图 `, provinceCityColTrend, () => api + `/school/appschool/province/cityColTrend`)
setRealUrl(`省份分析-下钻历年趋势列表`, provinceCityListsTrend, () => api + `/school/appschool/province/cityListsTrend`)

setRealUrl(`专业情况分析-获取全部批次`, specBatchConfig, () => api + `/school/appschool/special/getConfigBatch`)
setRealUrl(`专业情况分析-获取学院`, specCampusConfig, () => api + `/school/appschool/special/getConfigCampus`)
setRealUrl(`专业情况分析-录取情况分析`, specTopData, () => api + `/school/appschool/special/top`)
setRealUrl(`专业情况分析-专业详情`, specLists, () => api + `/school/appschool/special/lists`)
setRealUrl(`专业情况分析-查看招生省份`, specProvinceList, () => api + `/school/appschool/special/prolists`)
setRealUrl(`专业情况分析-导出全部数据`, specialExport, () => api + `/school/appschool/special/export`)

setRealUrl(`专业情况分析-各专业历年趋势`, specTrenTop, () => api + `/school/appschool/special/trendTop`)
setRealUrl(`专业情况分析-查看专业详情(表格表头数据)`, specMajorList, () => api + `/school/appschool/special/lists`)
setRealUrl(`专业情况分析-查看招生省份(弹窗表格表头数据)`, specModalMajorList, () => api + `/school/appschool/special/prolists`)
setRealUrl(`专业情况分析-各专业历年趋势详情表格(不分页)`, specModalMajorListDetails, () => api + `/school/appschool/special/trendLists`)

setRealUrl(`第一志愿分析-批次省份筛选`, volunteerConfig, () => api + `/school/appschool/volunteer/config`)
setRealUrl(`第一志愿分析-柱状图`, volunteerInfo, () => api + `/school/appschool/volunteer/info`)
setRealUrl(`第一志愿分析-列表`, volunteerLists, () => api + `/school/appschool/volunteer/lists`)
setRealUrl(`调剂率分析-柱状图`, adjustInfo, () => api + `/school/appschool/volunteer/adjustInfo`)
setRealUrl(`调剂率分析-列表`, adjustLists, () => api + `/school/appschool/volunteer/adjustLists`)
setRealUrl(`报考数分析-柱状图`, volunteerSpecInfo, () => api + `/school/appschool/volunteer/specInfo`)
setRealUrl(`报考数分析-列表`, volunteerSpecList, () => api + `/school/appschool/volunteer/specLists`)
setRealUrl(`志愿数分析--柱状图`, volunteerEnrollInfo, () => api + `/school/appschool/volunteer/enrollInfo`)
setRealUrl(`志愿数分析--列表`, volunteerEnrollLists, () => api + `/school/appschool/volunteer/enrollLists`)

// 考生类别
setRealUrl(`考生类别-专业`, candidateTypeSpcial, () => api + `/school/appschool/candidateType/getSpec`)
setRealUrl(`考生类别-图表及top`, candidateTypeTop, () => api + `/school/appschool/candidateType/top`)
setRealUrl(`考生类别-表格`, candidateTypeTableResource, () => api + `/school/appschool/candidateType/lists`)

setRealUrl(`生源高中-头部信息、地图及图表`, highSchoolAnalyseTop, () => api + `/school/appschool/highSchoolAnalyse/top`)
setRealUrl(`生源高中-分析列表`, highSchoolAnalyseList, () => api + `/school/appschool/highSchoolAnalyse/lists`)
setRealUrl(`生源高中-查看顶部及图表数据`, highSchoolAnalyseViewTop, () => api + `/school/appschool/highSchoolAnalyse/viewTop`)
setRealUrl(`生源高中-查看列表`, highSchoolAnalyseViewLists, () => api + `/school/appschool/highSchoolAnalyse/viewLists`)
setRealUrl(`生源高中-历年趋势图表`, highSchoolAnalyseTrendTop, () => api + `/school/appschool/highSchoolAnalyse/trendTop`)
setRealUrl(`生源高中-历年趋势列表`, highSchoolAnalyseTrendLists, () => api + `/school/appschool/highSchoolAnalyse/trendLists`)
setRealUrl(`生源高中-历年趋势省份`, highSchoolAnalyseTrendProvince, () => api + `/school/appschool/highSchoolAnalyse/getProvince`)

setRealUrl(`分数-省份`, scoreGetProvince, () => api + `/school/appschool/score/getProvince`)
setRealUrl(`分数-科类`, scoreGetCategory, () => api + `/school/appschool/score/getCategory`)
setRealUrl(`分数-批次`, scoreGetBatch, () => api + `/school/appschool/score/getBatch`)
setRealUrl(`分数-图表`, scoreTop, () => api + `/school/appschool/score/top`)
setRealUrl(`分数-列表`, scoreLists, () => api + `/school/appschool/score/lists`)

setRealUrl(`分数趋势-省份`, scoreGetProvinceTrend, () => api + `/school/appschool/score/province`)
setRealUrl(`分数趋势-专业`, scoreGetMajorTrend, () => api + `/school/appschool/score/special`)
setRealUrl(`分数趋势-图表`, scoreLineTrend, () => api + `/school/appschool/score/colTrend`)
setRealUrl(`分数趋势-列表`, scoreListsTrend, () => api + `/school/appschool/score/listsTrend`)

setRealUrl(`单科成绩分析-柱状图`, scoreSingleTop, () => api + `/school/appschool/score/singleTop`)
setRealUrl(`单科成绩分析-列表`, scoreSingleSingleLists, () => api + `/school/appschool/score/singleLists`)

setRealUrl(`分段分数分析-柱状图`, scoreSingleSectionTop, () => api + `/school/appschool/score/sectionTop`)
setRealUrl(`分段分数分析-列表`, scoreSingleSectionLists, () => api + `/school/appschool/score/sectionLists`)
setRealUrl(`分段分数分析--全部导出`, scoreSectionExport, () => api + `/school/appschool/score/sectionExport`)

setRealUrl(`线差-图表`, diffTop, () => api + `/school/appschool/score/diffTop`)
setRealUrl(`线差-列表`, diffLists, () => api + `/school/appschool/score/diffLists`)
setRealUrl(`线差分析--列表全部导出`, diffExportAll, () => api + `/school/appschool/score/diffExportAll`)

setRealUrl(`线差趋势-省份`, diffProvince, () => api + `/school/appschool/score/diffProvince`)
setRealUrl(`线差趋势-科类`, diffCategory, () => api + `/school/appschool/score/diffCategory`)
setRealUrl(`线差趋势-批次`, diffBatch, () => api + `/school/appschool/score/diffBatch`)
setRealUrl(`线差趋势-专业`, diffSpec, () => api + `/school/appschool/score/diffSpec`)
setRealUrl(`线差趋势-图表`, diffColTrend, () => api + `/school/appschool/score/diffColTrend`)
setRealUrl(`线差趋势-列表`, diffListsTrend, () => api + `/school/appschool/score/diffListsTrend`)
setRealUrl(`线差历年趋势分析--列表 全部导出`, diffExportTrend, () => api + `/school/appschool/score/diffExportTrend`)

setRealUrl(`计划录取匹配-筛选项`, planConfig, () => api + `/school/appschool/plan/config`)
setRealUrl(`计划录取匹配-总览和列表`, planInfo, () => api + `/school/appschool/plan/info`)
setRealUrl(`计划录取匹配-调整`, planAdjust, () => api + `/school/appschool/plan/adjust`)
setRealUrl(`计划录取匹配-解绑`, planUnbind, () => api + `/school/appschool/plan/unAdjust`)
setRealUrl(`计划录取匹配-计划列表`, planAllLists, () => api + `/school/appschool/plan/planLists`)
setRealUrl(`计划录取匹配-已匹配计划列表`, planMatchLists, () => api + `/school/appschool/plan/matchLists`)
setRealUrl(`计划录取匹配-计划筛选项`, planMatchConfig, () => api + `/school/appschool/plan/planConfig`)
setRealUrl(`计划录取匹配-计划筛选项`, planMatchMatch, () => api + `/school/appschool/plan/match`)
setRealUrl(`计划录取匹配-匹配规则获取`, planGetRule, () => api + `/school/appschool/plan/getRule`)
setRealUrl(`计划录取匹配-匹配规则设置`, planSetRule, () => api + `/school/appschool/plan/setRule`)
setRealUrl(`计划数据统计-列表`, planCensus, () => api + `/school/appschool/plan/census`)
setRealUrl(`计划数据统计-显示设置`, planSetShow, () => api + `/school/appschool/plan/setShow`)
setRealUrl(`计划数据统计-获取显示设置`, planGetShow, () => api + `/school/appschool/plan/getShow`)
setRealUrl(`计划数据统计-获取是否提示`, planTip, () => api + `/school/appschool/plan/tip`)
setRealUrl(`计划数据统计-取消提示`, planUnTip, () => api + `/school/appschool/plan/unTip`)
setRealUrl(`计划数据统计-取消提示`, customLists, () => api + `/school/appschool/custom/lists`)
setRealUrl(`计划数据统计--获取显示设置计划维度统计`, planGetShowT, () => api + `/school/appschool/plan/getShowT`)
setRealUrl(`计划数据统计--显示设置 计划统计维度`, planSetShowT, () => api + `/school/appschool/plan/setShowT`)
setRealUrl(`计划数据统计-计划维度列表`, planCensusT, () => api + `/school/appschool/plan/censusT`)

export default realUrl