import { SAVE_MENU } from './action-types'

/**
*
* @author : 田源
* @date : 2022-10-12 16:59
* @description : 当前用户的菜单数据
*
*/
export const saveMenu = (menu) => {
  return {
    type: SAVE_MENU,
    payload: menu,
  }
}