/*
 * @Author: 王松
 * @Descripttion: 
 * @Date: 2022-12-26 18:47:08
 */
import { useEffect, useState } from 'react'
import { Modal, Checkbox } from 'antd'
import styles from '../sass/fixTips.module.scss'
import EolAxios, { API } from '../../axios'

/**
*
* @author : 田源
* @date : 2022-12-26 11:52
* @description : 拥有不规范数据提示
*
*/
const FixTips = ({ year = 2022 }) => {

  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    EolAxios.dynamicRequest({
      path: API.getRemindStatus,
      formData: {
        year: year,
        type: 3,
      },
    }).then(res => {
      if (!EolAxios.checkResponse({ res })) return
      setOpenModal(res.status?.toString() === '2')
    })
  }, [year])


  const [hiddenTips, setHiddenTips] = useState(false)

  const onOk = () => {
    if (hiddenTips) {
      EolAxios.dynamicRequest({
        path: API.modifyStatus,
        formData: {
          year: year,
          type: 3,
        },
      }).then(res => {
        setOpenModal(false)
        if (!EolAxios.checkResponse({ res })) return
      })
    } else {
      setOpenModal(false)
    }
  }

  return <Modal
    title='提醒'
    open={openModal}
    onOk={onOk}
    onCancel={() => { setOpenModal(false) }}
    destroyOnClose
  >
    <p className={styles.tips}>
      当前存在未规范的专业数据，有可能导致统计的数据不准确的情况，如需调整请到学院专业修正模块进行处理。
      <a className={styles.tz} href='/handle/xueyuanzhuanye' target="_blank">点击去调整&nbsp;&gt;&gt;</a>
    </p>
    <div className={styles.knows}>
      <Checkbox onChange={() => { setHiddenTips(t => !t) }} checked={hiddenTips}>我知道了，七天以内无需重复提示。</Checkbox>
    </div>
  </Modal>
}

export default FixTips