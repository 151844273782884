/*
 * @Author: 王松
 * @Descripttion: 
 * @Date: 2023-03-07 09:46:01
 */
import { useEffect, useState } from 'react'
import { Modal } from "antd"
import { useHistory } from "react-router-dom"
import EolAxios, { API } from "../../axios"
import { useRouterInfo } from "../../hooks"
import { useDispatch } from "react-redux"
import { saveMenu } from "../../redux/actions"
import styles from '../sass/introduce-modal.module.scss'
import { usePlatform } from '../../hooks'
const { YUN_PATH } = require('../../axios/eol-api-domain')
const imgW = 2880
const introConfig = {
  '/': {
    1: {
      img: require('../../assets/images/introduce/home1.jpg'),
      action: {
        skip: [565, 363, 680, 389], // 跳过教程
        pre: [], // 上一步
        next: [555, 528, 678, 584], // 下一步      
        end: [] // 结束
      }
    },
    2: {
      img: require('../../assets/images/introduce/home2.jpg'),
      action: {
        skip: [956, 351, 1072, 380], // 跳过教程
        pre: [818, 518, 941, 573], // 上一步
        next: [950, 522, 1073, 574], // 下一步      
        end: [] // 结束
      }
    },
    3: {
      img: require('../../assets/images/introduce/home3.jpg'),
      action: {
        skip: [956, 351, 1072, 380], // 跳过教程
        pre: [812, 583, 938, 641], // 上一步
        next: [944, 582, 1068, 641], // 下一步      
        end: [] // 结束
      }
    },
    4: {
      img: require('../../assets/images/introduce/home4.jpg'),
      action: {
        skip: [954, 583, 1074, 622], // 跳过教程
        pre: [], // 上一步
        next: [], // 下一步      
        end: [918, 752, 1073, 813] // 结束
      }
    }
  },
  '/import': {
    1: {
      img: require('../../assets/images/introduce/import1.jpg'),
      action: {
        skip: [2679, 406, 2802, 443], // 跳过教程
        pre: [], // 上一步
        next: [2676, 584, 2804, 637], // 下一步      
        end: [] // 结束
      }
    },
    2: {
      img: require('../../assets/images/introduce/import2.jpg'),
      action: {
        skip: [1613, 1032, 1737, 1067], // 跳过教程
        pre: [1478, 1206, 1600, 1263], // 上一步
        next: [1607, 1201, 1733, 1262], // 下一步      
        end: [] // 结束
      }
    },
    3: {
      img: require('../../assets/images/introduce/import3.jpg'),
      action: {
        skip: [1438, 614, 1556, 650], // 跳过教程
        pre: [1298, 786, 1426, 841], // 上一步
        next: [1434, 786, 1557, 843], // 下一步      
        end: [] // 结束
      }
    },
    4: {
      img: require('../../assets/images/introduce/import4.jpg'),
      action: {
        skip: [1608, 960, 1730, 996], // 跳过教程
        pre: [1444, 1134, 1567, 1188], // 上一步
        next: [], // 下一步      
        end: [1576, 1129, 1728, 1190] // 结束
      }
    }
  },
  '/handle/xueyuanzhuanye': {
    1: {
      img: require('../../assets/images/introduce/xyzy1.jpg'),
      action: {
        skip: [1168, 272, 1290, 314], // 跳过教程
        pre: [], // 上一步
        next: [1168, 449, 1291, 507], // 下一步      
        end: [] // 结束
      }
    },
    2: {
      img: require('../../assets/images/introduce/xyzy2.jpg'),
      action: {
        skip: [929, 639, 1052, 680], // 跳过教程
        pre: [792, 811, 920, 870], // 上一步
        next: [926, 810, 1052, 870], // 下一步      
        end: [] // 结束
      }
    },
    3: {
      img: require('../../assets/images/introduce/xyzy3.jpg'),
      action: {
        skip: [938, 272, 1060, 315], // 跳过教程
        pre: [802, 450, 926, 507], // 上一步
        next: [931, 444, 1062, 509], // 下一步      
        end: [] // 结束
      }
    },
    4: {
      img: require('../../assets/images/introduce/xyzy4.jpg'),
      action: {
        skip: [1857, 171, 1985, 212], // 跳过教程
        pre: [1694, 340, 1821, 406], // 上一步
        next: [], // 下一步      
        end: [1829, 346, 1982, 404] // 结束
      }
    }
  },
  '/handle/zhaosheng': {
    1: {
      img: require('../../assets/images/introduce/zhaosheng1.jpg'),
      action: {
        skip: [1171, 275, 1290, 315], // 跳过教程
        pre: [], // 上一步
        next: [1160, 444, 1289, 504], // 下一步      
        end: [] // 结束
      }
    },
    2: {
      img: require('../../assets/images/introduce/zhaosheng2.jpg'),
      action: {
        skip: [940, 276, 1059, 313], // 跳过教程
        pre: [801, 443, 926, 503], // 上一步
        next: [931, 448, 1062, 506], // 下一步      
        end: [] // 结束
      }
    },
    3: {
      img: require('../../assets/images/introduce/zhaosheng3.jpg'),
      action: {
        skip: [2304, 356, 2427, 394], // 跳过教程
        pre: [2142, 527, 2269, 583], // 上一步
        next: [], // 下一步      
        end: [2271, 521, 2429, 584] // 结束
      }
    }
  },

  '/handle/chengjixiang': {
    1: {
      img: require('../../assets/images/introduce/cjx1.jpg'),
      action: {
        skip: [1787, 97, 1916, 142], // 跳过教程
        pre: [], // 上一步
        next: [1786, 271, 1915, 333], // 下一步      
        end: [] // 结束
      }
    },
    2: {
      img: require('../../assets/images/introduce/cjx2.jpg'),
      action: {
        skip: [2137, 180, 2256, 219], // 跳过教程
        pre: [1996, 344, 2125, 408], // 上一步
        next: [2127, 349, 2259, 407], // 下一步      
        end: [] // 结束
      }
    },
    3: {
      img: require('../../assets/images/introduce/cjx3.jpg'),
      action: {
        skip: [2356, 172, 2476, 211], // 跳过教程
        pre: [2194, 343, 2314, 403], // 上一步
        next: [], // 下一步      
        end: [2322, 347, 2477, 404] // 结束
      }
    }
  },
  '/analyse/report': {
    1: {
      img: require('../../assets/images/introduce/report1.jpg'),
      action: {
        skip: [985, 402, 1107, 440], // 跳过教程
        pre: [], // 上一步
        next: [982, 573, 1109, 631], // 下一步      
        end: [] // 结束
      }
    },
    2: {
      img: require('../../assets/images/introduce/report2.jpg'),
      action: {
        skip: [1539, 620, 1663, 660], // 跳过教程
        pre: [1407, 793, 1531, 847], // 上一步
        next: [1539, 792, 1664, 852], // 下一步      
        end: [] // 结束
      }
    },
    3: {
      img: require('../../assets/images/introduce/report3.jpg'),
      action: {
        skip: [2348, 175, 2471, 212], // 跳过教程
        pre: [2186, 345, 2312, 404], // 上一步
        next: [], // 下一步      
        end: [2318, 345, 2473, 406] // 结束
      }
    }
  },
  '/analyse/overview': {
    1: {
      img: require('../../assets/images/introduce/overview1.jpg'),
      action: {
        skip: [2374, 161, 2497, 200], // 跳过教程
        pre: [], // 上一步
        next: [2369, 334, 2497, 393], // 下一步      
        end: [] // 结束
      }
    },
    2: {
      img: require('../../assets/images/introduce/overview2.jpg'),
      action: {
        skip: [2358, 634, 2477, 670], // 跳过教程
        pre: [2224, 812, 2346, 872], // 上一步
        next: [2356, 813, 2475, 873], // 下一步      
        end: [] // 结束
      }
    },
    3: {
      img: require('../../assets/images/introduce/overview3.jpg'),
      action: {
        skip: [2355, 697, 2477, 736], // 跳过教程
        pre: [2223, 877, 2351, 939], // 上一步
        next: [2353, 876, 2475, 937], // 下一步      
        end: [] // 结束
      }
    },
    4: {
      img: require('../../assets/images/introduce/overview4.jpg'),
      action: {
        skip: [943, 492, 1060, 529], // 跳过教程
        pre: [781, 672, 905, 729], // 上一步
        next: [], // 下一步      
        end: [912, 670, 1064, 731] // 结束
      }
    }
  },
}
function IntroduceModal({ visible, setVisible }) {
  const { isLocal } = usePlatform()
  const dispatch = useDispatch()
  const { is_show, menu_uuid } = useRouterInfo()
  const { location: { pathname } } = useHistory()
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [step, setStep] = useState(1)
  const [scale, setScale] = useState(window.innerWidth * 0.9 / imgW)
  useEffect(() => {
    setShowModal(false)
    if (is_show) {
      setShowModal(is_show === '1' || visible ? true : false)
      setStep(1)
    }
  }, [pathname, visible, is_show])
  useEffect(() => {
    const updateSize = () => setScale(window.innerWidth * 0.9 / imgW)
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  const showGuide = () => {
    EolAxios.dynamicRequest({
      path: API.configIgnore, formData: {
        menu_uuid: menu_uuid
      }
    }).then(res => {
      if (!EolAxios.checkResponse({ res })) return
      // ! 获取用户菜单
      EolAxios.dynamicRequest({
        path: API.menu
      }).then(res => {
        if (!EolAxios.checkResponse({ res, text: "获取权限菜单失败，请重新登录" })) {
          if (isLocal) {
            history.push('/login')
          } else {
            window.location.href = YUN_PATH
          }
          return
        }
        // 特殊处理一下萌新h5预览
        let menus = [...res.menu]
        let temp = menus.find(item => item.path === '/helper')
        if (temp) {
          // 有问卷列表页，增加编辑、数据分析等路由
          let paper = temp.children.find(fitem => fitem.path === '/helper/questionnaire')
          if (paper) {
            temp.children.push({
              name: '',
              path: '/helper/paperedit'
            }, {
              name: '',
              path: '/helper/datasolution'
            }, {
              name: '',
              path: '/helper/questioncheck'
            })
          }
          // 有萌新h5配置页面，增加萌新h5预览导出路由
          let ht = temp.children.find(fitem => fitem.path === '/helper/hreport')
          if (ht) {
            temp.children.push({
              name: '',
              path: '/helper/template'
            })
          }
        }
        let goodNews = temp.children.find(fitem => fitem.path === '/goodNews')
        if (goodNews) {
          let template = goodNews.children.find(fitem => fitem.path === '/goodNews/template')          
          if (template) {
            goodNews.children.push({
              name: '',
              path: '/goodNews/edit'
            })
          }
        }
        dispatch(saveMenu(res.menu))
      })
    })
  }
  const getCoords = coords => {
    if (coords.length) {
      return coords.map(m => ((m * scale).toFixed(2))).join(',')
    }
    return 0
  }
  const todoAction = k => {
    switch (k) {
      case 'skip':
        setVisible(false)
        showGuide()
        break
      case 'next':
        setStep(pre => {
          return pre + 1
        })
        break
      case 'pre':
        setStep(pre => {
          return pre - 1
        })
        break
      case 'end':
        setVisible(false)
        showGuide()
        break
      default:
        return null
    }
  }
  return <>
    {showModal && introConfig[pathname] &&
      <Modal
        wrapClassName={styles.introduceModalWrap}
        title=""
        open={showModal}
        bodyStyle={{ background: 'rgba(0, 0, 0, 0.45)', padding: 0 }}
        footer={null}
        closable={false}
        maskClosable={false}
        zIndex={99999}
      >
        <div style={{ width: '100%' }} className={styles.ddf}>
          <img style={{ width: '100%' }} src={`${introConfig[pathname][step]['img']}`} alt="" useMap="#MM" />
          <map id="MM" name="MM">
            {Object.keys(introConfig[pathname][step]['action']).map((k, i) => {
              return <area shape="rect" key={i} coords={getCoords(introConfig[pathname][step]['action'][k])} alt="矩形" href="#" onClick={(e) => {
                e.preventDefault()
                todoAction(k)
              }} />
            })}
          </map>
        </div>
      </Modal> || null}
  </>
}
export default IntroduceModal