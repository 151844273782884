/*
 * @Author: 王松
 * @Descripttion: 
 * @Date: 2023-07-07 18:23:05
 */
/**
*
* @author : 田源
* @date : 2022-10-17 12:02
* @description : 数据管理接口
*
*/
const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}

export const planOriginalConfigInfo = 'planOriginalConfigInfo'
export const planOriginalTableList = 'planOriginalTableList'
export const planAdjustConfigInfo = 'planAdjustConfigInfo'
export const planAdjustTableList = 'planAdjustTableList'
export const planList = 'planList'
export const addPlan = 'addPlan'
export const editPlan = 'editPlan'
export const infoPlan = 'infoPlan'
export const deletePlan = 'deletePlan'
export const batchDelete = 'batchDelete'
export const updatePlan = 'updatePlan'
export const reportList = 'reportList'
export const editReport = 'editReport'
export const schoolConfigInfo = 'schoolConfigInfo'
export const schoolTableList = 'schoolTableList'
export const studentsFileds = 'studentsFileds'
export const studentInfoManage = 'studentInfoManage'
export const studentInfoManageInfo = 'studentInfoManageInfo'
export const studentsTemplates = 'studentsTemplates'
export const studentsTemplatesInfo = 'studentsTemplatesInfo'
export const studentsModifiedTemplate = 'studentsModifiedTemplate'
export const studentsDelTemplate = 'studentsDelTemplate'
export const studentsDddTemplate = 'studentsDddTemplate'
export const studentsCustomFields = 'studentsCustomFields'
export const studentsCustomColumnSort = 'studentsCustomColumnSort'
export const studentsImportCustom = 'studentsImportCustom'
export const studentsModified = 'studentsModified'
export const studentsModifiedBatch = 'studentsModifiedBatch'
export const studentsDelBatch = 'studentsDelBatch'
export const studentsDel = 'studentsDel'
export const studentsGetModifiedBatchConfig = 'studentsGetModifiedBatchConfig'
export const studentsReplaceFile = 'studentsReplaceFile'
export const studentsClearFile = 'studentsClearFile'
export const studentAllProvinces = 'studentAllProvinces'
export const studentInfoManageList = 'studentInfoManageList'
export const studentInfoManageFile = 'studentInfoManageFile'
export const fileDownloadPhoto = 'fileDownloadPhoto'
export const setShowPhotoPos = 'setShowPhotoPos'


// export const planConfigInfo = 'getConfigInfo'
// export const planTableList = 'planTableList'

setRealUrl(`获取计划-原始数据-编制配置`, planOriginalConfigInfo, () => api + `/school/appschool/oriPlan/getConfigInfo`)
setRealUrl(`获取计划-原始数据-编制表格`, planOriginalTableList, () => api + `/school/appschool/oriPlan/getTableLists`)
setRealUrl(`获取计划-原始数据-调整配置`, planAdjustConfigInfo, () => api + `/school/appschool/oriPlanAdjust/getConfigInfo`)
setRealUrl(`获取计划-原始数据-调整表格`, planAdjustTableList, () => api + `/school/appschool/oriPlanAdjust/getTableLists`)
setRealUrl(`获取招生计划-列表`, planList, () => api + `/school/appschool/enrollPlan/list`)
setRealUrl(`获取招生计划-新增`, addPlan, () => api + `/school/appschool/enrollPlan/add`)
setRealUrl(`获取招生计划-修改`, editPlan, () => api + `/school/appschool/enrollPlan/modified`)
setRealUrl(`获取招生计划-详情`, infoPlan, () => api + `/school/appschool/enrollPlan/info`)
setRealUrl(`获取招生计划-删除`, deletePlan, () => api + `/school/appschool/enrollPlan/del`)
setRealUrl(`获取招生计划-批量编辑`, updatePlan, () => api + `/school/appschool/enrollPlan/batchUpdate`)
setRealUrl(`获取招生计划-批量删除`, batchDelete, () => api + `/school/appschool/enrollPlan/batchDelete`)
setRealUrl(`报到数据-列表`, reportList, () => api + `/school/appschool/reportData/list`)
setRealUrl(`报到数据-修改`, editReport, () => api + `/school/appschool/reportData/modified`)
setRealUrl(`获取学生信息-原始数据-调整配置`, schoolConfigInfo, () => api + `/school/appschool/oriEnroll/getConfigInfo`)
setRealUrl(`获取学生信息-原始数据-调整表格`, schoolTableList, () => api + `/school/appschool/oriEnroll/getTableLists`)
setRealUrl(`获取学生信息-显示字段`, studentsFileds, () => api + `/school/appschool/studentInfoManage/getAllColumn`)
setRealUrl(`获取学生信息-表格数据`, studentInfoManage, () => api + `/school/appschool/studentInfoManage/list`)
setRealUrl(`获取学生信息-学生信息详情`, studentInfoManageInfo, () => api + `/school/appschool/studentInfoManage/info`)
setRealUrl(`获取学生信息-三表信息列表`, studentInfoManageList, () => api + `/school/appschool/studentInfoManage/filelist`)
setRealUrl(`获取学生信息-档案下载获取水印及印章`, studentInfoManageFile, () => api + `/school/appschool/studentInfoManage/fileDownloadSet`)
setRealUrl(`获取学生信息-档案下载水印及印章编辑`, fileDownloadPhoto, () => api + `/school/file/fileDownloadPhoto`)
setRealUrl(`获取学生信息-档案下载水印及印章编辑`, setShowPhotoPos, () => api + `/school/appschool/studentInfoManage/setShowPhotoPos`)


// setRealUrl(`获取学生信息-配置信息`, studentsConfigInfo, () => mock + `/school/appschool/students/getConfigInfo`)
setRealUrl(`获取学生信息-模板`, studentsTemplates, () => api + `/school/appschool/studentInfoManage/templateList`)
setRealUrl(`获取学生信息-模板详情`, studentsTemplatesInfo, () => api + `/school/appschool/studentInfoManage/templateInfo`)
setRealUrl(`获取学生信息-模板修改`, studentsModifiedTemplate, () => api + `/school/appschool/studentInfoManage/modifiedTemplate`)
setRealUrl(`获取学生信息-模板增加`, studentsDddTemplate, () => api + `/school/appschool/studentInfoManage/addTemplate`)
setRealUrl(`获取学生信息-模板删除`, studentsDelTemplate, () => api + `/school/appschool/studentInfoManage/delTemplate`)
setRealUrl(`获取学生信息-自定义字段`, studentsCustomFields, () => api + `/school/appschool/studentInfoManage/customColumn`)
setRealUrl(`获取学生信息-自定义字段修改`, studentsCustomColumnSort, () => api + `/school/appschool/studentInfoManage/customColumnSort`)
setRealUrl(`获取学生信息-自定义字段上传`, studentsImportCustom, () => api + `/school/file/importCustom`)
setRealUrl(`获取学生信息-修改学生信息`, studentsModified, () => api + `/school/appschool/studentInfoManage/modified`)
setRealUrl(`获取学生信息-批量修改学生信息`, studentsModifiedBatch, () => api + `/school/appschool/studentInfoManage/modifiedBatch`)
setRealUrl(`获取学生信息-批量修改学生信息-获取配置`, studentsGetModifiedBatchConfig, () => api + `/school/appschool/studentInfoManage/getConfig`)
setRealUrl(`获取学生信息-批量删除学生`, studentsDelBatch, () => api + `/school/appschool/studentInfoManage/delBatch`)
setRealUrl(`获取学生信息-删除学生`, studentsDel, () => api + `/school/appschool/studentInfoManage/del`)
setRealUrl(`获取学生信息-替换文件`, studentsReplaceFile, () => api + `/school/file/replaceFile`)
setRealUrl(`获取学生信息-批量删除档案`, studentsClearFile, () => api + `/school/appschool/studentInfoManage/clear`)
setRealUrl(`学生信息-编辑-获取所有省份`, studentAllProvinces, () => api + `/school/appschool/config/allprovinces`)
// setRealUrl(`获取配置数据`, planConfigInfo, () => api + `/school/appschool/oriPlan/getConfigInfo`)
// setRealUrl(`获取表格数据`, planTableList, () => api + `/school/appschool/oriPlan/getTableLists`)
export const preppyDataUpload = 'preppyDataUpload'
export const preppyDataImport = 'preppyDataImport'
export const preppyLists = 'preppyLists'
export const preppyDel = 'preppyDel'
export const preppyClear = 'preppyClear'
export const preppyShift = 'preppyShift'
setRealUrl(`预科生转入-上传文件`, preppyDataUpload, () => api + `/preppy/file/preppyDataUpload`)
setRealUrl(`预科生转入-数据入库`, preppyDataImport, () => api + `/preppy/apppreppy/students/dataImport`)
setRealUrl(`预科生转入-列表`, preppyLists, () => api + `/preppy/apppreppy/students/lists`)
setRealUrl(`预科生转入-删除`, preppyDel, () => api + `/preppy/apppreppy/students/del`)
setRealUrl(`预科生转入-清空`, preppyClear, () => api + `/preppy/apppreppy/students/clear`)
setRealUrl(`预科生转入-转入`, preppyShift, () => api + `/preppy/apppreppy/students/shift`)
export default realUrl