/*
 * @Author: 王松
 * @Descripttion: 院校数据对比
 * @Date: 2023-03-09 13:38:27
 */
const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}

export const scoreYear = 'scoreYear'
export const scoreProvince = 'scoreProvince'
export const scoreCategory = 'scoreCategory'
export const scoreBatch = 'scoreBatch'
export const scoreAttribute = 'scoreAttribute'
export const scoreSchool = 'scoreSchool'
export const contrastScoreLists = 'contrastScoreLists'
export const contrastScoreGetbind = 'contrastScoreGetbind'
export const contrastScoreBindschool = 'contrastScoreBindSchool'
export const contrastScoreGetBindschoolLists = 'contrastScoreGetBindschoolLists'
setRealUrl(`投档分对比-年份下拉`, scoreYear, () => api + `/college/appcollege/score/year`)
setRealUrl(`投档分对比-省份下拉`, scoreProvince, () => api + `/college/appcollege/score/province`)
setRealUrl(`投档分对比-科类下拉`, scoreCategory, () => api + `/college/appcollege/score/category`)
setRealUrl(`投档分对比-批次下拉`, scoreBatch, () => api + `/college/appcollege/score/batch`)
setRealUrl(`投档分对比-学校属性类型`, scoreAttribute, () => api + `/college/appcollege/score/attribute`)
setRealUrl(`投档分对比-学校下拉`, scoreSchool, () => api + `/college/appcollege/score/top`)
setRealUrl(`投档分对比-列表`, contrastScoreLists, () => api + `/college/appcollege/score/lists`)

setRealUrl(`获取绑定学校名称`, contrastScoreGetbind, () => api + `/college/appcollege/score/getbind`)
setRealUrl(`绑定学校`, contrastScoreBindschool, () => api + `/college/appcollege/score/bindschool`)
setRealUrl(`获取绑定学校列表`, contrastScoreGetBindschoolLists, () => api + `/college/appcollege/score/schoollists`)

export const specScoreYear = 'specScoreYear'
export const specScoreProvince = 'specScoreProvince'
export const specScoreCategory = 'specScoreCategory'
export const specScoreBatch = 'specScoreBatch'
export const specScoreSpec = 'specScoreSpec'
export const specScoreAttribute = 'specScoreAttribute'
export const specScoreSchool = 'specScoreSchool'
export const specScoreLists = 'specScoreLists'
setRealUrl(`专业分专业维度-年份下拉`, specScoreYear, () => api + `/college/appcollege/specScore/year`)
setRealUrl(`专业分专业维度-省份下拉`, specScoreProvince, () => api + `/college/appcollege/specScore/province`)
setRealUrl(`专业分专业维度-科类下拉`, specScoreCategory, () => api + `/college/appcollege/specScore/category`)
setRealUrl(`专业分专业维度-批次下拉`, specScoreBatch, () => api + `/college/appcollege/specScore/batch`)
setRealUrl(`专业分专业维度-专业`, specScoreSpec, () => api + `/college/appcollege/specScore/spec`)
setRealUrl(`专业分专业维度-学校属性类型`, specScoreAttribute, () => api + `/college/appcollege/specScore/attribute`)
setRealUrl(`专业分专业维度-学校下拉`, specScoreSchool, () => api + `/college/appcollege/specScore/top`)
setRealUrl(`专业分专业维度-列表`, specScoreLists, () => api + `/college/appcollege/specScore/lists`)

export const specSchoolYear = 'specSchoolYear'
export const specSchoolProvince = 'specSchoolProvince'
export const specSchoolSchool = 'specSchoolSchool'
export const specSchoolLists = 'specSchoolLists'
setRealUrl(`专业分学校维度-年份下拉`, specSchoolYear, () => api + `/college/appcollege/specSchool/year`)
setRealUrl(`专业分学校维度-省份下拉`, specSchoolProvince, () => api + `/college/appcollege/specSchool/province`)
setRealUrl(`专业分学校维度-学校下拉`, specSchoolSchool, () => api + `/college/appcollege/specSchool/school`)
setRealUrl(`专业分学校维度-列表`, specSchoolLists, () => api + `/college/appcollege/specSchool/lists`)

export const planYear = 'planYear'
export const planProvince = 'planProvince'
export const planBatch = 'planBatch'
export const planCategory = 'planCategory'
export const planSpec = 'planSpec'
export const planAttribute = 'planAttribute'
export const planSchool = 'planSchool'
export const planLists = 'planLists'
setRealUrl(`招生计划-年份下拉`, planYear, () => api + `/college/appcollege/plan/year`)
setRealUrl(`招生计划-省份下拉`, planProvince, () => api + `/college/appcollege/plan/province`)
setRealUrl(`招生计划-批次下拉`, planBatch, () => api + `/college/appcollege/plan/batch`)
setRealUrl(`招生计划-科类下拉`, planCategory, () => api + `/college/appcollege/plan/category`)
setRealUrl(`招生计划-专业`, planSpec, () => api + `/college/appcollege/plan/spec`)
setRealUrl(`招生计划-学校属性类型`, planAttribute, () => api + `/college/appcollege/plan/attribute`)
setRealUrl(`招生计划-学校下拉`, planSchool, () => api + `/college/appcollege/plan/school`)
setRealUrl(`招生计划-列表`, planLists, () => api + `/college/appcollege/plan/lists`)

export default realUrl