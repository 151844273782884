/*
 * @Author: 石吉文
 * @Date: 2023-09-12 16:20:42
 * @Description: 
 */
const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}
export const JiaoJi3324 = 'JiaoJi3324'
export const JiaoJi3326 = 'JiaoJi3326'
export const JiaoJi3335 = 'JiaoJi3335'
export const JiaoJi3336 = 'JiaoJi3336'
export const JiaoJi3337 = 'JiaoJi3337'
export const JiaoJi3338 = 'JiaoJi3338'
export const JiaoJi3339 = 'JiaoJi3339'
export const Biao631 = 'Biao631'
export const Biao632 = 'Biao632'
export const Biao633 = 'Biao633'

// 教基表
setRealUrl(`教基3324`, JiaoJi3324, () => api + `/school/appschool/jiaoji/excel3324`)
setRealUrl(`教基3326`, JiaoJi3326, () => api + `/school/appschool/jiaoji/excel3326`)
setRealUrl(`教基3335`, JiaoJi3335, () => api + `/school/appschool/jiaoji/excel3335`)
setRealUrl(`教基3336`, JiaoJi3336, () => api + `/school/appschool/jiaoji/excel3336`)
setRealUrl(`教基3337`, JiaoJi3337, () => api + `/school/appschool/jiaoji/excel3337`)
setRealUrl(`教基3338`, JiaoJi3338, () => api + `/school/appschool/jiaoji/excel3338`)
setRealUrl(`教基3338`, JiaoJi3339, () => api + `/school/appschool/jiaoji/excel3339`)

setRealUrl(`表6-3-1`, Biao631, () => api + `/school/appschool/jiaoji/excel631`)
setRealUrl(`表6-3-2`, Biao632, () => api + `/school/appschool/jiaoji/excel632`)
setRealUrl(`表6-3-3`, Biao633, () => api + `/school/appschool/jiaoji/excel633`)

export default realUrl