/**
 *
 * @author : 田源
 * @date : 2022-10-17 11:58
 * @description : 登录功能接口
 *
*/

const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}

export const passwordReset = 'passwordReset'
export const getToken = 'getToken'
export const userModified = 'userModified'
export const adminLogin = 'adminLogin'
export const localReset = 'localReset'

setRealUrl(`用户登录`, getToken, () => api + `/schoolno/user/getToken`)
setRealUrl(`管理员登录`, adminLogin, () => api + `/schoolno/user/adminlogin`)
setRealUrl(`提交忘记密码`, passwordReset, () => api + `/schoolno/user/reset`)
setRealUrl(`修改用户信息`, userModified, () => api + `/school/appschool/user/modified`)
setRealUrl(`本地版-重置账号密码`, localReset, () => api + `/schoolno/user/localReset`)

export default realUrl