// * 分班分学号
const { api } = require('../eol-api-domain')
const realUrl = {}
function setRealUrl(_, key, value) {
  realUrl[key] = value
}

// * 分班设置
export const classConfigLists = 'classConfigLists'
setRealUrl(`分班设置列表`, classConfigLists, () => api + `/divide/appdivide/classConfig/lists`)
export const classConfigDownloadTemplate = 'classConfigDownloadTemplate'
setRealUrl(`批量设置分班专业下载模板`, classConfigDownloadTemplate, () => api + `/divide/appdivide/classConfig/downloadTemplate`)
export const classConfigDataImport = 'classConfigDataImport'
setRealUrl(`导入数据处理`, classConfigDataImport, () => api + `/divide/appdivide/classConfig/dataImport`)
export const classConfigClear = 'classConfigClear'
setRealUrl(`清空分班配置`, classConfigClear, () => api + `/divide/appdivide/classConfig/clear`)
export const classConfigDel = 'classConfigDel'
setRealUrl(`删除分班设置`, classConfigDel, () => api + `/divide/appdivide/classConfig/del`)
export const classConfigUpdate = 'classConfigUpdate'
setRealUrl(`修改分班设置`, classConfigUpdate, () => api + `/divide/appdivide/classConfig/update`)

// * 分班
export const divideClassList = 'divideClassList'
setRealUrl(`分班列表`, divideClassList, () => api + `/divide/appdivide/class/lists`)
export const divideClassHistoryRuleLists = 'divideClassHistoryRuleLists'
setRealUrl(`分班历史规则`, divideClassHistoryRuleLists, () => api + `/divide/appdivide/class/historyRuleLists`)
export const divideClassDelHistoryRule = 'divideClassDelHistoryRule'
setRealUrl(`删除分班历史规则`, divideClassDelHistoryRule, () => api + `/divide/appdivide/class/delHistoryRule`)
export const divideClassUpdateClass = 'divideClassUpdateClass'
setRealUrl(`修改班级`, divideClassUpdateClass, () => api + `/divide/appdivide/class/updateClass`)
export const divideClassDel = 'divideClassDel'
setRealUrl(`删除班级`, divideClassDel, () => api + `/divide/appdivide/class/del`)
export const divideClassResetClass = 'divideClassResetClass'
setRealUrl(`重置班级`, divideClassResetClass, () => api + `/divide/appdivide/class/resetClass`)
export const divideClassDivideClass = 'divideClassDivideClass'
setRealUrl(`分班`, divideClassDivideClass, () => api + `/divide/appdivide/class/divideClass`)

// * 分学号

export const divideNumberDownloadTemplate = 'divideNumberDownloadTemplate'
setRealUrl(`下载自定义模板`, divideNumberDownloadTemplate, () => api + `/divide/appdivide/customNumber/downloadTemplate`)
export const divideNumberDataImport = 'divideNumberDataImport'
setRealUrl(`导入自定义编号`, divideNumberDataImport, () => api + `/divide/appdivide/customNumber/dataImport`)
export const divideNumberCustomLists = 'divideNumberCustomLists'
setRealUrl(`自定义数据列表`, divideNumberCustomLists, () => api + `/divide/appdivide/customNumber/lists`)
export const divideNumberCustomDel = 'divideNumberCustomDel'
setRealUrl(`删除自定义编号`, divideNumberCustomDel, () => api + `/divide/appdivide/customNumber/del`)
export const divideNumberNumberLists = 'divideNumberNumberLists'
setRealUrl(`列表`, divideNumberNumberLists, () => api + `/divide/appdivide/number/lists`)
export const divideNumberNumberDel = 'divideNumberNumberDel'
setRealUrl(`删除与批量删除学号`, divideNumberNumberDel, () => api + `/divide/appdivide/number/del`)
export const divideNumberNumberUpdate = 'divideNumberNumberUpdate'
setRealUrl(`编辑学号`, divideNumberNumberUpdate, () => api + `/divide/appdivide/number/update`)
export const divideNumberNumberReset = 'divideNumberNumberReset'
setRealUrl(`重置学号`, divideNumberNumberReset, () => api + `/divide/appdivide/number/reset`)
export const divideNumberGetNumberPrefix = 'divideNumberGetNumberPrefix'
setRealUrl(`获取编号前缀`, divideNumberGetNumberPrefix, () => api + `/divide/appdivide/number/getNumberPrefix`)
export const divideNumberCreateNumber = 'divideNumberCreateNumber'
setRealUrl(`生成学号`, divideNumberCreateNumber, () => api + `/divide/appdivide/number/createNumber`)
export const divideNumberHistoryLists = 'divideNumberHistoryLists'
setRealUrl(`历史分学号规则`, divideNumberHistoryLists, () => api + `/divide/appdivide/number/historyLists`)
export const divideNumberDelHistoryRule = 'divideNumberDelHistoryRule'
setRealUrl(`删除历史规则`, divideNumberDelHistoryRule, () => api + `/divide/appdivide/number/delHistoryRule`)
export const divideNumberUseLastRule = 'divideNumberUseLastRule'
setRealUrl(`使用上一次规则`, divideNumberUseLastRule, () => api + `/divide/appdivide/number/useLastRule`)

// * 分班设置导入 
export const divideFileClassConfigUpload = 'divideFileClassConfigUpload'
setRealUrl(`分班设置导入`, divideFileClassConfigUpload, () => api + `/divide/file/classConfigUpload`)

export default realUrl