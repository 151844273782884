import { useEffect, useRef, useState, useCallback } from 'react'
import user from '../user'
const { api } = require('../axios/eol-api-domain')

const READY_STATE = {
  connecting: 0, // * 连接中
  open: 1, // * 已连接
  closing: 2, // * 关闭中
  closed: 3, // * 已关闭
}

/**
 *
 * @description 使用 webSocket 的 hook
 * @param {string} socketUrl 必填，webSocket 地址	
 * @param {{reconnectLimit?: number,reconnectInterval?: number,manual?: boolean,onOpen?: (event: WebSocketEventMap['open'], instance: WebSocket) => void,onClose?: (event: WebSocketEventMap['close'], instance: WebSocket) => void,onMessage?: (message:{[k:string]:any}=>void, instance: WebSocket) => void,onError?: (event: WebSocketEventMap['error'], instance: WebSocket) => void}} options 可选，连接配置项	
 * 
 * - reconnectLimit: 重连次数限制 默认 3 次
 * - reconnectInterval: 重连间隔 默认 3 秒
 * - manual: 是否手动连接 默认 false
 * - onOpen: 连接成功回调
 * - onClose: 关闭回调
 * - onMessage: 收到消息回调
 * - onError: 错误回调
 * 
 * @return {{latestMessage?: {[k:string]:any},sendMessage?: (message:any)=>void,disconnect?: () => void,connect?: () => void,readyState: 0|1|2|3,webSocketIns?: WebSocket}} 
 * 
 * - latestMessage: 最新消息
 * - sendMessage: 发送消息函数
 * - disconnect: 手动断开连接
 * - connect: 手动连接，如果当前已有连接，则关闭后重新连接
 * - readyState: 当前连接状态 0 连接中 1 已连接 2 关闭中 3 已关闭
 * - webSocketIns: webSocket 实例
 **/
function useWebSocket(socketUrl, options = {}) {
  const optionsRef = useRef(options)
  const websocketRef = useRef()
  const unmountedRef = useRef(false)

  const [latestMessage, setLatestMessage] = useState()
  const [readyState, setReadyState] = useState(READY_STATE.closed)

  const [isError, setIsError] = useState(false)
  const heartBeatTimerRef = useRef(null)
  const heartBeatTimesRef = useRef(0)
  const reconnectTimesRef = useRef(0)
  const reconnectTimerRef = useRef()

  const connect = useCallback(() => {
    if (reconnectTimerRef.current) {
      clearTimeout(reconnectTimerRef.current)
    }

    const userToken = user.getuserToken() ?? ''
    const {
      onOpen,
      onClose,
      onMessage,
      onError,
      reconnectLimit = 3,
    } = optionsRef.current

    if (websocketRef.current) {
      websocketRef.current.close()
    }

    const { host, protocol } = window.location
    const SOCKET_PATH = process.env.NODE_ENV === "development" ? api : host
    const ws = new WebSocket(`${protocol === 'https:' ? 'wss' : 'ws'}://${SOCKET_PATH}/${socketUrl}?token=${userToken}`)

    ws.onerror = (event) => {
      if (unmountedRef.current) return
      setIsError(true)
      // * 重连失败后再触发onError
      if (reconnectTimesRef.current >= reconnectLimit) {
        onError?.(event, ws)
      }
      setReadyState(ws.readyState || READY_STATE.closed)
    }

    ws.onopen = (event) => {
      if (unmountedRef.current) return

      setIsError(false)
      onOpen?.(event, ws)
      reconnectTimesRef.current = 0
      setReadyState(ws.readyState || READY_STATE.open)

      // * 每 2s 发送一次心跳 保持连接
      ws.send('ping')
      heartBeatTimerRef.current = setInterval(() => {
        // * 连续5次心跳未收到消息则视为error 断开连接
        if (heartBeatTimesRef.current === 5) {
          clearInterval(heartBeatTimerRef.current)
          setIsError(true)
          onError?.(event, ws)
          ws.close()
          return
        }
        ws.send('ping')
        heartBeatTimesRef.current++
      }, 2 * 1000)
    }

    ws.onmessage = (message) => {
      if (unmountedRef.current) return
      if (!message.data) return

      // * 收到消息重置心跳次数
      heartBeatTimesRef.current = 0
      setIsError(false)
      const data = JSON.parse(message.data || {})

      // ! 后端返回的心跳信息 暂不需要
      if (data.cmd === '1') return

      onMessage?.(data, ws)
      setLatestMessage(data)
    }

    ws.onclose = (event) => {
      if (unmountedRef.current) return
      heartBeatTimerRef.current && clearInterval(heartBeatTimerRef.current)

      setIsError(false)
      onClose?.(event, ws)
      setReadyState(ws.readyState || READY_STATE.closed)
    }

    websocketRef.current = ws
  }, [socketUrl])

  const reconnect = useCallback(() => {
    if (unmountedRef.current) return
    const { reconnectLimit = 3, reconnectInterval = 3 * 1000 } = optionsRef.current
    if (
      reconnectTimesRef.current < reconnectLimit &&
      websocketRef.current?.readyState !== READY_STATE.open
    ) {
      if (reconnectTimerRef.current) {
        clearTimeout(reconnectTimerRef.current)
      }

      reconnectTimerRef.current = setTimeout(() => {
        connect()
        reconnectTimesRef.current++
      }, reconnectInterval)
    }
  }, [connect])

  const sendMessage = useCallback((message) => {
    if (readyState !== READY_STATE.open) {
      console.error('webSocket 未连接，无法发送消息')
      return
    }

    // * 发送二进制文件
    if (['ArrayBuffer', 'Blob'].includes(message?.constructor.name) || ArrayBuffer.isView(message)) {
      websocketRef.current.send(message)
    } else {
      websocketRef.current.send(JSON.stringify(message))
    }
  }, [readyState])

  const disconnect = useCallback(() => {
    setLatestMessage(undefined)
    heartBeatTimerRef.current && clearInterval(heartBeatTimerRef.current)
    // * 关闭前先发送关闭命令给后端
    websocketRef.current?.send?.(JSON.stringify({ cmd: '2' }))
    websocketRef.current?.close()
  }, [])

  useEffect(() => {
    if (isError) {
      reconnect()
    }
  }, [isError, reconnect])

  useEffect(() => {
    const { manual = false } = optionsRef.current
    if (!manual) connect()

    return () => {
      unmountedRef.current = true
      disconnect()
    }
  }, [connect, disconnect])

  return {
    latestMessage,
    sendMessage,
    connect,
    disconnect,
    readyState,
    webSocketIns: websocketRef.current,
  }
}

export default useWebSocket