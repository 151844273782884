/**
*
* @author : 田源
* @date : 2022-10-08 13:47
* @description : 正则表达式验证函数集合
*
*/

// 电话号
export const isPhone = (value) => /^(1[3456789])\d{9}$/.test(value)
// 邮箱
export const isEmail = (value) => /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(value)
// 8-16位字母及数字组合密码
export const isPassword = (value) => /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/.test(value)