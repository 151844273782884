/*
 * @Author: 王松
 * @Descripttion: 新版生源质量报告
 * @Date: 2024-04-09 10:19:26
 */
const { api, } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}
export const qualityReportConfig = 'qualityReportConfig'
setRealUrl(`筛选下拉`, qualityReportConfig, () => api + `/school/appschool/qualityReport/config`)
export const qualityReportgetSatConfig1 = 'qualityReportgetSatConfig1'
setRealUrl(`获取数据设置`, qualityReportgetSatConfig1, () => api + `/school/appschool/qualityReport/getConfig`)
export const qualityReportsetSatConfig1 = 'qualityReportsetSatConfig1'
setRealUrl(`数据设置`, qualityReportsetSatConfig1, () => api + `/school/appschool/qualityReport/setConfig`)
export const qualityReportgetCss = 'qualityReportgetCss'
setRealUrl(`获取数据设置`, qualityReportgetCss, () => api + `/school/appschool/qualityReport/getCss`)
export const qualityReportsetCss = 'qualityReportsetCss'
setRealUrl(`数据设置`, qualityReportsetCss, () => api + `/school/appschool/qualityReport/setCss`)

export const qualityReportContent = 'qualityReportContent'
setRealUrl(`数据设置`, qualityReportContent, () => api + `/school/appschool/qualityReport/content`)
export default realUrl