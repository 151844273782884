/*
 * @Author: 王松
 * @Descripttion: 下拉多选
 * @Date: 2022-12-22 10:38:32
 */
import { TreeSelect } from 'antd'
const { SHOW_PARENT } = TreeSelect
function ETreeSelect({
  treeData, value, maxTagCount = 1, treeNodeFilterProp = 'label',
  placeholder, labelInValue = true, onChange, width = 150, disabled
}) {
  const tProps = {
    treeData,
    value,
    treeNodeFilterProp: treeNodeFilterProp,
    onChange: onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: placeholder,
    maxTagCount: maxTagCount,
    treeDefaultExpandAll: true,
    labelInValue: labelInValue,
    style: {
      width: width
    },
  }
  return <TreeSelect {...tProps} disabled={disabled} />
}
export default ETreeSelect