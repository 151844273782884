const LRU = require('lru-cache')

/**
*
* @author: 田源
* @date: 2021-08-03 10:51
* @description: 缓存代理
* @argument {apiMap} api的缓存数据
* @argument {pending} pending中的接口
*/

class CachePorxy {
  static #instance = null
  #apiMap = new LRU({ max: 500 })
  #pending = new Map()

  constructor() {
    return this.#setTnstance()
  }

  #setTnstance() {
    if (!CachePorxy.#instance) {
      CachePorxy.#instance = this
    }
    return CachePorxy.#instance
  }

  getCache(cacheKey) {
    const cacheRes = this.#apiMap.get(cacheKey)
    if (cacheRes) {
      console.log("缓存请求结果", cacheRes)
      return cacheRes
    }
  }
  setCache(cacheKey, data) {
    this.#apiMap.set(cacheKey, data)
  }

  /** 当前接口是否pending中 */
  pendingCache(cacheKey) {
    return this.#pending.has(cacheKey)
  }

  /** 添加pending接口 */
  addPendingCache(cacheKey, value) {
    this.#pending.set(cacheKey, value)
  }
  /** 获取指定的pengding中的接口 */
  getPendingCache(cacheKey) {
    return this.#pending.get(cacheKey)
  }

  /** 删除pending中的接口状态 */
  deletePendingCache(cacheKey) {
    delete this.#pending.delete(cacheKey)
  }

  /** 取消pending中的接口 */
  cancelPending(url) {
    if (url) {
      for (const key of this.#pending.keys()) {
        if (key.startsWith(url.split('undefined')[0])) {
          this.#pending.get(key).cancel('axios canceled request')
          break
        }
      }
    } else {
      this.#pending.forEach(source => {
        source.cancel('axios canceled request')
      })
      this.#pending.clear()
    }
  }
}

export default CachePorxy