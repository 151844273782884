/*
 * @Author: 王松
 * @Descripttion: 
 * @Date: 2023-04-27 19:09:41
 */
const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}
export const getBigScreenResponse = 'getBigScreenResponse'
export const hasBigScreenResponse = 'hasBigScreenResponse'
export const bigScreenWelcome = 'bigScreenWelcome'
export const hasBigScreenProata = 'hasBigScreenProata'
export const getBigScreenResponse1 = 'getBigScreenResponse1'
export const hasBigScreenResponse1 = 'hasBigScreenResponse1'
export const hasBigScreenProata1 = 'hasBigScreenProata1'
setRealUrl(`获取招录大屏信息`, getBigScreenResponse, () => api + `/enrollScreen/screen/data`)
setRealUrl(`录取进程大屏是否失效`, hasBigScreenResponse, () => api + `/enrollScreen/screen/has`)
setRealUrl(`迎新大屏`, bigScreenWelcome, () => api + `/verify/link/data`)
setRealUrl(`录取进程大屏单个省数据`, hasBigScreenProata, () => api + `/enrollScreen/screen/prodata`)
setRealUrl(`录取进程大屏`, getBigScreenResponse1, () => api + `/enrollScreen/screen/data1`)
setRealUrl(`录取进程大屏是否失效`, hasBigScreenResponse1, () => api + `/enrollScreen/screen/has1`)
setRealUrl(`录取进程大屏单个省数据`, hasBigScreenProata1, () => api + `/enrollScreen/screen/prodata1`)
export default realUrl
