// * 单机版升级

const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}

export const versionCheck = 'versionCheck'
setRealUrl(`检查版本升级`, versionCheck, () => api + `/versionUp/VersionCheck`)
export const excUpdating = 'excUpdating'
setRealUrl(`执行版本更新`, excUpdating, () => api + `/versionUp/ExcUpadating`)
export const getProgress = 'getProgress'
setRealUrl(`获取版本更新进度`, getProgress, () => api + `/versionUp/GetProgress`)
export const dataCheck = 'dataCheck'
setRealUrl(`检查数据更新`, dataCheck, () => api + `/versionUp/DataCheck`)
export const excData = 'excData'
setRealUrl(`执行数据更新`, excData, () => api + `/versionUp/ExcData`)

export default realUrl