import { useCallback } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

/**
*
* @author : 田源
* @date : 2021-10-17 14:35
* @description : 获取路由信息
*
*/
function useRouterInfo() {

  const { menuList } = useSelector(store => store.userMenu)

  const { pathname } = useLocation()
  const keys = pathname.match(/\/[^/?]*/g)

  const findRouterName = useCallback((routerArray, keys, index) => {
    const path = keys.slice(0, index).join('')
    const router = routerArray.find(item => item.path === path) || {}
    if (router.children) {
      return findRouterName(router.children, keys, index + 1)
    }
    return router
  }, [])

  return findRouterName(menuList, keys, 1)
}

export default useRouterInfo