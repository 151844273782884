// 公共信息查询
const { api } = require('../eol-api-domain')
const realUrl = {}
function setRealUrl(_, key, value) {
  realUrl[key] = value
}
// 一分一段表
export const helperSegmentation = `helperSegmentation`
setRealUrl(`一分一段表helper`, helperSegmentation, () => api + `/publicInfo/public/score/list`)
// 声控线
export const helperProvincialControlLine = `helperProvincialControlLine`
setRealUrl(`声控线helper`, helperProvincialControlLine, () => api + `/publicInfo/public/province/list`)
// 生源高中
export const helperSourceOfStudents = `helperSourceOfStudents`
setRealUrl(`生源高中helper`, helperSourceOfStudents, () => api + `/publicInfo/public/high/list`)
export default realUrl
