 
/**
*
* @author : 田源
* @date : 2022-10-17 12:03
* @description : 数据总览接口
*
*/
const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}

export const getSex = 'getSex'
export const getOverview = 'getOverview'
export const luQuCount = 'luQuCount'
export const kslb = 'kslb'
export const everyPro = 'everyPro'
export const category = 'category'
export const batch = 'batch'
export const nation = 'nation'
export const vol = 'vol'
export const getReport = 'getReport'

setRealUrl(`获取男女比例`, getSex, () => api + `/school/appschool/dataOverview/sex`)
setRealUrl(`获取招生概览`, getOverview, () => api + `/school/appschool/dataOverview/getOverview`)
setRealUrl(`获取录取人数`, luQuCount, () => api + `/school/appschool/dataOverview/count`)
setRealUrl(`获取考生类别`, kslb, () => api + `/school/appschool/dataOverview/kslb`)
setRealUrl(`获取各省招生计划`, everyPro, () => api + `/school/appschool/dataOverview/everyPro`)
setRealUrl(`获取科类统计`, category, () => api + `/school/appschool/dataOverview/category`)
setRealUrl(`获取批次数据`, batch, () => api + `/school/appschool/dataOverview/batch`)
setRealUrl(`获取民资数据`, nation, () => api + `/school/appschool/dataOverview/nation`)
setRealUrl(`获取录取率和调剂率`, vol, () => api + `/school/appschool/dataOverview/vol`)
setRealUrl(`获取报到数据`, getReport, () => api + `/school/appschool/dataOverview/report`)

export default realUrl