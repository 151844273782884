import { useState, useEffect, useRef, } from 'react'
import { Progress, Space, Modal, Button, message as Message } from 'antd'
import styles from '../sass/import.module.scss'

/** @type {{UPLOAD:'1',PARSE:'2',CONVERT:'3'}} */
const ACTIONS = {
  UPLOAD: '1', // * 文件上传
  PARSE: '2', // * 文件解析
  CONVERT: '3'  // * 文件转换
}

/** @type {{ PROGRESSING: '0',SUCCESS: '1',FAIL: '2',ALL_SUCCESS: '3'}} */
const STATUS = {
  PROGRESSING: '0', // * 进行中
  SUCCESS: '1', // * 已完成
  FAIL: '2', // * 错误
  ALL_SUCCESS: '3' // * 全部成功
}

/** @type {{FINISH:'3',FAIL:'2'}} */
const CONVERT_STATUS = { // * 转换状态
  FINISH: '3',
  FAIL: '2',
}

// * 异常状态 
/** @type {{NORMAL: 0,FAIL: 1}} */
const ABNORMAL = {
  NORMAL: 0,
  FAIL: 1,  // * 失败待确认
}

/**
 * 
 * @description 导入的进度与结果展示
 * @param {Object} data socket返回的数据
 */
function ImportProgress(props) {
  const { title, data = {}, isError, importState, setImportState, handleImportResult, exportFailedInfo, showFileResult = false, year, isConvert = false } = props
  const messageBoxRef = useRef()
  const [action, setAction] = useState()
  const [status, setStatus] = useState()

  const [uploadProgress, setUploadProgress] = useState(0)
  const [parseProgress, setParseProgress] = useState(0)
  const [convertProgress, setConvertProgress] = useState(0)
  const totalProgress = isConvert ? Math.round((uploadProgress + parseProgress + convertProgress) / 3) : Math.round((uploadProgress + parseProgress) / 2)

  const [failedLineList, setFailedLineList] = useState([])
  const [successNum, setSuccessNum] = useState(0)
  const [failedNum, setFailedNum] = useState(0)
  const [fileResultList, setFileResultList] = useState([])
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [errorId, setErrorId] = useState(null)

  // * 跳过和继续按钮的loading状态
  const [skipBtnLoading, setSkipBtnLoading] = useState(false)
  const [continueBtnLoading, setContinueBtnLoading] = useState(false)

  useEffect(() => {
    if (!data?.action) return

    const { message, action, status, abnormal, line_info, data_id, task_id, process, count, } = data
    if (isError) return Message.warning(message)

    // * 过滤掉非本次任务的消息
    if (!task_id || +sessionStorage.getItem('task_id') !== +task_id) return

    // * 处理进度条
    if (action === ACTIONS.UPLOAD && process > 0) {
      if (convertProgress < 100) {
        setUploadProgress(prev => prev > process * 0.6 ? prev : process * 0.6)
      }
    } else if (action === ACTIONS.PARSE && process > 0) {
      if (convertProgress < 100) {
        setParseProgress(prev => prev > process ? prev : process)
        if (!isConvert && process === 100) {
          // * 不需要转换时，解析完成视为完成
          setImportState('SUCCESS')
        }
      }
    } else if (action === ACTIONS.CONVERT && process > 0 && isConvert) {
      setParseProgress(100)
      setConvertProgress(prev => prev > process ? prev : process)
    }

    setAction(action)
    setStatus(status)

    // * 忽略导入的消息
    if (action === ACTIONS.UPLOAD) return

    // * 单行失败的记录
    if (line_info?.reason !== '') {
      setFailedLineList(prev => [...prev, line_info])
      if (messageBoxRef.current) {
        messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight - 20
      }
    }

    if (action === ACTIONS.PARSE) {
      // * 完成或错误 记录单个文件的结果
      if (status !== STATUS.PROGRESSING && message !== '' && showFileResult) {
        setFileResultList(prev => [...prev, { abnormal, message }])
      }
      // * 解析异常
      if (abnormal === ABNORMAL.FAIL) {
        setErrorModalOpen(true)
        setErrorId(data_id)
      }
    } else if (action === ACTIONS.CONVERT && isConvert) {
      // * 转换完成
      if (status === CONVERT_STATUS.FINISH) {
        setSuccessNum(count)
        setImportState('SUCCESS')
      } else if (status === CONVERT_STATUS.FAIL) {
        setFailedNum(pre => pre + 1)
      }
    }
  }, [data, setImportState, isError, showFileResult, convertProgress, isConvert])

  useEffect(() => {
    let id

    if (uploadProgress < 100) {
      if (uploadProgress > 30) {
        id = setInterval(() => {
          setUploadProgress(prev => prev + Math.random() * 3)
          setStatus(STATUS.PROGRESSING)
        }, 300)
      }

      if (parseProgress > 0) {
        setUploadProgress(100)
        setStatus(STATUS.PROGRESSING)
      }

    } else if (uploadProgress === 100) {
      id && clearInterval(id)
    }

    return () => {
      id && clearInterval(id)
    }
  }, [parseProgress, uploadProgress])

  // * 跳过
  const handleSkip = () => {
    setSkipBtnLoading(true)
    handleImportResult(errorId, 1, year).then(() => {
      setErrorModalOpen(false)
      setErrorId(null)
    }).finally(() => {
      setSkipBtnLoading(false)
    })
  }

  // * 继续
  const handleContinue = () => {
    setContinueBtnLoading(true)
    handleImportResult(errorId, 2, year).then(() => {
      setErrorModalOpen(false)
      setErrorId(null)
      setFileResultList(data => [...data.slice(0, data.length - 1)])
    }).finally(() => {
      setContinueBtnLoading(false)
    })
  }

  return (
    <>
      <p className={styles.progressInfo}>
        {importState === 'SUCCESS' ?
          (title === '导入录取数据' || title === '解密录取数据' ? <span>{title === '解密录取数据' ? '数据解密' : '数据导入'}成功，共{title === '解密录取数据' ? '解密成功' : '导入'}<span className={styles.textfw}>{data.import_province}</span>个省份、<span className={styles.textfw}>{data.import_ksh}</span>个学生{title === '解密录取数据' ? '' : <>，新增<span className={styles.textfw}>{data.import_add_ksh}</span>个学生</>}</span> : '数据导入成功')
          : <span>{title === '解密录取数据' ? '数据解密' : '数据导入'}中（{totalProgress > 100 ? 100 : totalProgress}%）</span>}
      </p >
      {totalProgress > 0 && <div className={styles.progressWrapper}>
        <Progress
          className={styles.progress}
          showInfo={false}
          percent={totalProgress}
          strokeLinecap='butt'
          format={(percent) => `${percent}%`}
          strokeColor='#1677ff'
          status={totalProgress === 100 ? undefined : 'active'}
        />
      </div>
      }
      <Space style={{ width: '100%' }} direction='vertical'>
        {fileResultList.length > 0 && title !== '解密录取数据' && <p className={styles.result}>
          {fileResultList.map((item, index) => <span key={index}>{index + 1}、{item.message}</span>)}
        </p>}
        {failedLineList.length > 0 && title !== '解密录取数据' && <div className={styles.messageBox} ref={messageBoxRef} >
          <p className={styles.messageItem}>
            {failedLineList.map((item, index) => <span key={index} className={styles.fail}>{item?.file_name ? `${item?.file_name}，` : ''}{item?.line_message}</span>)}
          </p>
        </div>}
        <p className={styles.summary}>
          {action === ACTIONS.CONVERT && status === CONVERT_STATUS.FINISH && successNum > 0 && <>导入成功<span className={styles.success}>{successNum}</span>
            条记录，</>}
          {failedNum > 0 && <>失败<span className={styles.fail}>{failedNum}</span>条。</>}
        </p>
        {failedLineList.length > 0 && title !== '解密录取数据' && <Button type='link' style={{ padding: 0 }} onClick={() => { exportFailedInfo(failedLineList) }}>导出失败信息</Button>}
      </Space>
      {title !== '解密录取数据' && <Modal
        width={560}
        title={title}
        open={errorModalOpen}
        maskClosable={false}
        closable={false}
        destroyOnClose
        footer={
          <Space>
            <Button onClick={handleSkip} loading={skipBtnLoading}>跳过</Button>
            <Button onClick={handleContinue} loading={continueBtnLoading} type='primary'>继续</Button>
          </Space >
        }
      >
        检测到考生信息与当前选择年份不一致，是否继续导入？
      </Modal>}
    </>
  )
}

export default ImportProgress