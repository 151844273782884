import CryptoJS from 'crypto-js'
import { message } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import EolAxios, { API } from '../axios'
import { downloadFile } from '.'
import OSS from 'ali-oss'

/** 
 * @description 导入的相关工具函数和文件类型配置
*/

/**
 * @description 判断文件是否符合要求
 * @param {File} file 文件对象
 * @param {string} accept 接受的文件后缀或类型 与Upload组件的accept属性一致
 * @param {number} maxSize 文件最大尺寸 单位为M
 * @returns {boolean} 文件是否符合要求
 */
const isFileValid = (file, accept, maxSize) => {
  if (!maxSize || !(typeof maxSize === 'number' && maxSize > 0)) {
    console.error('maxSize必须是大于0的数字')
    return false
  }
  // * 后缀名相同或者类型相同
  const isAccept = accept ? accept
    .split(',')
    .some(item => ((file.name.toLowerCase()).endsWith(item.trim().toLowerCase()) || file.type === item.trim()))
    : true
  const isMaxSize = maxSize ? file.size / 1024 / 1024 < maxSize : true

  if (!isAccept) {
    message.warning(`请上传正确的文件类型`)
    return false
  }

  if (!isMaxSize) {
    message.warning(`导入的文件过大，请处理后上传`)
  }

  return isAccept && isMaxSize
}

/**
 * @description 获取文件列表每个文件的sha256值
 * @param {File[]} fileList 文件列表
 * @returns {Promise<string[]>} 每个文件的sha256值
 */
const getFilesSha256 = async (fileList = []) => {
  if (!(Array.isArray(fileList) && fileList?.length > 0)) return []

  const getSha256 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onload = (e) => {
      const buffer = e.target.result
      const sha256 = CryptoJS.SHA256(CryptoJS.lib.WordArray.create(buffer)).toString()
      resolve(sha256)
    }
    reader.onerror = (e) => {
      reject(e)
    }
  })

  const sha256List = await Promise.all(fileList.map(getSha256))
  return sha256List
}

/**
 * @description 上传文件附属信息（用于后端断点续传）
 * @param {File[]} [fileList=[]] 文件列表
 */
const sendFileTags = async (fileList = []) => {
  if (!(Array.isArray(fileList) && fileList?.length > 0)) return

  getFilesSha256(fileList).then((sha256List) => {
    EolAxios.dynamicRequest({
      path: API.fileTags,
      formData: {
        file_hash: sha256List,
        file_id: new Array(fileList.length).fill(null).map(() => uuidv4()),
      }
    }).then((res) => {
      if (!EolAxios.checkResponse({ res })) return
    })
  })
}

/**
 * 
 * * 处理导入的结果
 * @param {string|number} data_id 数据id
 * @param {1|2|3|4} action 动作 1跳过 2继续 3取消 4完成
 * @param {string|number} year 年份
 */
const handleImportResult = (data_id, action, year) => {
  // * 跳过和继续 不等接口返回，直接延迟一下返回成功
  if ([1, 2].includes(action)) {
    return new Promise((resolve, _reject) => {
      EolAxios.dynamicRequest({
        path: API.fileAbnormal,
        formData: {
          data_id,
          action,
          task_id: sessionStorage.getItem('task_id'),
          year
        }
      })
      setTimeout(() => {
        resolve()
      }, 500)
    })
  }

  return EolAxios.dynamicRequest({
    path: API.fileAbnormal,
    formData: {
      data_id,
      action,
      task_id: sessionStorage.getItem('task_id'),
      year,
    }
  })
}

// * 导出失败信息
const exportFailedInfo = (data) => {
  const tableHeader = ['序号', '文件', '名称', '失败原因']
  const tableData = data.map((item, index) => [`${index + 1}`, item.file_name, item.line_message, item.reason])

  EolAxios.dynamicRequest({
    path: API.fileExport,
    formData: {
      data: [[tableHeader, ...tableData]],
      sheet: ['sheet1']
    },
  }).then((res) => {
    if (!EolAxios.checkResponse({ res })) return
    const { file_id } = res

    downloadFile(file_id, '失败信息.xlsx')
  })
}

/**
 *
 * @description 上传文件到OSS
 * @param {{fileList:File[],params?:{[k:string]:any},handleProgress:()=>void}}
 * - fileList 文件列表
 * - params 获取配置请求的参数
 * - handleProgress 处理上传进度函数
 */
const uploadToOSS = async ({ fileList, params, handleProgress }) => {
  const resArr = await Promise.all([
    EolAxios.dynamicRequest({ path: API.getBucket, formData: params }),
    EolAxios.dynamicRequest({ path: API.getSTSToken, formData: params })
  ])

  if (resArr.some(res => !EolAxios.checkResponse({ res }))) return

  const [bucketConfig, _ossConfig] = resArr
  const { bucket, region, path } = bucketConfig

  const ossConfig = {
    accessKeyId: _ossConfig.AccessKeyId,
    accessKeySecret: _ossConfig.AccessKeySecret,
    stsToken: _ossConfig.SecurityToken,
  }

  const client = new OSS({
    region,
    accessKeyId: ossConfig.accessKeyId,
    accessKeySecret: ossConfig.accessKeySecret,
    stsToken: ossConfig.stsToken,
    bucket,
    secure: true,
    refreshSTSToken: async () => {
      const res = await EolAxios.dynamicRequest({ path: API.getSTSToken, formData: params })
      // * 获取失败，返回上一次的配置
      if (!EolAxios.checkResponse({ res })) return ossConfig

      // * 更新配置
      ossConfig.accessKeyId = res.AccessKeyId
      ossConfig.accessKeySecret = res.AccessKeySecret
      ossConfig.stsToken = res.SecurityToken

      return ossConfig
    },
    refreshSTSTokenInterval: 1000 * 60 * 2,
  })

  const progressMap = new Array(fileList.length).fill(0)
  return Promise.all(fileList.map(async (file, index) => {
    const { name } = file
    try {
      const result = await client.multipartUpload(`${path}${name}`, file, {
        partSize: 100 * 1024, // * 分片大小
        progress: (progress) => {
          progressMap[index] = progress
          const totalProgress = Math.floor((progressMap.reduce((prev, cur) => prev + cur, 0) / fileList.length) * 100)
          handleProgress?.(totalProgress)
        },
      })
      console.log(`${name} 上传成功`)
      return { ...result, file_name: name }
    } catch (error) {
      console.error(`${name} 上传失败: ${error}`)
      return { name, error }
    }
  }))
}

const FILE_TYPES = {
  LUQU: '6', // * 录取数据
  CUSTOM_LUQU: '7', // * 自定义录取数据
  JIHUA: '3', // * 计划数据
  JIHUA_ADJUST: '4', // * 计划调整
  CUSTOM_JIHUA: '5', // * 自定义计划
  BAODAO: '9', // * 报到数据
  VERIFY: '16', // * 人像核验数据
  DECRYPT: '17',//* 解密录取数据
}

// * 档案相关的文件类型
const ENROLL_FILE_TYPES = {
  PHOTO: '1', // * 录取照片
  BAOMING: '2', // * 报名表
  TIJIAN: '3', // * 体检表
  FUJIA: '4', // * 附加表
  CHENGJI_ZHIYUAN: '5', // * 成绩与志愿信息表
  CAIJIAN_PHOTO: '6', // * 采集照片
  XUEJI_PHOTO: '7', // * 学籍照片
  ID_CARD: '8', // * 身份证
  XUELI_PHOTO: '9', // * 学历照片
}

export {
  isFileValid,
  getFilesSha256,
  sendFileTags,
  handleImportResult,
  exportFailedInfo,
  uploadToOSS,

  FILE_TYPES,
  ENROLL_FILE_TYPES
}