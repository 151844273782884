/**
*
* @author : 田源
* @date : 2022-10-14 16:31
* @description : 功能名称组件
*
*/
function ETitle({ name, style = {} }) {
  return <p className='router-name' style={style}>{name}</p>
}

export default ETitle