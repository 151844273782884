/*
 * @Author: 王松
 * @Descripttion: 喜报模板
 * @Date: 2022-12-27 09:13:30
 */
const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}
// 喜报模板
export const getGoodnewslists = 'getGoodnewslists'
export const getGoodnewsSyslists = 'getGoodnewsSyslists'
export const addGoodnewsTem = 'addGoodnewsTem'
export const deleteGoodnewsTemplate = 'deleteGoodnewsTemplate'
export const cloneGoodnewsTemplate = 'cloneGoodnewsTemplate'
export const setDefaultGoodnewsTemplate = 'setDefaultGoodnewsTemplate'
export const oneContentGoodnewsTemplate = 'oneContentGoodnewsTemplate'
export const updateGoodnewsTemplate = 'updateGoodnewsTemplate'
export const printGetConfig = 'printGetConfig'
export const printLists = 'printLists'
export const printGetImportData = 'printGetImportData'
export const goodNewsDataUpload = 'goodNewsDataUpload'
export const printDataImport = 'printDataImport'
export const printUpdateHigh = 'printUpdateHigh'
export const printDetailLists = 'printDetailLists'
export const getGoodnewstemplatePreview = 'getGoodnewstemplatePreview'
export const printDetailListsPrint = 'printDetailListsPrint'
export const printCreatePng = 'printCreatePng'
export const printGoodNewsdownload = 'printGoodNewsdownload'
export const printDownload = 'printDownload'
export const printGoodNewsDownloadDel = 'printGoodNewsDownloadDel'
export const printDownloadCancel = 'printDownloadCancel'

setRealUrl(`喜报-学校模板`, getGoodnewstemplatePreview, () => api + `/goodNews/appgoodnews/template/templatePreview`)
setRealUrl(`喜报-学校模板`, getGoodnewslists, () => api + `/goodNews/appgoodnews/template/lists`)
setRealUrl(`喜报-内置模板`, getGoodnewsSyslists, () => api + `/goodNews/appgoodnews/template/syslists`)
setRealUrl(`喜报-添加模板`, addGoodnewsTem, () => api + `/goodNews/appgoodnews/template/add`)
setRealUrl(`喜报-删除模板`, deleteGoodnewsTemplate, () => api + `/goodNews/appgoodnews/template/deleteTemplate`)
setRealUrl(`喜报-复制模板`, cloneGoodnewsTemplate, () => api + `/goodNews/appgoodnews/template/clone`)
setRealUrl(`喜报-设置默认`, setDefaultGoodnewsTemplate, () => api + `/goodNews/appgoodnews/template/setDefault`)
setRealUrl(`喜报-详情`, oneContentGoodnewsTemplate, () => api + `/goodNews/appgoodnews/template/oneContent`)
setRealUrl(`喜报-编辑`, updateGoodnewsTemplate, () => api + `/goodNews/appgoodnews/template/update`)
setRealUrl(`获取配置信息`, printGetConfig, () => api + `/goodNews/appgoodnews/printing/getConfig`)
setRealUrl(`打印列表`, printLists, () => api + `/goodNews/appgoodnews/printing/lists`)
setRealUrl(`导出模板`, printGetImportData, () => api + `/goodNews/appgoodnews/printing/getImportData`)
setRealUrl(`喜报导入`, goodNewsDataUpload, () => api + `/goodNews/file/goodNewsDataUpload`)
setRealUrl(`学校数据导入`, printDataImport, () => api + `/goodNews/appgoodnews/printing/dataImport`)
setRealUrl(`单个规范学校`, printUpdateHigh, () => api + `/goodNews/appgoodnews/printing/updateHigh`)
setRealUrl(`详情列表`, printDetailLists, () => api + `/goodNews/appgoodnews/printing/detailLists`)
setRealUrl(`详情列表预览和打印`, printDetailListsPrint, () => api + `/goodNews/appgoodnews/printing/detailListsPrint`)
setRealUrl(`批量生成`, printCreatePng, () => api + `/goodNews/appgoodnews/printing/createPng`)
setRealUrl(`传输列表`, printGoodNewsdownload, () => api + `/goodNews/appgoodnews/printing/goodNewsdownload`)
setRealUrl(`下载文件`, printDownload, () => api + `/goodNews/appgoodnews/printing/download`)
setRealUrl(`传输列表删除`, printGoodNewsDownloadDel, () => api + `/goodNews/appgoodnews/printing/goodNewsDownloadDel`)
setRealUrl(`喜报-取消下载`, printDownloadCancel, () => api + `/goodNews/appgoodnews/printing/downloadCancel`)

export default realUrl