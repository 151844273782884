/**
 *
 * @author : 田源
 * @date : 2022-10-17 12:03
 * @description : 首页\公共接口
 *
 */
const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}

export const menu = 'menu'
export const getUserInfo = 'getUserInfo'
export const loginOut = 'loginOut'
export const getCaptcha = 'getCaptcha'
export const smsCaptche = 'smsCaptche'
export const emailCaptcha = 'emailCaptcha'
export const getYear = 'getYear'
export const importYear = 'importYear'
export const getMapConfig = 'getMapConfig'
export const fileDownload = api + 'fileDownload'
export const getTableHeader = 'getTableHeader'
export const region = 'region'
export const setSatConfig = 'setSatConfig'
export const getSatConfig = 'getSatConfig'
export const getSatConfig1 = 'getSatConfig1'
export const setSatConfig1 = 'setSatConfig1'
export const modifyStatus = 'modifyStatus'
export const getRemindStatus = 'getRemindStatus'
export const analyseYear = 'analyseYear'
export const downloadTemplate = 'downloadTemplate'
export const configIgnore = 'configIgnore'
export const commonConfig = 'commonConfig'

setRealUrl(`获取公共配置`, commonConfig, () => api + `/common/config`)
setRealUrl(`获取用户菜单`, menu, () => api + `/school/appschool/config/menu1`)
setRealUrl(`获取用户信息`, getUserInfo, () => api + `/school/appschool/user/getInfo`)
setRealUrl(`用户退出`, loginOut, () => api + `/school/appschool/user/loginOut`)
setRealUrl(`获取图片验证码`, getCaptcha, () => api + `/schoolno/user/getCaptcha`)
setRealUrl(`获取短信验证码`, smsCaptche, () => api + `/schoolno/user/smsCaptche`)
setRealUrl(`获取邮箱验证码`, emailCaptcha, () => api + `/schoolno/user/emailCaptcha`)
setRealUrl(`获取年份列表`, getYear, () => api + `/school/appschool/config/year`)
setRealUrl(`导入年份`, importYear, () => api + `/school/appschool/config/allYear`)
setRealUrl(`获取全局map配置`, getMapConfig, () => api + `/school/appschool/mapConfig/list`)
setRealUrl(`文件下载`, fileDownload, () => api + `/school/file/download`)
setRealUrl(`获取表头数据`, getTableHeader, () => api + `/school/appschool/header/list`)
setRealUrl(`省市区联动`, region, () => api + `/school/appschool/mapConfig/region`)
setRealUrl(`设置统计规则修改`, setSatConfig, () => api + `/school/appschool/schoolsetting/setSatConfig`)
setRealUrl(`获取统计规则修改`, getSatConfig, () => api + `/school/appschool/schoolsetting/getSatConfig`)
setRealUrl(`获取统计规则设置`, getSatConfig1, () => api + `/school/appschool/schoolsetting/getSatConfig1`)
setRealUrl(`保存统计规则设置`, setSatConfig1, () => api + `/school/appschool/schoolsetting/setSatConfig1`)
setRealUrl(`修改提醒显示`, modifyStatus, () => api + `/school/appschool/schoolsetting/modifyStatus`)
setRealUrl(`获取提醒状态`, getRemindStatus, () => api + `/school/appschool/schoolsetting/getRemindStatus`)
setRealUrl(`统计年份列表`, analyseYear, () => api + `/school/appschool/config/statYear`)
setRealUrl(`下载模板静态文件`, downloadTemplate, () => api + `/static/down_static_file`)
setRealUrl(`引导状态`, configIgnore, () => api + `/school/appschool/config/ignore`)
export default realUrl
