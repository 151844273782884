/**
 * @description: 数据处理相关接口
 */
const { api } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}

export const admissionTypeRuleList = 'admissionTypeRuleList'
export const admissionTypeRuleModified = 'admissionTypeRuleModified'

export const displayData = 'displayData'
export const setDisplayData = 'setDisplayData'

export const collegeMajorRepairList = 'collegeMajorRepairList'
export const collegeMajorMangeList = 'collegeMajorMangeList'
export const collegeMajorMangeInfo = 'collegeMajorRepair'
export const collegeMajorAdmissionTypeRuleConfig = 'collegeMajorAdmissionTypeRuleConfig'
export const collegeMajorRepairCallback = 'collegeMajorRepairCallback'
export const collegeMajorRepairRepair = 'collegeMajorRepairRepair'
export const collegeMajorRepairModified = 'collegeMajorRepairModified'
export const collegeMajorRepairSpec = 'collegeMajorRepairSpec'
export const collegeMajorRepairRule = 'collegeMajorRepairRule'
export const collegeMajorRepairSetRule = 'collegeMajorRepairSetRule'

export const collegeMajorManageDelete = 'collegeMajorManageDelete'
export const collegeMajorManageAdd = 'collegeMajorManageAdd'
export const collegeMajorManageCopy = 'collegeMajorManageCopy'
export const collegeMajorManageEdit = 'collegeMajorManageEdit'
export const collegeMajorManageConfig = 'collegeMajorManageConfig'
export const collegeMajorImport = 'collegeMajorImport'
export const collegeMajorCampusHas = 'collegeMajorCampusHas'
export const collegeMajorCampusList = 'collegeMajorCampusList'
export const collegeMajorCampusAdd = 'collegeMajorCampusAdd'
export const collegeMajorCampusEdit = 'collegeMajorCampusEdit'
export const collegeMajorCampusDelete = 'collegeMajorCampusDelete'
export const collegeMajorCampusQuote = 'collegeMajorCampusQuote'
export const collegeMajorCampusHasData = 'collegeMajorCampusHasData'

export const admissionManageList = 'admissionManageList'
export const admissionManageModify = 'admissionManageModify'
export const admissionManageDel = 'admissionManageDel'
export const admissionManageAdd = 'admissionManageAdd'
export const admissionManageImport = 'admissionManageImport'

export const getCjxName = 'getCjxName'
export const checkCjxName = 'checkCjxName'
export const addCjxCustom = 'addCjxCustom'
export const getCjxList = 'getCjxList'
export const modifyCjx = 'modifyCjx'
export const cjxSort = 'cjxSort'
export const getConfig = 'getConfig'
export const getConfig1 = 'getConfig1'

export const streamRepairList = 'streamRepairList'
export const streamRepairModify = 'streamRepairModify'
export const streamRepair = 'streamRepair'
export const streamRepairReset = 'streamRepairReset'

export const batchRepairList = 'batchRepairList'
export const batchRepairModify = 'batchRepairModify'
export const batchRepair = 'batchRepair'
export const batchRepairReset = 'batchRepairReset'

export const examineeTypeRepairList = 'examineeTypeRepairList'
export const examineeTypeRepairModify = 'examineeTypeRepairModify'
export const examineeTypeRepair = 'examineeTypeRepair'
export const examineeTypeRepairReset = 'examineeTypeRepairReset'

export const graduateTypeRepairList = 'graduateTypeRepairList'
export const graduateTypeRepairModify = 'graduateTypeRepairModify'
export const graduateTypeRepair = 'graduateTypeRepair'
export const graduateTypeRepairReset = 'graduateTypeRepairReset'

export const highSchoolList = 'highSchoolList'
export const highSchoolAddress = 'highSchoolAddress'
export const highSchoolModify = 'highSchoolModify'
export const highSchoolRematch = 'highSchoolRematch'

export const sectionList = 'sectionList'
export const sectionScoreConfig = 'sectionScoreConfig'
export const sectionConfig = 'sectionConfig'
export const sectionModify = 'sectionModify'
export const sectionUnMatch = 'sectionUnMatch'

export const provinceScoreList = 'provinceScoreList'
export const provinceScoreScoreConfig = 'provinceScoreScoreConfig'
export const provinceScoreConfig = 'provinceScoreConfig'
export const provinceScoreModify = 'provinceScoreModify'
export const provinceScoreUnMatch = 'provinceScoreUnMatch'
export const campuspec = 'campuspec'

setRealUrl(`获取显示设置数据`, displayData, () => api + '/school/appschool/cache/list')
setRealUrl(`设置显示设置数据`, setDisplayData, () => api + '/school/appschool/cache/modified')

setRealUrl(`招生类型修正 列表`, admissionTypeRuleList, () => api + `/school/appschool/admissionTypeRule/list`)
setRealUrl(`招生类型修正 编辑`, admissionTypeRuleModified, () => api + `/school/appschool/admissionTypeRule/modified`)
setRealUrl(`招生类型维护 列表`, admissionManageList, () => api + `/school/appschool/admissionManage/list`)
setRealUrl(`招生类型维护 编辑`, admissionManageModify, () => api + `/school/appschool/admissionManage/modified`)
setRealUrl(`招生类型维护 新增`, admissionManageAdd, () => api + `/school/appschool/admissionManage/add`)
setRealUrl(`招生类型维护 删除`, admissionManageDel, () => api + `/school/appschool/admissionManage/del`)
setRealUrl(`招生类型维护 导入`, admissionManageImport, () => api + `/school/file/importEnroll`)

setRealUrl(`学院专业修正 列表`, collegeMajorRepairList, () => api + `/school/appschool/collegeMajorRepair/list`)
setRealUrl(`学院专业修正 一键修正`, collegeMajorRepairRepair, () => api + `/school/appschool/collegeMajorRepair/repair`)
setRealUrl(`学院专业修正 一键还原`, collegeMajorRepairCallback, () => api + `/school/appschool/collegeMajorRepair/callback`)
setRealUrl(`学院专业修正 编辑`, collegeMajorRepairModified, () => api + `/school/appschool/collegeMajorRepair/modified`)
setRealUrl(`学院专业修正 编辑 下拉数据`, collegeMajorRepairSpec, () => api + `/school/appschool/collegeMajorRepair/spec`)
setRealUrl(`学院专业修正 规则列表`, collegeMajorRepairRule, () => api + `/school/appschool/collegeMajorRepair/getRule`)
setRealUrl(`学院专业修正 设置规则`, collegeMajorRepairSetRule, () => api + `/school/appschool/collegeMajorRepair/setRule`)

setRealUrl(`学院专业维护 列表`, collegeMajorMangeList, () => api + `/school/appschool/collegeMajorManage/list`)
setRealUrl(`学院专业维护 详情`, collegeMajorMangeInfo, () => api + `/school/appschool/collegeMajorMange/info`)
setRealUrl(`学院专业维护 删除`, collegeMajorManageDelete, () => api + `/school/appschool/collegeMajorManage/del`)
setRealUrl(`学院专业维护 新增`, collegeMajorManageAdd, () => api + `/school/appschool/collegeMajorManage/add`)
setRealUrl(`学院专业维护 复制`, collegeMajorManageCopy, () => api + `/school/appschool/collegeMajorManage/copy`)
setRealUrl(`学院专业维护 编辑`, collegeMajorManageEdit, () => api + `/school/appschool/collegeMajorManage/modified`)
setRealUrl(`学院专业维护 下拉数据`, collegeMajorAdmissionTypeRuleConfig, () => api + `/school/appschool/admissionTypeRule/config`)
setRealUrl(`学院专业维护 导入`, collegeMajorImport, () => api + `/school/file/importCollege`)
setRealUrl(`学院专业修正--新编辑下拉`, campuspec, () => api + `/school/appschool/collegeMajorRepair/campuspec`)
setRealUrl(`学院专业维护 配置项`, collegeMajorManageConfig, () => api + `/school/appschool/collegeMajorManage/config`)
setRealUrl(`学院专业维护 学院是否存在`, collegeMajorCampusHas, () => api + `/school/appschool/collegeMajorCampus/has`)
setRealUrl(`学院专业维护 学院列表`, collegeMajorCampusList, () => api + `/school/appschool/collegeMajorCampus/list`)
setRealUrl(`学院专业维护 学院添加`, collegeMajorCampusAdd, () => api + `/school/appschool/collegeMajorCampus/add`)
setRealUrl(`学院专业维护 学院编辑`, collegeMajorCampusEdit, () => api + `/school/appschool/collegeMajorCampus/modified`)
setRealUrl(`学院专业维护 学院删除`, collegeMajorCampusDelete, () => api + `/school/appschool/collegeMajorCampus/del`)
setRealUrl(`学院专业维护 引用`, collegeMajorCampusQuote, () => api + `/school/appschool/collegeMajorCampus/quote`)
setRealUrl(`学院专业 是否有当年数据`, collegeMajorCampusHasData, () => api + `/school/appschool/collegeMajorCampus/hasData`)

setRealUrl(`科类修正 列表`, streamRepairList, () => api + `/school/appschool/streamRepair/list`)
setRealUrl(`科类修正 编辑`, streamRepairModify, () => api + `/school/appschool/streamRepair/modified`)
setRealUrl(`科类修正 一键修正`, streamRepair, () => api + `/school/appschool/streamRepair/repair`)
setRealUrl(`科类修正 一键还原`, streamRepairReset, () => api + `/school/appschool/streamRepair/callback`)

setRealUrl(`批次修正 列表`, batchRepairList, () => api + `/school/appschool/batchRepair/list`)
setRealUrl(`批次修正 编辑`, batchRepairModify, () => api + `/school/appschool/batchRepair/modified`)
setRealUrl(`批次修正 一键修正`, batchRepair, () => api + `/school/appschool/batchRepair/repair`)
setRealUrl(`批次修正 一键还原`, batchRepairReset, () => api + `/school/appschool/batchRepair/callback`)

setRealUrl(`考生类别修正 列表`, examineeTypeRepairList, () => api + `/school/appschool/examineeTypeRepair/list`)
setRealUrl(`考生类别修正 编辑`, examineeTypeRepairModify, () => api + `/school/appschool/examineeTypeRepair/modified`)
setRealUrl(`考生类别修正 一键修正`, examineeTypeRepair, () => api + `/school/appschool/examineeTypeRepair/repair`)
setRealUrl(`考生类别修正 一键还原`, examineeTypeRepairReset, () => api + `/school/appschool/examineeTypeRepair/callback`)

setRealUrl(`毕业类别修正 列表`, graduateTypeRepairList, () => api + `/school/appschool/graduateTypeRepair/list`)
setRealUrl(`毕业类别修正 编辑`, graduateTypeRepairModify, () => api + `/school/appschool/graduateTypeRepair/modified`)
setRealUrl(`毕业类别修正 一键修正`, graduateTypeRepair, () => api + `/school/appschool/graduateTypeRepair/repair`)
setRealUrl(`毕业类别修正 一键还原`, graduateTypeRepairReset, () => api + `/school/appschool/graduateTypeRepair/callback`)

setRealUrl(`高中信息 列表`, highSchoolList, () => api + `/school/appschool/highSchool/getLists`)
setRealUrl(`高中信息 获取地址`, highSchoolAddress, () => api + `/school/appschool/highSchool/getAdress`)
setRealUrl(`高中信息 编辑`, highSchoolModify, () => api + `/school/appschool/highSchool/modify`)
setRealUrl(`高中信息 重新匹配`, highSchoolRematch, () => api + `/school/appschool/highSchool/rematch`)

setRealUrl(`位次对应 列表`, sectionList, () => api + `/school/appschool/section/lists`)
setRealUrl(`位次对应 编辑获取配置`, sectionScoreConfig, () => api + `/school/appschool/section/scoreConfig`)
setRealUrl(`位次对应 筛选`, sectionConfig, () => api + `/school/appschool/section/config`)
setRealUrl(`位次对应 编辑`, sectionModify, () => api + `/school/appschool/section/modify`)
setRealUrl(`位次对应 解绑`, sectionUnMatch, () => api + `/school/appschool/section/unMatch`)

setRealUrl(`省控线对应 列表`, provinceScoreList, () => api + `/school/appschool/provinceScore/lists`)
setRealUrl(`省控线对应 编辑获取配置`, provinceScoreScoreConfig, () => api + `/school/appschool/provinceScore/scoreConfig`)
setRealUrl(`省控线对应 筛选`, provinceScoreConfig, () => api + `/school/appschool/provinceScore/config`)
setRealUrl(`省控线对应 编辑`, provinceScoreModify, () => api + `/school/appschool/provinceScore/modify`)
setRealUrl(`省控线对应 解绑`, provinceScoreUnMatch, () => api + `/school/appschool/provinceScore/unMatch`)

// * 成绩项
setRealUrl(`获取自定义成绩项名称列表`, getCjxName, () => api + `/school/appschool/cjx/getCjxName`)
setRealUrl(`验证成绩项是否存在`, checkCjxName, () => api + `/school/appschool/cjx/checkCjxName`)
setRealUrl(`添加自定义成绩项`, addCjxCustom, () => api + `/school/appschool/cjx/addCjxCus`)
setRealUrl(`编辑成绩项`, modifyCjx, () => api + `/school/appschool/cjx/modify`)
setRealUrl(`成绩项配置列表`, getCjxList, () => api + `/school/appschool/cjx/getLists`)
setRealUrl(`分数整理`, cjxSort, () => api + `/school/appschool/cjx/scoreSorting`)
setRealUrl(`单科成绩编辑下拉列表`, getConfig, () => api + `/school/appschool/cjx/getConfig`)
setRealUrl(`自定义成绩编辑下拉`, getConfig1, () => api + `/school/appschool/cjx/getConfig1`)

export default realUrl