/**
 * @description: 传输相关接口
 */
const { api, } = require('../eol-api-domain')
const realUrl = {}

function setRealUrl(_, key, value) {
  realUrl[key] = value
}

export const transferUploadList = 'transferUploadList'
export const transferDownloadList= 'transferDownloadList'
export const transferGetFilePath = 'transferGetFilePath'
export const transferDownloadCancel = 'transferDownloadCancel'
export const transferDelDownload = 'transferDelDownload'
export const transferRebuild = 'transferRebuild'
export const transferUploadCancel = 'transferUploadCancel'
export const transferReupload = 'transferReupload'

setRealUrl(`文件上传列表`, transferUploadList, () => api + `/school/appschool/transfer/fileUpload`)
setRealUrl(`下载列表`, transferDownloadList, () => api + `/school/appschool/transfer/fileDownload`)
setRealUrl(`获取下载地址`, transferGetFilePath, () => api + `/school/appschool/transfer/getFilePath`)
setRealUrl(`取消下载`, transferDownloadCancel, () => api + `/school/appschool/transfer/downloadCancel`)
setRealUrl(`删除下载记录`, transferDelDownload, () => api + `/school/appschool/transfer/delDownload`)
setRealUrl(`重新生成`, transferRebuild, () => api + `/school/appschool/transfer/rebuild`)
setRealUrl(`取消上传`, transferUploadCancel, () => api + `/school/appschool/transfer/uploadCancel`)
setRealUrl(`重新上传`, transferReupload, () => api + `/school/appschool/transfer/reupload`)

export default realUrl