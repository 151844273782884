import { SAVE_OSS_PROGRESS } from '../actions/action-types'

export const ossProgress = (state = null, action) => {
  switch (action.type) {
    case SAVE_OSS_PROGRESS:
      return action.payload
    default:
      return state
  }
}
