/*
 * @Author: 王松
 * @Descripttion: 
 * @Date: 2024-04-07 09:54:44
 */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EolAxios, { API } from '../axios'
import { saveConfig } from '../redux/actions'

/**
 *
 * @description 判断是否为本地部署版
 * @return {{isLocal: boolean}}  isLocal: 是否为本地部署版 
 */
const usePlatform = () => {
  const { platform, platform_update } = useSelector(store => store.globalConfig)
  const dispatch = useDispatch()
  const [isLocal, setIsLocal] = useState(false)
  const [platformUpdate, setPlatformUpdate] = useState(false)
  useEffect(() => {
    if (platform === undefined) {
      EolAxios.dynamicRequest({
        path: API.commonConfig
      }).then(res => {
        if (!EolAxios.checkResponse({ res })) return
        // // TODO 临时
        // res.platform = '1'
        setIsLocal(res.platform === '1')
        setPlatformUpdate(res.platform_update === '1')
        dispatch(saveConfig({ platform: res.platform, platform_update: res.platform_update }))
      })
      return
    }

    setIsLocal(platform === '1')
    setPlatformUpdate(platform_update === '1')
  }, [dispatch, platform, platform_update])

  return { isLocal, platformUpdate }
}

export default usePlatform