/*
 * @Author: 王松
 * @Descripttion: 
 * @Date: 2024-02-04 11:36:16
 */
import { lazy } from 'react'
import menuRouter from './menu-router'
import setRouter from './set-router-fun'

const routes = [
  setRouter('登录', { path: '/login', exact: true, Comp: lazy(() => import('../pages/login/Index')) }),
  setRouter('忘记密码', { path: '/forgetpassword', exact: true, Comp: lazy(() => import('../pages/login/ForgetPassword')) }),
  setRouter('问卷预览', {
    path: '/question-preview',
    exact: true,
    needLogin: true,
    Comp: lazy(() => import('../pages/questionnaire/Preview'))
  }),
  setRouter('学生端问卷', {
    path: '/viewquestion',
    exact: true,
    Comp: lazy(() => import('../pages/questionnaire/Preview'))
  }),
  setRouter('招录大屏', {
    path: '/admission-bigscreen',
    exact: true,
    Comp: lazy(() => import('../pages/admission-bigscreen/BigScreen'))
  }),
  setRouter('通知书编辑', {
    path: '/notice/edit',
    exact: true,
    needLogin: true,
    Comp: lazy(() => import('../pages/data-notice/Paper'))
  }),
  setRouter('数据发布-外部页面-录取结果查询', {
    path: '/release-page/enroll',
    exact: true,
    Comp: lazy(() => import('../pages/release-pages/Enroll'))
  }),
  setRouter('数据发布-外部页面-志愿报考分析', {
    path: '/release-page/analysisva',
    exact: true,
    Comp: lazy(() => import('../pages/release-pages/AnalysisVA'))
  }),
  setRouter('数据发布-外部页面-历年数据查询', {
    path: '/release-page/overyears',
    exact: true,
    Comp: lazy(() => import('../pages/release-pages/OverYears'))
  }),
  setRouter('数据发布-外部页面-招生计划查询', {
    path: '/release-page/plan',
    exact: true,
    Comp: lazy(() => import('../pages/release-pages/Plan'))
  }),
  setRouter('数据发布-外部页面-自定义数据查询', {
    path: '/release-page/custom',
    exact: true,
    Comp: lazy(() => import('../pages/release-pages/Custom'))
  }),
  setRouter('对外页面-萌新海报长图', {
    path: '/poster/mengxin',
    exact: true,
    Comp: lazy(() => import('../pages/poster/Index'))
  }),
  setRouter('外部页面-迎新报到-老师登录页面', {
    path: '/baodaolink/login',
    exact: true,
    Comp: lazy(() => import('../pages/baodao-teacher-link/BaoDaoLogin'))
  }),
  setRouter('外部页面-迎新报到-老师审核页面', {
    path: '/baodaolink/index',
    exact: true,
    Comp: lazy(() => import('../pages/baodao-teacher-link/BaoDaoShenHe'))
  }),
  setRouter('外部页面-迎新报到-学生登录页面', {
    path: '/student/login',
    exact: true,
    Comp: lazy(() => import('../pages/baodao-student-link/StudentLogin'))
  }),
  setRouter('外部页面-迎新报到-学生报到页面', {
    path: '/student/index',
    exact: true,
    Comp: lazy(() => import('../pages/baodao-student-link/BaoDao'))
  }),
  setRouter('迎新大屏', {
    path: '/welcome/screen',
    exact: true,
    Comp: lazy(() => import('../pages/bigscreen-welcome/index'))
  }),
  setRouter('通知书打印引导', {
    path: '/notice/guide',
    exact: true,
    Comp: lazy(() => import('../pages/data-notice/Guide'))
  }),
  setRouter('过渡页面', {
    path: '/transit',
    exact: true,
    Comp: lazy(() => import('../pages/transit/Index'))
  }),
  setRouter('人像核验报到-老师登录页面', {
    path: '/verify/login',
    exact: true,
    Comp: lazy(() => import('../pages/portrait-verification/VerifyLogin'))
  }),
  setRouter('人像核验报到-老师审核页面', {
    path: '/verify/teacher',
    exact: true,
    Comp: lazy(() => import('../pages/portrait-verification/VerifyTeacher'))
  }),
  setRouter('功能页', {
    path: '/',
    exact: false,
    needLogin: true,
    Comp: lazy(() => import('../pages/home/Index')),
    children: menuRouter
  })
]

export default routes
